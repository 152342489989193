import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import PageBanner from "./PageBanner";
import { images } from "./Utility/Images";

function Press() {
  return (
    <>
      <Header />
      <main>
        <PageBanner
          banner3
          img1={images.slide4}
          className="page-banner-3"
          title="Our Press Story"
        />

        <section className="blog mb-80 px-4pc pt-80">
          <div className="container-fluid">
            <div className="row gy-5">
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image rounded-3">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/PressDetail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image rounded-3">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/PressDetail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image rounded-3">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/PressDetail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image rounded-3">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/PressDetail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image rounded-3">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/PressDetail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image rounded-3">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/PressDetail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image rounded-3">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/PressDetail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image rounded-3">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/PressDetail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="trending-blog mb-80">
          <div className="container">
            <div className="title-section bg text-center w-100 mb-4">
              <h1 className="heading fw-semibold">Our Top Story</h1>
            </div>
            <div
              className="row align-items-center g-0"
              style={{ backgroundColor: "#FAEDED" }}
            >
              <div className="col-12 col-md-6">
                <div className="content">
                  <h4 className="mb-3">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis
                    nisl ut aliquip ex ea commodo consequat.
                  </p>
                  <Link to="/PressDetail" className="btn red-bg btn-custom">
                    Know More
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="image">
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/YmoXPNxCplo"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Press;
