import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillPatchCheckFill, BsFillStarFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { images } from "../Utility/Images";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { getActivitiesForItinerary, getHotelsForItinerary, updateItinerary } from "../../services/Itinerary.service";
import { generateFilePath } from "../../services/url";
import { calculateFinalPrice } from "../../utils/utils";
import { getResortRoomsForChange } from "../../services/resort.service";

function Hotel({ itineraryObj, changeTab, changeRef, changeRefFn }) {
  const [itineraryData, setItineraryData] = useState(null);
  const [selectedHotelModalObj, setSelectedHotelModalObj] = useState(null);
  const [hotelArr, setHotelArr] = useState([]);
  const [roomsArr, setRoomsArr] = useState([]);
  const hotelsRef = useRef()
  const [activityArr, setActivityArr] = useState([]);
  const ActiveTab = (i) => {
    let temp = itineraryData?.datesArr?.map((item, index) => {
      i === index ? (item.active = !item.active) : (item.active = false);
      return item;
    });
    let tempObj = {
      ...itineraryData,
      datesArr: temp,
    };
    setItineraryData(tempObj);
  };

  const [getWindow, setGetWindow] = useState(window.innerWidth);

  // useLayoutEffect(() => {
  //   window.addEventListener("resize", () => setGetWindow(getWindow));
  //   setGetWindow(getWindow);
  //   return () =>
  //     window.addEventListener("resize", () => setGetWindow(getWindow));
  // }, []);







  const hotel_img = {
    0: {
      slidesPerView: 1,
    },
    576: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
  };

  useEffect(() => {
    if (itineraryObj) {
      getAllHotelsForItinerary()
      let tempObj = itineraryObj
      if (tempObj?.datesArr) {
        tempObj.datesArr = tempObj?.datesArr.map((el) => {
          el.sectionShown = "activity";
          return el;
        });
      }
      setItineraryData(tempObj);
      handleGetActivity()

    }
  }, [itineraryObj]);

  const getAllHotelsForItinerary = async () => {
    try {
      if (!itineraryObj?.isHotelApiDisabled) {

        const { data: res } = await getHotelsForItinerary(itineraryObj?._id);
        if (res) {
          // console.log(res.data)
          setHotelArr(res.data)
        }
      }
      else {
        const { data: res } = await getResortRoomsForChange();
        if (res) {
          setRoomsArr(res.data)
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [opencadmodal, setOpencadmodal] = useState(false);
  const cartmodalClose = () => setOpencadmodal(false);
  const cartmodalShow = () => setOpencadmodal(true);

  const handleSelectShow = (i, sectionShown) => {
    let temp = itineraryData?.datesArr?.map((item, index) => {
      if (i === index) {
        item.active = true;
        item.sectionShown = sectionShown;
      } else item.active = true;
      return item;
    });
    let tempObj = {
      ...itineraryData,
      datesArr: temp,
    };
    console.log(temp, "temp");
    setItineraryData(tempObj);
  };

  const handleHotelModalSelection = (obj) => {
    setSelectedHotelModalObj(obj);
    handleShow();
  };

  const handleHotelChange = async (obj) => {
    try {
      let tempDatesIndex = itineraryData.datesArr.findIndex(elx => elx.itineraryDate == selectedHotelModalObj?.itineraryDate);
      let tempArr = itineraryData.datesArr;
      // let tempPrice = parseInt(itineraryData?.totalPrice - itineraryData?.hotelObj?.price) + parseInt(obj.price)
      tempArr[tempDatesIndex].hotelObj = obj;
      let tempPrice = calculateFinalPrice(itineraryData.flightObj, [...tempArr])
      // let tempPrice = parseInt(itineraryData.flightObj?.Fare) + tempArr.reduce((acc, el) => acc + parseInt(el.activityObj.acitivityPriceObj.priceFor1) + parseInt(el.hotelObj?.price), 0)
      let tempObj = {
        ...itineraryData,
        totalPrice: parseInt(tempPrice),
        datesArr: tempArr
      }
      const { data: res } = await updateItinerary(tempObj, itineraryData?._id);
      if (res) {
        toastSuccess(res.message)
        window.location.reload()
      }
    } catch (error) {
      toastError(error);
    }
  }

  const handleRoomChange = async (obj) => {
    try {
      let tempDatesIndex = itineraryData.datesArr.findIndex(elx => elx.itineraryDate == selectedHotelModalObj?.itineraryDate);
      let tempArr = itineraryData.datesArr;
      tempArr[tempDatesIndex].roomObj = obj;
      let tempPrice = calculateFinalPrice(itineraryData.flightObj, [...tempArr], itineraryData?.isHotelApiDisabled)
      // let tempPrice = parseInt(itineraryData.flightObj?.Fare) + tempArr.reduce((acc, el) => acc + parseInt(el.activityObj.acitivityPriceObj.priceFor1) + parseInt(el.hotelObj?.price), 0)
      let tempObj = {
        ...itineraryData,
        totalPrice: parseInt(tempPrice),
        datesArr: tempArr
      }
      const { data: res } = await updateItinerary(tempObj, itineraryData?._id);
      if (res) {
        toastSuccess(res.message)
        window.location.reload()
      }
    } catch (error) {
      toastError(error);
    }
  }

  const handleGetActivity = async () => {
    try {
      const { data: res } = await getActivitiesForItinerary(itineraryObj?._id);
      if (res) {
        let tempArr = res.data
        tempArr = tempArr.map(el => ({ ...el, isSelected: false }))
        setActivityArr(tempArr)
        console.log(tempArr, "DATA")
      }
    } catch (error) {
      toastError(error)
    }
  }

  const handleActivitySelect = async (index) => {
    let tempArr = [...activityArr]
    tempArr = tempArr.map((el, i) => ({ ...el, isSelected: i == index }))
    setActivityArr(tempArr)
  }


  const handleActivityChange = async (index) => {
    let tempDatesIndex = itineraryData.datesArr.findIndex(elx => elx.itineraryDate == selectedHotelModalObj?.itineraryDate);
    let tempArr = itineraryData.datesArr;
    tempArr[tempDatesIndex].activityObj = activityArr[index]



    let tempPrice = calculateFinalPrice(itineraryData.flightObj, [...tempArr])
    console.log(tempPrice, "TEMPPRICE")
    // let tempPrice = parseInt(itineraryData.flightObj?.Fare) + parseInt(tempArr.reduce((acc, el) => acc + el?.hotelObj?.price ? parseInt(el.hotelObj?.price) : 0), 0) + parseInt(tempArr.reduce((acc, el) => acc + el?.activityObj?.acitivityPriceObj?.priceFor1 ? parseInt(el?.activityObj?.acitivityPriceObj?.priceFor1) : 0), 0)
    console.log(tempPrice)
    let tempObj = {
      ...itineraryData,
      datesArr: tempArr,
      totalPrice: tempPrice
    }
    const { data: res } = await updateItinerary(tempObj, itineraryData?._id);
    if (res) {
      toastSuccess(res.message)
      window.location.reload()
    }
  }


  const getHotelImageUrl = (hotelObj) => {
    if (itineraryData?.isHotelApiDisabled) {
      return generateFilePath(hotelObj?.imageUrl)
    }
    else {
      return hotelObj?.imageUrl
    }
  }


  useEffect(() => {
    setTimeout(() => {

      if (changeRef) {
        let element = document.getElementById("scrollToTopId")
        if (element) {
          window.scrollTo(0, element.offsetTop)
        }
      }
      else {
        changeRefFn(!changeRef)
      }
    }, 1000)
  }, [changeRef, hotelsRef.current])

  return (
    <div className="row">
      {itineraryData && (
        <>
          <div className="col-12 col-md-8 col-xxl-9">
            <div className="itinerary-hotel">
              <div className="row">
                <div className="col-12 mt-4 mt-lg-5">
                  <div className="flight-info">
                    <div className="heading">
                      <h4>Flights</h4>

                      <ul className="edit-option justify-content-end">
                        <li>
                          <div
                            onClick={() => changeTab(2)}
                            className="red text-decoration-underline"
                          >
                            Change
                          </div>
                        </li>
                        {/* <li>
                          <button
                            className="red text-decoration-underline"
                            onClick={handleShow}
                          >
                            Remove
                          </button>
                        </li> */}
                      </ul>
                    </div>
                    <div className="box">
                      <div className="row align-items-center">
                        <div className="col-2 text-end">
                          <div className="imgradius">
                            <img src={images.flight2} alt="" className="img-fluid" style={{ width: 120 }} />
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="content flex-1 borderbtom">
                            {itineraryData?.flightObj?.Legs?.map((el, i) => {
                              return (
                                <ul key={i}>

                                  <li>
                                    <h6>{el?.DepartureTime}</h6>
                                    <p>{el?.DepartureDate}</p>
                                    <p>{el?.Origin}</p>
                                  </li>
                                  <li>
                                    {getWindow <= 576 ? (
                                      <img
                                        src={images.mobile_plane}
                                        alt=""
                                        className="plane-img"
                                      />
                                    ) : (
                                      <img
                                        src={images.flight_info}
                                        alt=""
                                        className="plane-img"
                                      />
                                    )}
                                  </li>
                                  <li>
                                    <h6>{el?.ArrivalTime}</h6>
                                    <p>{el?.ArrivalDate}</p>
                                    <p>{el?.Destination}</p>
                                  </li>
                                  <li>
                                    <p className="mb-0">{el?.Duration}</p>
                                    <p>{itineraryData?.flightObj?.Legs.length > 0 ? `${itineraryData?.flightObj?.Legs.length - 1 == 0 ? "Non" : itineraryData?.flightObj?.Legs.length - 1} Stop` : "Non Stop"}</p>
                                  </li>
                                </ul>
                              )
                            })}


                          </div>
                          <div className="content flex-1">
                            {itineraryData?.returnFlightObj?.Legs?.map((el, i) => {
                              return (
                                <ul key={i}>

                                  <li>
                                    <h6>{el?.DepartureTime}</h6>
                                    <p>{el?.DepartureDate}</p>
                                    <p>{el?.Origin}</p>
                                  </li>
                                  <li>
                                    {getWindow <= 576 ? (
                                      <img
                                        src={images.mobile_plane}
                                        alt=""
                                        className="plane-img"
                                      />
                                    ) : (
                                      <img
                                        src={images.flight_info}
                                        alt=""
                                        className="plane-img"
                                      />
                                    )}
                                  </li>
                                  <li>
                                    <h6>{el?.ArrivalTime}</h6>
                                    <p>{el?.ArrivalDate}</p>
                                    <p>{el?.Destination}</p>
                                  </li>
                                  <li>
                                    <p className="mb-0">{el?.Duration}</p>
                                    <p>{itineraryData?.returnFlightObj?.Legs.length > 0 ? `${itineraryData?.returnFlightObj?.Legs.length - 1 == 0 ? "Non" : itineraryData?.returnFlightObj?.Legs.length - 1} Stop` : "Non Stop"}</p>
                                  </li>
                                </ul>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="list_flext1">
                        <div className="imagesarea">

                        </div>
                        <div className="contentarea">

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-12 mt-4 mt-lg-5">
                  <div className="hotel-img-slider">
                    {/* <div className="heading">
                      <h4>{itineraryData?.hotelObj?.name}</h4>
                      <ul className="edit-option justify-content-end">
                        <li>
                          <button className="red text-decoration-underline">
                            Change
                          </button>
                        </li>
                        <li>
                          <button className="red text-decoration-underline">
                            Remove
                          </button>
                        </li>
                      </ul>
                    </div> */}
                    <Swiper
                      id="scrollToTopId"
                      ref={hotelsRef}
                      slidesPerView={3}
                      loop
                      speed={4000}
                      modules={[Autoplay, EffectCoverflow, Pagination]}
                      pagination={{ clickable: true }}
                      autoplay={{
                        disableOnInteraction: false,
                      }}
                      effect="coverflow"
                      className="pb-5"
                      breakpoints={hotel_img}
                    >
                      {itineraryData?.hotelObj?.imageArr &&
                        itineraryData?.hotelObj?.imageArr?.length > 0 ? (
                        <SwiperSlide>
                          <div className="room-category-slider">
                            <img
                              src={getHotelImageUrl(itineraryData?.hotelObj)}
                              alt=""
                            />
                          </div>
                        </SwiperSlide>
                      ) : (
                        <>
                          <SwiperSlide>
                            <div className="room-category-slider">
                              <img
                                src={getHotelImageUrl(itineraryData?.hotelObj)}
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="room-category-slider">
                              <img
                                src={getHotelImageUrl(itineraryData?.hotelObj)}
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="room-category-slider">
                              <img
                                src={getHotelImageUrl(itineraryData?.hotelObj)}
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="room-category-slider">
                              <img
                                src={getHotelImageUrl(itineraryData?.hotelObj)}
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="room-category-slider">
                              <img
                                src={getHotelImageUrl(itineraryData?.hotelObj)}
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="room-category-slider">
                              <img
                                src={getHotelImageUrl(itineraryData?.hotelObj)}
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                        </>
                      )}
                    </Swiper>
                  </div>
                </div>

                {itineraryData?.isHotelApiDisabled ?
                  <div className="col-12 mt-4 mt-lg-5" >
                    <ul className="initinery-detail-box">
                      {itineraryData &&
                        itineraryData?.datesArr.length > 0 &&
                        itineraryData?.datesArr?.map((item, i) => {
                          return (
                            <li
                              className={`pointer ${item.active ? "active" : ""}`}
                              key={i}
                            // onClick={() => ActiveTab(i)}
                            >
                              <div>
                                <p className="day">{`Day ${i + 1}`}</p>
                                <p
                                  className={`date ${item.active ? "visible" : "invisible"
                                    }`}
                                >
                                  {moment(item.itineraryDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="dot"></div>
                              <div className="flex-1">
                                <div className="name-hotel">
                                  <button
                                    className="btn btn-custom"
                                    onClick={() =>
                                      handleSelectShow(i, "activity")
                                    }
                                  >
                                    {item?.roomObj?.name}
                                  </button>
                                  <button
                                    className="btn btn-custom"
                                    onClick={() => handleSelectShow(i, "hotel")}
                                  >
                                    {item?.hotelObj?.name}
                                  </button>
                                </div>
                                {item.active && (
                                  <span>
                                    {item.sectionShown == "activity" ? (
                                      <div className="box">
                                        <div className="activity">
                                          <div>
                                            <h5>{item?.roomObj?.name}</h5>
                                            {/* <i className="small">{item.time}</i> */}
                                          </div>
                                          <ul className="edit-option">
                                            <li>
                                              <button className="red text-decoration-underline" onClick={() => { handleHotelModalSelection(item) }}>
                                                Change
                                              </button>
                                            </li>
                                            <li>
                                              {/* <button className="red text-decoration-underline">
                                                Remove
                                              </button> */}
                                            </li>
                                          </ul>
                                        </div>
                                        <p>{item?.roomObj?.description}</p>
                                        <div className="inclusion-exclusion">
                                          <div>
                                            <h6>Inclusions</h6>
                                            <ul className="m-0">
                                              {item?.roomObj?.inclusionsArr?.map(
                                                (elx, index) => {
                                                  return (
                                                    <li key={index}>
                                                      <div className="icon text-success">
                                                        <BsFillPatchCheckFill />
                                                      </div>
                                                      {elx?.text}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                          <div>
                                            <h6>Exclusions</h6>
                                            <ul className="m-0">
                                              {item?.activityObj?.exclusionsArr?.map(
                                                (elx, index) => {
                                                  return (
                                                    <li key={index}>
                                                      <div className="icon text-danger">
                                                        <AiFillCloseCircle />
                                                      </div>
                                                      {elx.text}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="box">
                                        <div className="activity">
                                          <ul className="edit-option d-flex ms-auto">
                                            <li>
                                              {/* <button
                                                onClick={() =>
                                                  handleHotelModalSelection(item)
                                                }
                                                className="red text-decoration-underline"
                                              >
                                                Change
                                              </button> */}
                                            </li>
                                            <li>
                                              {/* <button className="red text-decoration-underline">
                                              Remove
                                            </button> */}
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="inclusion-exclusion">
                                          <div
                                            className="d-flex"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              flex: 5,
                                            }}
                                          >
                                            <img
                                              src={getHotelImageUrl(item?.hotelObj)}
                                              style={{
                                                height: 90,
                                                width: 90,
                                                borderRadius: "10%",
                                                display: "flex",
                                              }}
                                            />
                                            <h5
                                              style={{
                                                display: "flex",
                                                marginLeft: 15,
                                                maxWidth: "100%",
                                              }}
                                            >
                                              {item?.hotelObj?.name}
                                            </h5>
                                          </div>
                                          <span
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              flex: 1,
                                            }}
                                          >
                                            <h6>
                                              Price :{" "}
                                              {parseFloat(
                                                item.hotelObj.price
                                              ).toFixed(2)}
                                            </h6>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              flex: 1,
                                            }}
                                          >
                                            <h6>
                                              Rating : {item?.hotelObj?.rating}/5
                                            </h6>
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </span>
                                )}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  :

                  <div className="col-12 mt-4 mt-lg-5" >
                    <ul className="initinery-detail-box">
                      {itineraryData &&
                        itineraryData?.datesArr.length > 0 &&
                        itineraryData?.datesArr?.map((item, i) => {
                          return (
                            <li
                              className={`pointer ${item.active ? "active" : ""}`}
                              key={i}
                            // onClick={() => ActiveTab(i)}
                            >
                              <div>
                                <p className="day">{`Day ${i + 1}`}</p>
                                <p
                                  className={`date ${item.active ? "visible" : "invisible"
                                    }`}
                                >
                                  {moment(item.itineraryDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="dot"></div>
                              <div className="flex-1">
                                <div className="name-hotel">
                                  <button
                                    className="btn btn-custom"
                                    onClick={() =>
                                      handleSelectShow(i, "activity")
                                    }
                                  >
                                    {item?.activityObj?.name}
                                  </button>
                                  <button
                                    className="btn btn-custom"
                                    onClick={() => handleSelectShow(i, "hotel")}
                                  >
                                    {item?.hotelObj?.name}
                                  </button>
                                </div>
                                {item.active && (
                                  <span>
                                    {item.sectionShown == "activity" ? (
                                      <div className="box">
                                        <div className="activity">
                                          <div>
                                            <h5>{item?.activityObj?.name}</h5>
                                            {/* <i className="small">{item.time}</i> */}
                                          </div>
                                          <ul className="edit-option">
                                            <li>
                                              <button className="red text-decoration-underline" onClick={() => { setSelectedHotelModalObj(item); setOpencadmodal(true) }}>
                                                Change
                                              </button>
                                            </li>
                                            <li>
                                              <button className="red text-decoration-underline">
                                                Remove
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                        <p>{item?.activityObj?.description}</p>
                                        <div className="inclusion-exclusion">
                                          <div>
                                            <h6>Inclusions</h6>
                                            <ul className="m-0">
                                              {item?.activityObj?.inclusionsArr?.map(
                                                (elx, index) => {
                                                  return (
                                                    <li key={index}>
                                                      <div className="icon text-success">
                                                        <BsFillPatchCheckFill />
                                                      </div>
                                                      {elx?.text}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                          <div>
                                            <h6>Exclusions</h6>
                                            <ul className="m-0">
                                              {item?.activityObj?.exclusionsArr?.map(
                                                (elx, index) => {
                                                  return (
                                                    <li key={index}>
                                                      <div className="icon text-danger">
                                                        <AiFillCloseCircle />
                                                      </div>
                                                      {elx.text}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="box">
                                        <div className="activity">
                                          <ul className="edit-option d-flex ms-auto">
                                            <li>
                                              <button
                                                onClick={() =>
                                                  handleHotelModalSelection(item)
                                                }
                                                className="red text-decoration-underline"
                                              >
                                                Change
                                              </button>
                                            </li>
                                            <li>
                                              {/* <button className="red text-decoration-underline">
                                                Remove
                                              </button> */}
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="inclusion-exclusion">
                                          <div
                                            className="d-flex"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              flex: 5,
                                            }}
                                          >
                                            <img
                                              src={item.hotelObj?.imageUrl}
                                              style={{
                                                height: 90,
                                                width: 90,
                                                borderRadius: "10%",
                                                display: "flex",
                                              }}
                                            />
                                            <h5
                                              style={{
                                                display: "flex",
                                                marginLeft: 15,
                                                maxWidth: "100%",
                                              }}
                                            >
                                              {item?.hotelObj?.name}
                                            </h5>
                                          </div>
                                          <span
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              flex: 1,
                                            }}
                                          >
                                            <h6>
                                              Price :{" "}
                                              {parseFloat(
                                                item.hotelObj.price
                                              ).toFixed(2)}
                                            </h6>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              flex: 1,
                                            }}
                                          >
                                            <h6>
                                              Rating : {item?.hotelObj?.rating}/5
                                            </h6>
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </span>
                                )}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>

                }
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-xxl-3">
            <div className="trip-price-detail style-2 rounded-3 mb-4">
              <div className="top">
                <p>
                  {moment(itineraryData?.startDate).format("DD-MMM")} - {moment(itineraryData?.endDate).format("DD-MMM")}, {itineraryData?.peopleTravelling} Guests, {Math.round(itineraryData?.peopleTravelling / 2)} Room
                  <span className="red ms-2">
                    <FiEdit />
                  </span>
                </p>
              </div>
              <ul className="box">
                <li>
                  <span>
                    Price for {itineraryData?.peopleTravelling} persons
                  </span>
                  ₹{itineraryData?.totalPrice}
                </li>
              </ul>
              <ul className="box">
                <li>
                  <span>Trip Cost</span>₹{itineraryData?.totalPrice}
                </li>
                <li>
                  <span>GST</span>₹{itineraryData?.gst}
                </li>
                <li>
                  <span>TCS</span>₹{itineraryData?.tcs}
                </li>
                <li>
                  <span>Payable Amount</span>
                  <span className="total text-success fw-bold">
                    ₹{itineraryData?.totalPrice}
                  </span>
                </li>
              </ul>
              <div className="text-center my-4">
                <Link to="/Checkout" className="btn red-bg btn-custom me-2">
                  Proceed To Book Online
                </Link>
                <Link to="/" className="btn red-bg btn-custom">
                  Edit
                </Link>
              </div>
            </div>
            <div
              className="talk-to-expert rounded-3 mb-4"
              style={{ maxWidth: "100%" }}
            >
              <div className="title-section mb-3">
                <h4 className="heading mb-1">Talk to an epert !!!</h4>
                <p>Get in touch with us, we are available 24 X 7.</p>
              </div>
              <form action="/" className="form row">
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="E-mail"
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone No."
                  />
                </div>
                <div className="col-12">
                  <textarea
                    rows="2"
                    className="form-control"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-custom red-bg px-4">
                    Send Enquiry
                  </button>
                </div>
              </form>
            </div>
            <div className="why-deyor-box rounded-3">
              <h4 className="text-center">Why Deyor?</h4>
              <ul className="list-circle my-4">
                <li>All inclusive real-time pricing</li>
                <li>Travel concierge through app</li>
                <li>Flexible payment options</li>
              </ul>
              <div className="text-center">
                <button className="btn btn-custom red-bg px-4">
                  GET A QUOTE
                </button>
              </div>
            </div>
          </div>
          {/* <h2 onClick={cartmodalShow}>open modal</h2> */}
        </>
      )}
      {/* details  modal cart */}
      <Modal
        size="lg"
        centered
        show={show}
        onHide={handleClose}
        className="modalcustom"
      >
        <Modal.Header closeButton className="p-0"></Modal.Header>
        <Modal.Body className="p-0">
          {itineraryData?.isHotelApiDisabled ?
            <>
              <div className="col-12">
                {roomsArr &&
                  roomsArr.length > 0 &&
                  roomsArr.map((el, i) => {
                    // console.log(el)
                    return (
                      <div key={i} className="packages-box packages-inner row g-0">
                        <div className="packages-inner-left col-12 col-lg-4">
                          <Link to="/HotelDetail">
                            <img
                              src={generateFilePath(el?.imageUrl)}
                              alt=""
                              className="w-100 img-cover"
                            />
                          </Link>
                        </div>
                        <div className="col-12 col-lg-8">
                          <div className="packages-inner-right">
                            <div className="d-flex heading gap-3 align-items-start">
                              <div>
                                <h4>{el?.roomArr?.name}</h4>

                                <ul className="offer">
                                  <li>
                                    <img src={images.hotel1} alt="" />
                                  </li>
                                  <li>
                                    <img src={images.hotel2} alt="" />
                                  </li>
                                  <li>
                                    <img src={images.hotel3} alt="" />
                                  </li>
                                  <li>
                                    <img src={images.hotel4} alt="" />
                                  </li>
                                  <li>
                                    <img src={images.hotel5} alt="" />
                                  </li>
                                </ul>
                              </div>
                              <div className="d-flex align-items-center gap-3">
                                <div className="text-end"></div>
                                <button className="btn green-bg text-white with-icon">
                                  <div className="icon">
                                    <BsFillStarFill />
                                  </div>
                                  {el?.rating}
                                </button>
                              </div>
                            </div>
                            <p className="price">
                              Starts from
                              <h3 className="red mb-0">
                                &nbsp;₹ {parseInt(el?.roomArr.adultPrice)}
                              </h3>
                              <span className="text-muted fs-15">/Person</span>
                            </p>

                            <div
                              onClick={() => handleRoomChange(el.roomArr)}
                              className="btn btn-custom red-bg px-4"
                            >
                              Change Room
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
            :
            <>
              <div className="col-12">
                {hotelArr &&
                  hotelArr.length > 0 &&
                  hotelArr.map((el, i) => {
                    return (
                      <div key={i} className="packages-box packages-inner row g-0">
                        <div className="packages-inner-left col-12 col-lg-4">
                          <Link to="/HotelDetail">
                            <img
                              src={el?.imageUrl}
                              alt=""
                              className="w-100 img-cover"
                            />
                          </Link>
                        </div>
                        <div className="col-12 col-lg-8">
                          <div className="packages-inner-right">
                            <div className="d-flex heading gap-3 align-items-start">
                              <div>
                                <h4>{el?.name}</h4>

                                <ul className="offer">
                                  <li>
                                    <img src={images.hotel1} alt="" />
                                  </li>
                                  <li>
                                    <img src={images.hotel2} alt="" />
                                  </li>
                                  <li>
                                    <img src={images.hotel3} alt="" />
                                  </li>
                                  <li>
                                    <img src={images.hotel4} alt="" />
                                  </li>
                                  <li>
                                    <img src={images.hotel5} alt="" />
                                  </li>
                                </ul>
                              </div>
                              <div className="d-flex align-items-center gap-3">
                                <div className="text-end"></div>
                                <button className="btn green-bg text-white with-icon">
                                  <div className="icon">
                                    <BsFillStarFill />
                                  </div>
                                  {el?.rating}
                                </button>
                              </div>
                            </div>
                            <p className="price">
                              Starts from
                              <h3 className="red mb-0">
                                &nbsp;₹ {parseInt(el?.price)}
                              </h3>
                              <span className="text-muted fs-15">/Person</span>
                            </p>

                            <div
                              onClick={() => handleHotelChange(el)}
                              className="btn btn-custom red-bg px-4"
                            >
                              Change Hotel
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          }
        </Modal.Body>
      </Modal>
      {/*----------------------- gallery gride modal-------------------------- */}
      <Modal size="xl" centered show={opencadmodal} onHide={cartmodalClose} className="gallerycardbox">
        <Modal.Header className="p-0" closeButton>

        </Modal.Header>
        <Modal.Body>
          <ul className="itinery-activity row gy-4">
            {activityArr && activityArr.length > 0 && activityArr.map((el, index) => {
              return (

                <li className="col-12 col-md-3 col-lg-3" onClick={() => handleActivityChange(index)} >
                  <div className="box">
                    <div className="image">
                      <img src={generateFilePath(el?.image)} alt="" className="w-100 img-cover" />
                    </div>
                    <div className="content">
                      <h5>{el?.name}</h5>
                    </div>
                  </div>
                </li>
              )
            })}

          </ul>
          {/* <div className="d-flex align-items-center justify-content-center">
            {activityArr && activityArr.some(el => el.isSelected) &&

              <div
                onClick={() => handleActivityChange()}
                className="btn btn-custom red-bg px-4"
              >
                Submit
              </div>
            }
          </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Hotel;
