import React, { useCallback, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { MdFlightTakeoff } from "react-icons/md";
import Select from "react-select";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Link, useNavigate } from "react-router-dom";
import { images } from "./Utility/Images";
import moment from "moment";
import { debounce } from "lodash";
import { getCitiesByName } from "../services/City.service";
import { toastError } from "../utils/toastUtils";


function SliderTabs({ isFlight, selecteDestinationObj, selectedOriginObj, selectedDatesObj, selectedUsers }) {
  // ============================================================================================
  const [travelInfo, setTravelInfo] = useState();
  const [switchPlaces, setSwitchPlaces] = useState("holiday");
  const [origin, setOrigin] = useState(false);
  const [destination, setDestination] = useState(false);
  const [place, setPlace] = useState(false);
  const [room, setRoom] = useState(["Room 1"]);
  const [value, onChange] = useState([new Date(), new Date()]);
  const navigate = useNavigate();
  const [cityQuery, setCityQuery] = useState("");
  const [destinationQuery, setDestinationQuery] = useState("");

  const [selectedDestination, setSelectedDestination] = useState({});
  const [selectedOrigin, setSelectedOrigin] = useState([]);
  const [travellorObj, setTravellorObj] = useState({});

  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [traveelClass, setTraveelClass] = useState("");

  const [cityArr, setCityArr] = useState([]);
  const [cityDestinationArr, setCityDestinationArr] = useState([]);
  // const handleSearchHotelByDestination=async()=>{
  //   try{
  //     const {data:res}=await
  //   }
  //   catch(error){
  //     console.error(error)
  //   }
  // }

  useEffect(() => {
    if (selectedDatesObj && selecteDestinationObj && selectedOriginObj) {
      setSelectedDestination(selecteDestinationObj);
      setCityQuery(selectedOriginObj?.name);
      setDestinationQuery(selecteDestinationObj?.name);
      setSelectedOrigin(selectedOriginObj);
      onChange(selectedDatesObj);
      setAdults(selectedUsers?.Adults);
      setInfants(selectedUsers?.Infants);
      setChildren(selectedUsers?.Childs)
      console.log(selectedDatesObj, "selectedDatesObj")
    }
  }, [selecteDestinationObj, selectedOriginObj, selectedDatesObj]);

  const handleNavigateToHotel = () => {
    // let obj={
    console.log(selectedOrigin, "CHECK");
    if (selectedOrigin && selectedOrigin?.cityCode && selectedOrigin?.countryCode) {
      let obj = {
        arrivalDate: new Date(value[0]).toISOString(),
        departureDate: new Date(value[1]).toISOString(),
        countryCode: selectedOrigin?.countryCode,
        cityCode: selectedOrigin?.cityCode,
      };
      navigate(`/Hotel?search=${encodeURIComponent(JSON.stringify(obj))}`);
    } else {
      toastError({ message: "Please Select Destination" });
    }
  };

  const handleFlightSearch = async () => {
    if (!selectedDestination.name) {
      toastError("Please Select destination");
      return;
    }
    if (!selectedOrigin.name) {
      toastError("Please Select origin");
      return;
    }
    if (children == 0 && adults == 0 && infants == 0) {
      toastError("Please Select atleast one traveller");
      return;
    }

    // To set two dates to two variables
    var date1 = new Date(value[0]);
    var date2 = new Date(value[1]);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    let obj = {
      Cabin: 0,
      Childs: children,
      FlightSearchDetails: [],
      Infants: infants,
      TripType: 0,
      Adults: adults,
    };

    if (Difference_In_Days > 1) {
      obj.FlightSearchDetails.push(
        {
          BeginDate: moment(new Date(value[0])).format("YYYY-MM-DD"),
          // EndDate: moment(new Date(value[1])).format("YYYY-MM-DD"),
          // CurrencyCode: "INR",
          Origin: selectedOrigin?.airportCode,
          Destination: selectedDestination?.airportCode,
        },
        {
          BeginDate: moment(new Date(value[1])).format("YYYY-MM-DD"),
          // EndDate: moment(new Date(value[1])).format("YYYY-MM-DD"),
          // CurrencyCode: "INR",
          Origin: selectedDestination?.airportCode,
          Destination: selectedOrigin?.airportCode,
        }
      );
    } else {
      obj.FlightSearchDetails.push({
        BeginDate: moment(new Date(value[0])).format("YYYY-MM-DD"),
        // EndDate: moment(new Date(value[1])).format("YYYY-MM-DD"),
        CurrencyCode: "INR",
        Origin: selectedOrigin?.airportCode,
        Destination: selectedDestination?.airportCode,
      });
    }

    let dataObj = {
      destinationObj: selectedDestination,
      originObj: selectedOrigin,
      date: value,
    };
    console.log(dataObj)
    navigate(`/Flight?dataPrefill=${encodeURIComponent(JSON.stringify(dataObj))}&search=${encodeURIComponent(JSON.stringify(obj))}`);
  };

  const activePlace = (i) => {
    let temp = choosePlace.map((item, index) => {
      if (i === index) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
    setchoosePlace([...temp]);
  };
  const [choosePlace, setchoosePlace] = useState([
    {
      name: "Holidays",
      icon: images.holiday,
      active: true,
      value: "holiday",
    },
    {
      name: "Bike Trips",
      icon: images.bike,
      active: false,
      value: "bike",
    },
    {
      name: "Flights",
      icon: images.flight,
      active: false,
      value: "flight",
    },
    {
      name: "Hotels",
      icon: images.hotel,
      active: false,
      value: "hotel",
    },
  ]);
  const addRoom = () => {
    setRoom((temp) => [...temp, `Room ${room.length + 1}`]);
  };
  const tabClick = (i, value) => {
    setSwitchPlaces(value);
    activePlace(i);
    setCityArr([]);
    setCityDestinationArr([]);
  };

  const debouncedSave = useCallback(
    debounce((nextValue) => handleGetOriginCities(nextValue), 500),

    [] // will be created only once initially
  );

  const handleChange = (event) => {
    const nextValue = event;

    setCityQuery(nextValue);

    // Even though handleChange is created on each render and executed

    // it references the same debouncedSave that was created initially

    debouncedSave(nextValue);
  };

  const handleDestinationChange = (event) => {
    const nextValue = event;

    setDestinationQuery(nextValue);

    // Even though handleChange is created on each render and executed

    // it references the same debouncedSave that was created initially

    debouncedSaveDestination(nextValue);
  };
  const debouncedSaveDestination = useCallback(
    debounce((nextValue) => handleGetDestinationCities(nextValue), 500),

    [] // will be created only once initially
  );

  const handleGetOriginCities = async (value) => {
    try {
      if (value == "") {
        return;
      }
      let { data: res } = await getCitiesByName(`search=${value}`);
      if (res.data) {
        console.log(res.data);
        setCityArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };
  const handleGetDestinationCities = async (value) => {
    try {
      if (value == "") {
        return;
      }
      let { data: res } = await getCitiesByName(`search=${value}`);
      if (res.data) {
        setCityDestinationArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  //   ============================================================================================
  return (
    <div className="main-slider-tabs">
      <ul className="tabs">
        {choosePlace.map((item, i) => {
          return (
            <li key={i}>
              <button className={`${item.active ? "active" : ""}`} onClick={() => tabClick(i, item.value)}>
                <img src={item.icon} alt="" />
                {item.name}
              </button>
            </li>
          );
        })}
      </ul>
      {switchPlaces === "holiday" ? (
        <div className="tab-content">
          <div className="form-control rounded-pill main-search ps-5" onClick={() => navigate("/Questions")}>
            <input type="search" className="border-0 bg-transparent w-100 pe-3" placeholder="Search Holidays" />
            <button className="btn red-bg with-icon btn-custom btn-lg rounded-pill">
              <div className="icon">
                <BsSearch />
              </div>
              Search
            </button>
          </div>
        </div>
      ) : switchPlaces === "bike" ? (
        <div className="tab-content">
          <div className="form-control rounded-pill main-search ps-5" onClick={() => navigate("/Questions")}>
            <input type="search" className="border-0 bg-transparent w-100 pe-3" placeholder="Search Bike Trips" />
            <button className="btn red-bg with-icon btn-custom btn-lg rounded-pill">
              <div className="icon">
                <BsSearch />
              </div>
              Search
            </button>
          </div>
        </div>
      ) : switchPlaces === "flight" ? (
        <div className="tab-content">
          <div className="form-control rounded-pill main-search">
            <ul className="travel-info">
              <li onClick={() => setTravelInfo("origin")}>
                <div>
                  <h5>Origin</h5>
                  <p className="text">{selectedOrigin && selectedOrigin.name ? selectedOrigin.name : "Where From?"}</p>
                  {travelInfo === "origin" && (
                    <div className="search-bar">
                      {/* <Select
                                options={options}
                                classNamePrefix="travel-search"
                              /> */}
                      <div className="form-control inner-search" onClick={() => setOrigin(!origin)}>
                        <div className="icon text-dark">
                          <BsSearch />
                        </div>
                        <input type="search" onChange={(e) => handleChange(e.target.value)} value={cityQuery} className="border-0 bg-transparent" placeholder="search here..." />
                      </div>
                      {origin && (
                        <div className="custum-dropdown">
                          <p className="text-muted">Popular cities</p>
                          <ul className="list">
                            {cityArr && cityArr.length > 0 ? (
                              cityArr.map((el, i) => {
                                return (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      setSelectedOrigin(el);
                                      setCityQuery(el.name);
                                      setOrigin(false);
                                    }}
                                  >
                                    <div className="icon">
                                      <MdFlightTakeoff />
                                    </div>
                                    <div>
                                      <p className="text-dark">
                                        {el.name}, {el.countryObj.name}
                                      </p>
                                      <p className="text-muted">{el?.airportName}</p>
                                    </div>
                                  </li>
                                );
                              })
                            ) : (
                              <li>
                                <div>
                                  <p className="mx-4 my-2 text-dark">Data not found</p>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </li>
              <li onClick={() => setTravelInfo("destination")}>
                <div>
                  <h5>Destination</h5>
                  <p className="text">{selectedDestination && selectedDestination.name ? selectedDestination.name : "Where To?"}</p>
                  {travelInfo === "destination" && (
                    <div className="search-bar">
                      <div className="form-control inner-search" onClick={() => setDestination(!destination)}>
                        <div className="icon text-dark">
                          <BsSearch />
                        </div>
                        <input type="search" onChange={(e) => handleDestinationChange(e.target.value)} value={destinationQuery} className="border-0 bg-transparent" placeholder="search here..." />
                      </div>
                      {destination && (
                        <div className="custum-dropdown">
                          <p className="text-muted">Popular cities</p>
                          <ul className="list">
                            {cityDestinationArr && cityDestinationArr.length > 0 ? (
                              cityDestinationArr.map((el, i) => {
                                return (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      setSelectedDestination(el);
                                      setDestinationQuery(el.name);
                                      setDestination(false);
                                    }}
                                  >
                                    <div className="icon">
                                      <MdFlightTakeoff />
                                    </div>
                                    <div>
                                      <p className="text-dark">
                                        {el.name}, {el.countryObj.name}
                                      </p>
                                      <p className="text-muted">{el?.airportName}</p>
                                    </div>
                                  </li>
                                );
                              })
                            ) : (
                              <li>
                                <div>
                                  <p className="mx-4 my-2 text-dark">Data not found</p>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </li>
              <li onClick={() => setTravelInfo("duration")}>
                <h5>Departure - Return</h5>
                <div className="date-picker-module" >
                  <DateRangePicker onChange={onChange} value={value} />
                </div>
              </li>
              <li onClick={() => setTravelInfo("travellers")}>
                <div>
                  <h5>Travellers</h5>
                  <p className="text">{adults + children + infants}</p>
                  {travelInfo === "travellers" && (
                    <div className="search-bar traveller text-start">
                      <div className="row gy-3 gy-xxl-4">
                        <div className="col-12">
                          <p className="text">ADULTS (12y +)</p>
                          <ul className="number">
                            <li onClick={() => setAdults(0)} className={adults == 0 && "active"}>
                              0
                            </li>
                            <li onClick={() => setAdults(1)} className={adults == 1 && "active"}>
                              1
                            </li>
                            <li onClick={() => setAdults(2)} className={adults == 2 && "active"}>
                              2
                            </li>
                            <li onClick={() => setAdults(3)} className={adults == 3 && "active"}>
                              3
                            </li>
                            <li onClick={() => setAdults(4)} className={adults == 4 && "active"}>
                              4
                            </li>
                            <li onClick={() => setAdults(5)} className={adults == 5 && "active"}>
                              5
                            </li>
                            <li onClick={() => setAdults(6)} className={adults == 6 && "active"}>
                              6
                            </li>
                            <li onClick={() => setAdults(7)} className={adults == 7 && "active"}>
                              7
                            </li>
                            <li onClick={() => setAdults(8)} className={adults == 8 && "active"}>
                              8
                            </li>
                            <li onClick={() => setAdults(9)} className={adults == 9 && "active"}>
                              9
                            </li>
                            <li onClick={() => setAdults(10)} className={adults == 10 && "active"}>
                              &lt;9
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 col-lg-6">
                          <p className="text">CHILDREN (2y - 12y )</p>
                          <ul className="number">
                            <li onClick={() => setChildren(0)} className={children == 0 && "active"}>
                              0
                            </li>
                            <li onClick={() => setChildren(1)} className={children == 1 && "active"}>
                              1
                            </li>
                            <li onClick={() => setChildren(2)} className={children == 2 && "active"}>
                              2
                            </li>
                            <li onClick={() => setChildren(3)} className={children == 3 && "active"}>
                              3
                            </li>
                            <li onClick={() => setChildren(4)} className={children == 4 && "active"}>
                              4
                            </li>
                            <li onClick={() => setChildren(5)} className={children == 5 && "active"}>
                              5
                            </li>
                            <li onClick={() => setChildren(6)} className={children == 6 && "active"}>
                              6
                            </li>
                            <li onClick={() => setChildren(7)} className={children == 7 && "active"}>
                              &lt;6
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 col-lg-6">
                          <p className="text">INFANTS (below 2y)</p>
                          <ul className="number">
                            <li onClick={() => setInfants(0)} className={infants == 0 && "active"}>
                              0
                            </li>
                            <li onClick={() => setInfants(1)} className={infants == 1 && "active"}>
                              1
                            </li>
                            <li onClick={() => setInfants(2)} className={infants == 2 && "active"}>
                              2
                            </li>
                            <li onClick={() => setInfants(3)} className={infants == 3 && "active"}>
                              3
                            </li>
                            <li onClick={() => setInfants(4)} className={infants == 4 && "active"}>
                              4
                            </li>
                            <li onClick={() => setInfants(5)} className={infants == 5 && "active"}>
                              5
                            </li>
                            <li onClick={() => setInfants(6)} className={infants == 6 && "active"}>
                              6
                            </li>
                            <li onClick={() => setInfants(7)} className={infants == 7 && "active"}>
                              &lt;6
                            </li>
                          </ul>
                        </div>
                        <div className="col-12">
                          <p className="text">CHOOSE TRAVEL CLASS</p>
                          <ul className="number travel-class">
                            <li onClick={() => setTraveelClass(0)} className={traveelClass == 0 && "active"}>
                              Economy/Premium Economy
                            </li>
                            <li onClick={() => setTraveelClass(1)} className={traveelClass == 1 && "active"}>
                              Premium Economy
                            </li>
                            <li onClick={() => setTraveelClass(2)} className={traveelClass == 2 && "active"}>
                              Business
                            </li>
                          </ul>
                        </div>
                        <div className="col-12">
                          <button className="btn red-bg btn-sm btn-custom" onClick={() => setTravelInfo("")}>
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            </ul>
            <button className="btn red-bg with-icon btn-custom btn-lg rounded-pill" onClick={() => handleFlightSearch()}>
              <div className="icon">
                <BsSearch />
              </div>
              Search
            </button>
          </div>
        </div>
      ) : switchPlaces === "hotel" ? (
        <div className="tab-content">
          <div className="form-control rounded-pill main-search">
            <ul className="travel-info">
              <li onClick={() => setTravelInfo("origin")}>
                <div>
                  <h5>place</h5>
                  <p className="text small">LOCATION</p>
                  {travelInfo === "origin" && (
                    <div className="search-bar">
                      <div className="form-control inner-search" onClick={() => setPlace(!place)}>
                        <div className="icon text-dark">
                          <BsSearch />
                        </div>
                        <input type="search" onChange={(e) => handleChange(e.target.value)} value={cityQuery} className="border-0 bg-transparent" placeholder="search here..." />
                      </div>
                      {place && (
                        <div className="custum-dropdown">
                          <p className="text-muted">Popular cities</p>
                          <ul className="list">
                            {cityArr && cityArr.length > 0 ? (
                              cityArr.map((el, i) => {
                                return (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      setSelectedOrigin(el);
                                      setCityQuery(el.name);
                                      setPlace(false);
                                    }}
                                  >
                                    <div className="icon">
                                      <MdFlightTakeoff />
                                    </div>
                                    <div>
                                      <p className="text-dark">
                                        {el.name}, {el.countryObj.name}
                                      </p>
                                      <p className="text-muted">{el?.airportName}</p>
                                    </div>
                                  </li>
                                );
                              })
                            ) : (
                              <li>
                                <div>
                                  <p className="mx-4 my-2 text-dark">Data not found</p>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </li>
              <li onClick={() => setTravelInfo("duration")}>
                <h5>Check in - Check out</h5>
                <div className="date-picker-module">
                  <DateRangePicker onChange={onChange} value={value} />
                </div>
              </li>
              <li onClick={() => setTravelInfo("room")}>
                <div>
                  <h5>Room & Guest</h5>
                  <p className="text">1</p>
                  {travelInfo === "room" && (
                    <div className="search-bar traveller room-guest text-start">
                      {room.map((item, i) => {
                        return (
                          <div key={i} className="border-bottom pb-3 mb-2">
                            <h6>{item}</h6>
                            <div className="row gy-3">
                              <div className="col-12">
                                <p className="text">ADULTS (12y +)</p>
                                <ul className="number">
                                  <li className="active">1</li>
                                  <li>2</li>
                                  <li>3</li>
                                  <li>4</li>
                                  <li>5</li>
                                  <li>6</li>
                                  <li>7</li>
                                  <li>8</li>
                                  <li>9</li>
                                  <li>10</li>
                                  <li>11</li>
                                  <li>12</li>
                                </ul>
                              </div>
                              <div className="col-12">
                                <p className="text">CHILDREN (2y - 12y )</p>
                                <ul className="number">
                                  <li className="active">0</li>
                                  <li>1</li>
                                  <li>2</li>
                                  <li>3</li>
                                  <li>4</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="d-flex align-items-center gap-3">
                        <button className="btn red-bg btn-sm btn-custom" onClick={addRoom}>
                          +&nbsp;Add Another Room
                        </button>
                        <button className="btn red-bg btn-sm btn-custom">Apply</button>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            </ul>
            <button className="btn red-bg with-icon btn-custom btn-lg rounded-pill" onClick={() => handleNavigateToHotel()}>
              <div className="icon">
                <BsSearch />
              </div>
              Search
            </button>
          </div>
        </div>
      ) : (
        <h1 className="text-white">Nothing To Show</h1>
      )}
    </div>
  );
}

export default SliderTabs;
