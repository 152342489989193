import React from "react";

function NewsLetter({ className }) {
  return (
    <section className={`newsletter bg-fix ${className ? className : ""}`}>
      <div className="container">
        <div className="row align-items-center gx-lg-5">
          <div className="col-12 col-md-4">
            <div className="title-section mb-1">
              <h1 className="text-white">Newsletter</h1>
            </div>
            <p className="fs-15 text-white">
              Beyond more stoic this along goodness hey this news far flustered
              impressive manifest after all.
            </p>
          </div>
          <div className="col-12 col-md-8">
            <form action="/" className="form row">
              <div className="col-12">
                <label className="text-white mb-2">
                  Subscribe to Our Weekly Newsletter
                </label>
                <div className="form-control rounded-pill ps-5">
                  <input
                    type="email"
                    className="border-0 bg-transparent w-100 pe-3"
                    placeholder="Our Weekly Newsletter"
                    required
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                  />
                  <button type="submit" className="btn red-bg btn-custom rounded-pill">
                    SUBSCRIBE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsLetter;
