import React, { useState } from "react";
import { BsClockFill, BsFillPatchCheckFill, BsFillStarFill } from "react-icons/bs";
import { GiTicket } from "react-icons/gi";
import { Link, useSearchParams } from "react-router-dom";
import { toastError } from "../utils/toastUtils";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import PageBanner from "./PageBanner";
import { images } from "./Utility/Images";
import { createHotelBooking } from "../services/hotels";
import { decodeSessionJwt } from "../services/users.service";

function Checkout() {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(searchParams.get("pay"));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([
    {
      name: "Card",
      active: true,
      tab: "1",
    },
    {
      name: "UPI",
      active: false,
      tab: "2",
    },
    {
      name: "Wallet",
      active: false,
      tab: "3",
    },
    {
      name: "Net Banking",
      active: false,
      tab: "4",
    },
    {
      name: "Paylater",
      active: false,
      tab: "5",
    },
  ]);

  const activeTab = (i) => {
    const temp = paymentMethod.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setPaymentMethod([...temp]);
  };

  const handlePay = async () => {
    try {
      let tokenObj = await decodeSessionJwt();
      let parsedData = JSON.parse(data);
      let obj = {
        userId: tokenObj?.userId,
        bookingObj: { ...parsedData, guestName: firstName, guestLastName: lastName },
        totalPrice: parsedData.totalPrice,
      };
      let { data: res } = await createHotelBooking(obj);
      if (res) {
        alert(res.message);
      }
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <>
      <Header />
      <main style={{ backgroundImage: `url(${images.bg1})` }}>
        <PageBanner banner3 img1={images.slide4} className="page-banner-3" title="Check Out" desp="you’re one step away form your dream destination" />

        <section className="ptb-80 checkout">
          <div className="container">
            <div className="row gy-5">
              <div className="col-12">
                <h4 className="mb-4">TRAVELLER DETAILS</h4>
                <div className="checkout-box">
                  <form className="form row">
                    {/* <div className="col-12 mb-3">
                      <input onChange={(e) => setFirstName(e.target.value)} type="text" className="form-control" placeholder="First Name" />
                    </div> */}
                    <div className="col-12 col-md-6 mb-3">
                      <input onChange={(e) => setFirstName(e.target.value)} value={firstName} type="text" className="form-control" placeholder="First Name" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <input type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} className="form-control" placeholder="Last Name" />
                    </div>
                    {/* <div className="col-12">
                      <button className="btn btn-custom red-bg px-4 py-2" type="submit">
                        Save & Continue
                      </button>
                    </div> */}
                  </form>
                </div>
              </div>
              <div className="col-12">
                <h4 className="mb-4">BOOKING SUMMARY</h4>
                <div className="packages-box packages-inner row g-0">
                  <div className="packages-inner-left col-lg-4">
                    <Link to="/">
                      <img src={images.slide2} alt="" className="w-100 img-cover" />
                    </Link>
                  </div>
                  <div className="col-lg-8">
                    <div className="packages-inner-right">
                      <div className="d-flex heading gap-3">
                        <div>
                          <Link to="/TripDetail">
                            <h4>Leh Ladakh Bike Trip</h4>
                          </Link>
                          <ul className="offer">
                            <li>
                              <Link to="/TripDetail">
                                <img src={images.secure} alt="" />
                              </Link>
                            </li>
                            <li>
                              <Link to="/TripDetail">
                                <img src={images.guide} alt="" />
                              </Link>
                            </li>
                            <li>
                              <Link to="/TripDetail">
                                <img src={images.dinner} alt="" />
                              </Link>
                            </li>
                            <li>
                              <Link to="/TripDetail">
                                <img src={images.tent} alt="" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="d-flex align-items-start gap-3">
                          <div className="text-end">
                            <p className="mb-0 text-dark fw-semibold" style={{ lineHeight: "normal" }}>
                              Superb
                            </p>
                            <span className="fs-15 fw-semibold">(153 Reviews)</span>
                          </div>
                          <button className="btn green-bg text-white with-icon">
                            <div className="icon">
                              <BsFillStarFill />
                            </div>
                            4.6
                          </button>
                        </div>
                      </div>
                      <p className="price">
                        Total Price
                        <h3 className="red mb-0">&nbsp;₹1,500</h3>
                        <span className="text-muted fs-15">/Person</span>
                      </p>
                      <ul className="detail">
                        <li style={{ color: "#70B6C1" }}>
                          <div className="icon">
                            <BsClockFill />
                          </div>
                          2022-10-20
                        </li>
                        <li>
                          <div className="icon">
                            <GiTicket />
                          </div>
                          Museum of the Future Admission Tickets
                        </li>
                      </ul>
                      <ul className="itinarary">
                        <li>2D Leh</li>
                        <li>1D Nubra Valley</li>
                        <li>1D Pangong Tso</li>
                        <li>1D Leh</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <h4 className="mb-4">PAYMENT</h4>
                {/* <ul className="filter-tabs justify-content-start mb-3">
                  {paymentMethod.map((item, i) => {
                    return (
                      <li key={i}>
                        <button className={`btn ${item.active ? "active" : ""}`} onClick={() => activeTab(i)}>
                          {item.name}
                        </button>
                      </li>
                    );
                  })}
                </ul> */}
                <div className="checkout-box">
                  <form className="form row">
                    {/* <div className="col-12 col-md-6 mb-3">
                      <input type="tel" className="form-control" placeholder="Card Number" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <input type="text" className="form-control" placeholder="Card Holder Name" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <input type="date" className="form-control" placeholder="Expiry Date" />
                    </div>
                    <div className="col-12 col-md-6">
                      <input type="text" className="form-control" placeholder="CVV" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" value="" />
                          Secure my card as per RBI guidelines
                        </label>
                      </div>
                    </div> */}
                    <div className="col-12">
                      <button onClick={() => handlePay()} className="btn btn-custom red-bg px-4 py-2 me-3" type="button">
                        Pay Now
                      </button>
                      <img src={images.card_payment} alt="" />
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-12">
                <div className="title-section bg w-100 mb-3">
                  <h2>Tickets Policies</h2>
                </div>
                <h5>Confirmation Policy</h5>
                <p>
                  The customer receives a confirmation voucher via email within 2 business hours of successful booking. In case the preferred slots are unavailable, an alternate schedule of the customer’s preference will be arranged and a
                  new confirmation voucher will be sent via email. Alternatively, the customer may choose to cancel their booking before confirmation and a full refund will be processed.
                </p>
                <h5>Refund Policy</h5>
                <p>Refunds in the DEYOR wallet will be transferred within 3 business days.</p>
                <p>Cash Refunds once initiated, usually take around 5-7 business days to get transferred to the same account through which the payment is made.</p>
                <h5>Booking Policy</h5>
                <p>For confirm booking100% payment at the time of booking.</p>
                <h5>Cancellation Policy</h5>
                <ul className="not-to-miss">
                  <li>
                    <div className="icon">
                      <BsFillPatchCheckFill />
                    </div>
                    If cancellation are made 1 days before the date of travel then 100.0% of total tour cost will be charged as cancellation fees
                  </li>
                  <li>
                    <div className="icon">
                      <BsFillPatchCheckFill />
                    </div>
                    If cancellation are made 0 days to 1 days before the date of travel then 100.0% of total tour cost will be charged as cancellation fees
                  </li>
                </ul>
                <h5>Payment Terms Policy</h5>
                <p>100.0% of total tour cost will have to be paid 0 days before the date of booking</p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Checkout;
