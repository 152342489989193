import React, { useState } from "react";
import { BsClockFill } from "react-icons/bs";
import { GiTicket } from "react-icons/gi";
import { Link } from "react-router-dom";
import { images } from "./Utility/Images";
import Modal from "react-modal";

function Help_Support() {
  const [ticketModal, setTicketModal] = useState(false);
  return (
    <>
      <section className="account-booking pb-80">
        <div className="container">
          <h4 className="mb-4">BOOKKING SUMMARY</h4>
          <div className="row gy-4">
            <div className="col-12">
              <div className="packages-box m-0 packages-inner row g-0 align-items-center">
                <div className="packages-inner-left col-lg-4">
                  <Link to="/">
                    <img
                      src={images.slide2}
                      alt=""
                      className="w-100 img-cover"
                    />
                  </Link>
                </div>
                <div className="col-lg-8">
                  <div className="packages-inner-right">
                    <Link to="/TripDetail">
                      <h4>Leh Ladakh Bike Trip</h4>
                    </Link>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                    <p className="price mb-3">
                      Total Price
                      <h3 className="red mb-0">&nbsp;₹1,500</h3>
                      <span className="text-muted fs-15">/Person</span>
                    </p>
                    <ul className="detail">
                      <li style={{ color: "#70B6C1" }}>
                        <div className="icon">
                          <BsClockFill />
                        </div>
                        2022-10-20
                      </li>
                      <li>
                        <div className="icon">
                          <GiTicket />
                        </div>
                        Museum of the Future Admission Tickets
                      </li>
                    </ul>
                    <button
                      className="btn red-bg btn-custom"
                      onClick={() => setTicketModal(true)}
                    >
                      Raise a Ticket
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="packages-box m-0 packages-inner row g-0 align-items-center">
                <div className="packages-inner-left col-lg-4">
                  <Link to="/">
                    <img
                      src={images.slide2}
                      alt=""
                      className="w-100 img-cover"
                    />
                  </Link>
                </div>
                <div className="col-lg-8">
                  <div className="packages-inner-right">
                    <Link to="/TripDetail">
                      <h4>Leh Ladakh Bike Trip</h4>
                    </Link>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                    <p className="price mb-3">
                      Total Price
                      <h3 className="red mb-0">&nbsp;₹1,500</h3>
                      <span className="text-muted fs-15">/Person</span>
                    </p>
                    <ul className="detail">
                      <li style={{ color: "#70B6C1" }}>
                        <div className="icon">
                          <BsClockFill />
                        </div>
                        2022-10-20
                      </li>
                      <li>
                        <div className="icon">
                          <GiTicket />
                        </div>
                        Museum of the Future Admission Tickets
                      </li>
                    </ul>
                    <button
                      className="btn red-bg btn-custom"
                      onClick={() => setTicketModal(true)}
                    >
                      Raise a Ticket
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="account-help mb-80">
        <div className="container">
          <ul className="row gy-5">
            <li className="col-12 col-md-4">
              <div className="why-us-box">
                <Link to="/">
                  <div className="icon">
                    <img src={images.tour_guide} alt="" />
                  </div>
                  <h5>Guides</h5>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </Link>
              </div>
            </li>
            <li className="col-12 col-md-4">
              <div className="why-us-box">
                <Link to="/Frequently-Asked-Questions">
                  <div className="icon">
                    <img src={images.faq} alt="" />
                  </div>
                  <h5>FAQ</h5>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </Link>
              </div>
            </li>
            <li className="col-12 col-md-4">
              <div className="why-us-box">
                <Link to="/">
                  <div className="icon">
                    <img src={images.community} alt="" />
                  </div>
                  <h5>Community</h5>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </Link>
              </div>
            </li>
            <li className="col-12 col-md-4">
              <div className="why-us-box">
                <a href="tel:4455667788">
                  <div className="icon">
                    <img src={images.phone} alt="" />
                  </div>
                  <h5>Call</h5>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </a>
              </div>
            </li>
            <li className="col-12 col-md-4">
              <div className="why-us-box">
                <a href="https://api.whatsapp.com/send?phone=919289370407">
                  <div className="icon">
                    <img src={images.whatsapp} alt="" />
                  </div>
                  <h5>Chat</h5>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </a>
              </div>
            </li>
            <li className="col-12 col-md-4">
              <div className="why-us-box">
                <a href="mailto:info@aahilya.com">
                  <div className="icon">
                    <img src={images.email} alt="" />
                  </div>
                  <h5>Mail</h5>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <Modal
        isOpen={ticketModal}
        onRequestClose={() => setTicketModal(false)}
        className="custom-modal"
        bodyOpenClassName="custom-modal-body"
        overlayClassName="custom-modal-overlay"
        htmlOpenClassName="custom-modal-html"
        portalClassName="custom-modal-parent"
        contentLabel="Example Modal"
      >
        <div className="talk-to-expert raise-ticket-modal">
          <div className="title-section mb-3">
            <h4 className="heading fw-semibold">
              Your issue has been noted!!!
            </h4>
          </div>
            <p>Please wait for some time.</p>
            <p>Admin will respond to your query soon.</p>
        </div>
      </Modal>
    </>
  );
}

export default Help_Support;
