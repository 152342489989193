import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/scss/main.css";
import BikeTrips from "./components/BikeTrips";
import Hotel from "./components/Hotel";
import Index from "./components/Index";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Questions from "./components/Questions/Questions";
import ScrollTop from "./components/ScrollTop";
import TermCondition from "./components/Term-and-condition";
import TripDetail from "./components/TripDetail";
import FAQ from "./components/FAQ";
import About from "./components/About";
import Blogs from "./components/Blogs";
import BlogDetail from "./components/BlogDetail";
import Testimonials from "./components/Testimonials";
import Career from "./components/Career";
import Checkout from "./components/Checkout";
import SignIn from "./components/SignIn";
import Flight from "./components/Flight";
import Account from "./components/Account";
import Press from "./components/Press";
import PressDetail from "./components/PressDetail";
import Visa from "./components/Visa";
import HotelDetail from "./components/HotelDetail";
import Itinerary from "./components/Itinerary/Itinerary";
import CareerForm from "./components/CareerForm";
import { Toaster } from "react-hot-toast";
import { getJwt } from "./services/users.service";
import { Newcheckout } from "./components/Newcheckout";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
export const AuthContext = createContext();

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);

  const handleAuthCheck = async () => {
    let token = await getJwt();
    console.log(token);
    if (token) {
      console.log("check");
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  };

  useEffect(() => {
    handleAuthCheck();
  }, []);

  return (
    <AuthContext.Provider value={[isAuthorized, setIsAuthorized]}>
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          <Route path="/" exact element={<Index />}></Route>
          <Route path="/Questions" exact element={<Questions />}></Route>
          <Route path="/Bike-Trips" exact element={<BikeTrips />}></Route>
          <Route path="/Hotel" exact element={<Hotel />}></Route>
          <Route path="/TripDetail" exact element={<TripDetail />}></Route>
          <Route path="/PrivacyPolicy" exact element={<PrivacyPolicy />}></Route>
          <Route path="/Term-and-Condition" exact element={<TermCondition />}></Route>
          <Route path="/Frequently-Asked-Questions" exact element={<FAQ />}></Route>
          <Route path="/About" exact element={<About />}></Route>
          <Route path="/Blogs" exact element={<Blogs />}></Route>
          <Route path="/Blog-Detail" exact element={<BlogDetail />}></Route>
          <Route path="/Testimonials" exact element={<Testimonials />}></Route>
          <Route path="/Careers" exact element={<Career />}></Route>
          <Route path="/Checkout" exact element={<Checkout />}></Route>
          <Route path="/SignIn" exact element={<SignIn />}></Route>
          <Route path="/Flight" exact element={<Flight />}></Route>
          <Route path="/Account" exact element={<Account />}></Route>
          <Route path="/Press" exact element={<Press />}></Route>
          <Route path="/PressDetail" exact element={<PressDetail />}></Route>
          <Route path="/Visa" exact element={<Visa />}></Route>
          <Route path="/HotelDetail" exact element={<HotelDetail />}></Route>
          <Route path="/Itinerary" exact element={<Itinerary />}></Route>
          <Route path="/Newcheckout" exact element={<Newcheckout />}></Route>

          <Route path="/CareerForm" exact element={<CareerForm />}></Route>
        </Routes>
        <Toaster />
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
