import React, { useState } from "react";
import { BsImages } from "react-icons/bs";
import { FaUserCircle, FaWallet } from "react-icons/fa";
import { GiTicket } from "react-icons/gi";
import { BiHeadphone } from "react-icons/bi";
import { images } from "./Utility/Images";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import Profile from "./Profile";
import MyBooking from "./MyBooking";
import Gallery from "./Gallery";
import Help_Support from "./Help_Support";
import DeyorCoins from "./DeyorCoins";

function Account() {
  const [filter, setFilter] = useState([
    {
      name: "Profile",
      icon: <FaUserCircle />,
      active: true,
      value: "profile",
      render: <Profile />,
    },
    {
      name: "My Booking",
      icon: <GiTicket />,
      active: false,
      value: "my_booking",
      render: <MyBooking />,
    },
    {
      name: "Deyor Coins",
      icon: <FaWallet />,
      active: false,
      value: "wallet",
      render: <DeyorCoins />,
    },
    {
      name: "Gallery",
      icon: <BsImages />,
      active: false,
      value: "gallery",
      render: <Gallery />,
    },
    {
      name: "Help & Support",
      icon: <BiHeadphone />,
      active: false,
      value: "help",
      render: <Help_Support />,
    },
  ]);
  const activeFilter = (i) => {
    let temp = filter.map((item, index) => {
      if (i === index) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
    setFilter([...temp]);
  };
  return (
    <>
      <Header />
      <main style={{ backgroundImage: `url(${images.flight_bg})` }}>
        <section className="account-banner mx-4pc mb-5 px-4pc">
          <div className="container-fluid">
            <ul className="tabs">
              {filter.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={`${item.active ? "active" : ""}`}
                    onClick={() => activeFilter(i)}
                  >
                    <div className="icon red">{item.icon}</div>
                    <p className="mb-0">{item.name}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        {/* <!-- ========== Start Section ========== --> */}

        {filter.map((item, i) => item.active && <div key={i}>{item.render}</div>)}

        {/* <!-- ========== End Section ========== --> */}
      </main>
      <Footer />
    </>
  );
}

export default Account;
