// searchCityByName
import axios from "axios";
import { url } from "./url";
let serverlUrl = `${url}/TravelPurpose`;



export const getTravelPurposes = async (query) => {
    return axios.get(`${serverlUrl}/getTravelPurpose?${query}`);
};


