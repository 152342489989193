import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import PageBanner from "./PageBanner";
import { images } from "./Utility/Images";

function PressDetail() {
  return (
    <>
      <Header />
      <main>
        <PageBanner
          banner3
          img1={images.holiday2}
          className="page-banner-3 mx-4pc rounded-5"
          cols="col-lg-8"
        />
        <section className="blog single-blog mb-80 mt-4 px-4pc">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="blog-box">
                  <h4>
                    Integer turpis ligula, tempor a libero in, pharetra aliquet
                    dolor
                  </h4>
                  <div className="content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Fusce eleifend
                      tempor malesuada. Donec tempor quam vel mi laoreet
                      convallis. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Fusce eleifend tempor malesuada. Donec
                      tempor quam vel mi laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default PressDetail;
