// searchCityByName
import axios from "axios";
import { url } from "./url";
let serverlUrl = `${url}/city`;



export const getCitiesByName = async (query) => {
    return axios.get(`${serverlUrl}/searchCityByName?${query}`);
};


export const getDepartureCityForItinerary = async (query) => {
    return axios.get(`${serverlUrl}/getDepartureCityForItinerary?${query}`);
};

export const getArrivalCityForItinerary = async (query) => {
    console.log("GetArrival")
    return axios.get(`${serverlUrl}/getArrivalCityForItinerary?${query}`);
};

