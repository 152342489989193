import React from "react";

function PageBanner({
  className,
  cols,
  title,
  desp,
  img1,
  img2,
  img3,
  img4,
  img5,
  banner1Class,
  banner1,
  banner2,
  banner3,
}) {
  return (
    <section
      className={`page-banner ${className ? className : ""}`}
      style={banner3 && { backgroundImage: `url(${img1})` }}
    >
      {banner1 && <img src={img1} alt="" className={`${banner1Class ? banner1Class : ""}`} />}
      {banner2 && (
        <div className="row g-0 px-4pc">
          <div className="col-12 col-lg-6">
            <div className="image left">
              <img src={img1} alt="" />
            </div>
          </div>
          <div className="col-12 col-lg-6 row gx-2 gx-lg-0">
            <div className="col-3 col-md-12">
              <div className="image right">
                <img src={img2} alt="" />
              </div>
            </div>
            <div className="col-3 col-md-4">
              <div className="image right">
                <img src={img3} alt="" />
              </div>
            </div>
            <div className="col-3 col-md-4">
              <div className="image right">
                <img src={img4} alt="" />
              </div>
            </div>
            <div className="col-3 col-md-4">
              <div className="image right">
                <img src={img5} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
      {banner3 && (
        <div className="container">
          {(title || desp) && (
            <div
              className={`page-banner-content col-12 ${
                cols ? cols : "col-lg-4"
              }`}
            >
              {title && <h1 className="text-white">{title}</h1>}
              {desp && <p className="text-white">{desp}</p>}
            </div>
          )}
        </div>
      )}
    </section>
  );
}

export default PageBanner;
