import React, { useEffect, useRef, useState } from "react";
import { BiSwim, BiTransfer } from "react-icons/bi";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaHotel, FaTrain } from "react-icons/fa";
import { MdOutlineFlight } from "react-icons/md";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Activity from "./Activity";
import Flight from "./Flight";
import Hotel from "./Hotel";
import Transfer from "./Transfer";
import { useSearchParams } from 'react-router-dom'
import { toastError } from "../../utils/toastUtils";
import { downloadPdf, getItineraryById } from "../../services/Itinerary.service";

function Itinerary() {
  const itineraryRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams()
  const [changeRefToScroll, setChangeRefToScroll] = useState(false);
  const [itineraryObj, setItineraryObj] = useState(null);
  const getItinerary = async () => {
    try {
      const { data: res } = await getItineraryById(searchParams.get("itineraryid"));
      if (res) {
        let tempData = res.data;
        tempData.datesArr = tempData?.datesArr?.map((el, i) => ({ ...el, active: true }))
        setItineraryObj({ ...tempData })
      }
    } catch (error) {
      toastError(error)
    }
  }
  const [filterTabs, setFilterTabs] = useState([
    {
      name: "ITINERARY",
      active: true,
      tab: "1",
    },
    {
      name: "POLICIES",
      active: false,
      tab: "2",
    },
  ]);

  const [itinerary, setItinerary] = useState([
    {
      name: "Itinerary",
      active: true,
      icon: <FaTrain />,
      value: "hotels",
    },
    {
      name: "Hotels",
      active: false,
      icon: <FaHotel />,
      value: "Ref",
    },
    {
      name: "Flights",
      active: false,
      icon: <MdOutlineFlight />,
      value: "flights",
    },
    // {
    //   name: "Transfers",
    //   active: false,
    //   icon: <BiTransfer />,
    //   value: "transfers",
    // },
    // {
    //   name: "Activity",
    //   active: false,
    //   icon: <BiSwim />,
    //   value: "activity",
    // },
  ]);

  const filterActiveTab = (i) => {
    var height = document.querySelector("ul.filter-1").offsetHeight + 30;
    window.scrollTo(0, itineraryRef.current.offsetTop - height);
    const temp = filterTabs.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setFilterTabs([...temp]);
  };

  const itineraryActiveTab = (i) => {
    const temp = itinerary.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setItinerary([...temp]);
  };


  useEffect(() => {
    getItinerary()
  }, [])


  const handleGetActiveFilter = () => {
    let tempTab = filterTabs.find(el => el.tab == "1")

    if (tempTab.active == true) {
      return true
    }
    else {
      return false
    }
  }


  const handleGetActiveItenary = () => {
    let tempItenarry = itinerary.find(el => el.active)

    if (tempItenarry.value == "hotels") {
      return true
    }
    else {
      return false
    }
  }


  return (
    <>
      <Header />
      <div className="itinerary-top-banner ptb-80">
        <div className="container">
          <div className="content col-12 col-xxl-12">
            <h1>{itineraryObj?.arrivalCityObj?.name} Holiday {itineraryObj?.totalDays}N</h1>
            <ul>
              <li style={{ backgroundColor: "#FBF3F3", color: "#F27575" }}>
                {itineraryObj?.nightDayStr}
              </li>
              <li style={{ backgroundColor: "#F3FBFB", color: "#7AB5EB" }}>
                Prime Package
              </li>
              <li style={{ backgroundColor: "#F5F3FB", color: "#877ED9" }}>
                32<sup>o</sup>c/30<sup>o</sup>c
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-9"> */}
          {itineraryObj &&
            <main>


              <div className="itinerary-page mb-80">

                <div className="container-fluid" ref={itineraryRef}>
                  <div className={`left ${handleGetActiveItenary() && handleGetActiveFilter() && "col-9"}`}>
                    <ul className="filter-1 mb-4 sticky-top">
                      {filterTabs.map((item, i) => {
                        return (
                          <li
                            className={`${item.active ? "active" : ""}`}
                            onClick={() => filterActiveTab(i)}
                            key={i}
                          >
                            {item.name}
                          </li>
                        );
                      })}
                      <li>
                        <a onClick={() => downloadPdf(itineraryObj?._id)} className="pdfbtn">PDF</a>
                      </li>
                    </ul>
                    {filterTabs.map((item, i) => {
                      if (item.active && item.tab === "1") {
                        return (
                          <div key={i}>
                            <ul className="filter-2">
                              {itinerary.map((item, i) => {
                                return (
                                  <li
                                    className={`${item.active ? "active" : ""}`}
                                    onClick={() => itineraryActiveTab(i)}
                                    key={i}
                                  >
                                    <div className="icon">{item.icon}</div>
                                    {item.name}
                                  </li>
                                );
                              })}
                            </ul>

                          </div>
                        );
                      }
                      if (item.active && item.tab === "2") {
                        return (
                          <div className="itinerary-policy px-4pc" key={i}>
                            <h5>Confirmation Policy</h5>
                            <p>
                              The customer receives a confirmation voucher via email
                              within 2 business hours of successful booking. In case
                              the preferred slots are unavailable, an alternate
                              schedule of the customer’s preference will be arranged
                              and a new confirmation voucher will be sent via email.
                              Alternatively, the customer may choose to cancel their
                              booking before confirmation and a full refund will be
                              processed.
                            </p>
                            <p>
                              The customer receives a confirmation voucher via email
                              within 2 business hours of successful booking. In case
                              the preferred slots are unavailable, an alternate
                              schedule of the customer’s preference will be arranged
                              and a new confirmation voucher will be sent via email.
                              Alternatively, the customer may choose to cancel their
                              booking before confirmation and a full refund will be
                              processed.
                            </p>
                            <h5>Refund Policy</h5>
                            <p>
                              Refunds in the DEYOR wallet will be transferred within 3
                              business days.
                            </p>
                            <p>
                              Cash Refunds once initiated, usually take around 5-7
                              business days to get transferred to the same account
                              through which the payment is made.
                            </p>
                            <p>
                              Refunds in the DEYOR wallet will be transferred within 3
                              business days.
                            </p>
                            <p>
                              Cash Refunds once initiated, usually take around 5-7
                              business days to get transferred to the same account
                              through which the payment is made.
                            </p>
                            <h5>Booking Policy</h5>
                            <p>
                              For confirm booking100% payment at the time of booking.
                            </p>
                            <h5>Cancellation Policy</h5>
                            <ul className="not-to-miss">
                              <li>
                                <div className="icon">
                                  <BsFillPatchCheckFill />
                                </div>
                                If cancellation are made 1 days before the date of
                                travel then 100.0% of total tour cost will be charged
                                as cancellation fees
                              </li>
                              <li>
                                <div className="icon">
                                  <BsFillPatchCheckFill />
                                </div>
                                If cancellation are made 0 days to 1 days before the
                                date of travel then 100.0% of total tour cost will be
                                charged as cancellation fees
                              </li>
                              <li>
                                <div className="icon">
                                  <BsFillPatchCheckFill />
                                </div>
                                If cancellation are made 1 days before the date of
                                travel then 100.0% of total tour cost will be charged
                                as cancellation fees
                              </li>
                            </ul>
                            <h5>Payment Terms Policy</h5>
                            <p>
                              100.0% of total tour cost will have to be paid 0 days
                              before the date of booking
                            </p>
                            <p>
                              100.0% of total tour cost will have to be paid 0 days
                              before the date of booking
                            </p>
                          </div>
                        );
                      }
                    })}
                  </div>
                  {handleGetActiveFilter() && itinerary.map((item, i) => {
                    if (item.active && item.value === "hotels") {
                      return <Hotel itineraryObj={itineraryObj} changeTab={itineraryActiveTab} changeRef={false} changeRefFn={setChangeRefToScroll} />;
                    }
                    if (item.value == "Ref" && item.active) {
                      return <Hotel itineraryObj={itineraryObj} changeTab={itineraryActiveTab} changeRef={changeRefToScroll} changeRefFn={setChangeRefToScroll} />
                    }
                    if (item.active && item.value === "flights") {
                      return <Flight itineraryObj={itineraryObj} />;
                    }
                    if (item.active && item.value === "transfers") {
                      return <Transfer />;
                    }
                    if (item.active && item.value === "activity") {
                      return <Activity itineraryObj={itineraryObj} />;
                    }
                  })}
                </div>
              </div>
            </main>
          }

          {/* </div> */}
        </div>
      </div>


      <Footer />
    </>
  );
}

export default Itinerary;
