import React, { useState } from "react";
import Select from "react-select";
import Slider from "@mui/material/Slider";
import Offcanvas from 'react-bootstrap/Offcanvas';

function Filter({ sticky, showFilter, setShowFilter }) {
  // =====================================================================================

  const sort_by = [
    { value: "popular", label: "Popular" },
    { value: "Date, new to old", label: "Date, new to old" },
    { value: "Date, old to new", label: "Date, old to new" },
    { value: "Price, low to high", label: "Price, low to high" },
    { value: "Price, high to low", label: "Price, high to low" },
  ];

  const [destinations, setDestinations] = useState([
    "Spiti Valley",
    "Ladakh",
    "Sikkim",
    "Bhutan",
    "Rajasthan",
    "Solo Rider",
    "Dual Rider",
    "Own Bike",
  ]);

  const [duration, setDuration] = useState(["6N - 7D", "7N - 8D", "11N - 12D"]);
  const [price, setPrice] = useState([20, 37]);

  const priceValue = (value) => {
    return `₹ ${value}`;
  };

  const handlePriceChange = (event, newValue) => {
    setPrice(newValue);
  };

  //   =====================================================================================

  return (
    <Offcanvas show={showFilter} onHide={()=>setShowFilter(false)} responsive="lg">
      <Offcanvas.Header closeButton>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className={`filters packages-box ${sticky ? "sticky-top" : ""}`}>
          <div className="filters-box">
            <p className="filters-title">Filters</p>
            <Select
              classNamePrefix="sort-by"
              options={sort_by}
              placeholder="Sort By"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#eeeeee",
                  primary: "#FF5050",
                },
              })}
            />
          </div>
          <div className="filters-box">
            <p className="filters-title">Destinations</p>
            <ul className="filters-list">
              {destinations &&
                destinations.length > 0 &&
                destinations.map((item, i) => {
                  return (
                    <li key={i}>
                      <div className="filters-list-title">
                        <input type="checkbox" id={`destination_${i}`} />
                        <label htmlFor={`destination_${i}`}>{item}</label>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="filters-box">
            <p className="filters-title">Bike Riding Options</p>
            <ul className="filters-list">
              {destinations &&
                destinations.length > 0 &&
                destinations.map((item, i) => {
                  return (
                    <li key={i}>
                      <div className="filters-list-title">
                        <input type="checkbox" id={`bike_ride_${i}`} />
                        <label htmlFor={`bike_ride_${i}`}>{item}</label>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="filters-box">
            <p className="filters-title">Price Range </p>
            <Slider
              getAriaLabel={() => "Price range"}
              value={price}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              getAriaValueText={priceValue}
              className="price-range"
              //   color="#FF5050"
            />
          </div>
          <div className="filters-box">
            <p className="filters-title">Duration</p>
            <ul className="filters-list">
              {duration &&
                duration.length > 0 &&
                duration.map((item, i) => {
                  return (
                    <li key={i}>
                      <div className="filters-list-title">
                        <input type="checkbox" id={`destination_${i}`} />
                        <label htmlFor={`destination_${i}`}>{item}</label>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Filter;
