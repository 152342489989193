import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { FaQuoteLeft } from "react-icons/fa";
import { Autoplay, EffectFade, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { getAlternateFlightsForItinerary, updateItinerary } from "../../services/Itinerary.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { images } from "../Utility/Images";
import { calculateFinalPrice } from "../../utils/utils";

function Flight({ itineraryObj }) {
  const [itineraryData, setItineraryData] = useState(null);
  const [origin, setOrigin] = useState(false);
  const [destination, setDestination] = useState(false);
  const [getDate, GetDate] = useState([new Date(), new Date()]);
  const [flightArr, setFlightArr] = useState([]);
  const sort_by = [
    { value: "popular", label: "Popular" },
    { value: "Date, new to old", label: "Date, new to old" },
    { value: "Date, old to new", label: "Date, old to new" },
    { value: "Price, low to high", label: "Price, low to high" },
    { value: "Price, high to low", label: "Price, high to low" },
  ];
  const [departure, setDeparture] = useState([
    {
      img: images.flight2,
      info: {
        flightName: "Pegasus",
        flightNameValue: "20:00 - 24:00",
        flightType: "Type",
        flightTypeValue: "Non-Stop",
        flightCode: "Flight Info",
        flightCodeValue: "4 Hours",
        roundTrip: "Round Trip",
        roundTripValue: "₹10,000",
        isHigh: true,
      },
      active: false,
      date: "Fri, 26 April",
    },
    {
      img: images.flight2,
      info: {
        flightName: "Turkish Airlines",
        flightNameValue: "20:00 - 24:00",
        flightType: "Type",
        flightTypeValue: "Non-Stop",
        flightCode: "Flight Info",
        flightCodeValue: "4 Hours",
        roundTrip: "Round Trip",
        roundTripValue: "₹10,000",
        isHigh: false,
      },
      active: false,
      date: "Fri, 26 April",
    },
    {
      img: images.flight2,
      info: {
        flightName: "Pegasus",
        flightNameValue: "20:00 - 24:00",
        flightType: "Type",
        flightTypeValue: "Non-Stop",
        flightCode: "Flight Info",
        flightCodeValue: "4 Hours",
        roundTrip: "Round Trip",
        roundTripValue: "₹10,000",
        isHigh: false,
      },
      active: false,
      date: "Fri, 26 April",
    },
    {
      img: images.flight2,
      info: {
        flightName: "Turkish Airlines",
        flightNameValue: "20:00 - 24:00",
        flightType: "Type",
        flightTypeValue: "1 Stop",
        flightCode: "Flight Info",
        flightCodeValue: "4 Hours",
        roundTrip: "Round Trip",
        roundTripValue: "₹10,000",
        isHigh: true,
      },
      active: false,
      date: "Fri, 26 April",
    },
  ]);

  const ActiveTab = (i) => {
    // const temp = flightArr.map((item, index) => {
    //   i === index ? (item.active = !item.active) : (item.active = true);
    //   return item;
    // });
    // setFlightArr([...temp]);
  };

  const getAlternateFlight = async (id) => {
    try {
      const { data: res } = await getAlternateFlightsForItinerary(id);
      if (res) {
        let tempArr = res.data.map(el => ({ ...el, active: true }));
        console.log(res.data)
        setFlightArr(tempArr)
      }
    } catch (error) {
      toastError(error)
    }
  }


  const handleFlightChange = async (obj) => {
    // let tempDatesIndex = itineraryData.datesArr.findIndex(elx => elx.itineraryDate == selectedHotelModalObj?.itineraryDate);
    // let tempArr = itineraryData.datesArr;
    // tempArr[tempDatesIndex].activityObj = activityArr.find(el => el.isSelected)
    let flightObj = {
      JourneyTime: obj?.outBoundObj.JourneyTime,
      ItineraryKey: obj?.outBoundObj?.ItineraryKey,
      Legs: obj?.outBoundObj?.Legs,
      CurrencyCode: obj?.CurrencyCode,
      Fare: obj?.Fare?.TotalFareWithOutMarkUp,
    }
    let returnFlightObj = {
      JourneyTime: obj?.inboundObj.JourneyTime,
      ItineraryKey: obj?.inboundObj?.ItineraryKey,
      Legs: obj?.inboundObj?.Legs,
      CurrencyCode: obj?.CurrencyCode,
      Fare: obj?.Fare?.TotalFareWithOutMarkUp,
    }
    // let tempPrice = parseInt(flightObj?.Fare) + itineraryData?.datesArr.reduce((acc, el) => acc + parseInt(el.activityObj.acitivityPriceObj.priceFor1) + parseInt(el.hotelObj?.price), 0)
    let tempPrice = calculateFinalPrice(flightObj, itineraryData?.datesArr)

    let tempObj = {
      ...itineraryData,
      totalPrice: parseInt(tempPrice),
      flightObj,
      returnFlightObj
    }
    const { data: res } = await updateItinerary(tempObj, itineraryData?._id);
    if (res) {
      toastSuccess(res.message)
      window.location.reload()
    }
  }


  useEffect(() => {
    if (itineraryObj) {
      setItineraryData(itineraryObj)
      getAlternateFlight(itineraryObj?._id)
    }
  }, [itineraryObj])


  return (
    <div className="container">
      <div className="itinerary-flight mb-80">
        <div className="row">
          {/* <div className="col-12 mt-4 mt-lg-5">
            <ul className="flight-filter">
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Return"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Economy"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder={<HiUserGroup />}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder={<GiGymBag />}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
            </ul>
            <form className="form row select-date-origin">
              <div className="col-12 col-md-3">
                <label>Origin</label>
                <input
                  type="search"
                  className="form-control"
                  onClick={() => setOrigin(!origin)}
                />
                {origin && (
                  <div className="custum-dropdown">
                    <p className="text-muted">Popular cities</p>
                    <ul className="list">
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-3">
                <label>Destination</label>
                <input
                  type="search"
                  className="form-control"
                  onClick={() => setDestination(!destination)}
                />
                {destination && (
                  <div className="custum-dropdown">
                    <p className="text-muted">Popular cities</p>
                    <ul className="list">
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-4">
                <label>Departure - Return</label>
                <div className="date-picker-module">
                  <DateRangePicker onChange={GetDate} value={getDate} />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <button
                  type="submit"
                  className="btn red-bg with-icon btn-custom btn-lg"
                >
                  <div className="icon">
                    <BsSearch />
                  </div>
                  Search
                </button>
              </div>
            </form>
          </div>
          <div className="col-12 mt-4 mt-lg-5">
            <ul className="flight-filter">
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Stop"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Airlines"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="1 carry-on bag"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Price"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Time"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Connectiong airports"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Duration"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
            </ul>
          </div> */}
          <div className="col-12 mt-4 mt-lg-5">
            <div className="departing-flights">
              <div className="heading">
                <div>
                  <h4>Departing Flights</h4>
                  <p>
                    Total price includes raxes + fees for {itineraryData?.peopleTravelling} passenger, Additional
                    bag fees may apply
                  </p>
                </div>
                {/* <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Cheapest"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                /> */}
              </div>
              {flightArr && flightArr.length > 0 && flightArr.map((item, i) => {
                return (
                  <div className="box" key={i}>
                    <div
                      className={`inner-box pointer ${item.active ? "active" : ""
                        }`}
                      onClick={() => ActiveTab(i)}
                    >
                      <div className="left">
                        <div className="image">
                          <img src={images.flight2} alt="" className="h-100 w-100" />
                        </div>
                      </div>
                      <ul className="right flex-1">
                        {item.active ? (
                          <>
                            <li>
                              <h5>{item?.outBoundObj?.Legs[0]?.DepartureTime}-{item?.outBoundObj?.Legs[0]?.ArrivalTime}</h5>
                              <p>{item?.outBoundObj?.Legs[0]?.FlightName}</p>
                            </li>
                            <li>
                              <h5>{item?.outBoundObj?.Legs.length == 1 ? "non-stop" : `${item?.outBoundObj?.Legs.length - 1} stops`}</h5>
                              <p>Type</p>
                            </li>
                            <li>
                              <h5>{item?.outBoundObj?.Legs[0]?.AircraftCode}</h5>
                              <p>{item?.outBoundObj?.Legs[0]?.AircraftType}</p>
                            </li>
                            <li>
                              <h5>
                                {item?.outBoundObj?.JourneyTime}

                              </h5>
                              <p>₹{item?.Fare?.TotalFareWithOutMarkUp}</p>
                            </li>
                          </>
                          // <>
                          //   <li>
                          //     <h5>Depature - {item?.outBoundObj?.Legs[0]?.DepartureDate}</h5>
                          //   </li>
                          //   <li>
                          //     <button onClick={() => handleFlightChange(item)} className="btn btn-custom red-bg px-4">
                          //       Select Flight
                          //     </button>
                          //   </li>
                          // </>
                        ) : (
                          <>
                            <li>
                              <h5>{item?.outBoundObj?.Legs[0]?.DepartureTime}-{item?.outBoundObj?.Legs[0]?.ArrivalTime}</h5>
                              <p>{item?.outBoundObj?.Legs[0]?.FlightName}</p>
                            </li>
                            <li>
                              <h5>{item?.outBoundObj?.Legs.length == 1 ? "non-stop" : `${item?.outBoundObj?.Legs.length - 1} stops`}</h5>
                              <p>Type</p>
                            </li>
                            <li>
                              <h5>{item?.outBoundObj?.Legs[0]?.AircraftCode}</h5>
                              <p>{item?.outBoundObj?.Legs[0]?.AircraftType}</p>
                            </li>
                            <li>
                              <h5>
                                {item?.outBoundObj?.JourneyTime}

                              </h5>
                              <p>₹{item?.Fare?.TotalFareWithOutMarkUp}</p>
                            </li>
                          </>
                        )}
                        <li>
                          {/* <div className="icon down-arrow">
                            <BiChevronDown />
                          </div> */}
                          <button onClick={() => handleFlightChange(item)} className="btn btn-custom red-bg px-4">
                            Select Flight
                          </button>
                        </li>
                      </ul>
                    </div>
                    {item.active && (
                      <div className="outer-box">
                        <ul>
                          <li>
                            <div className="time">{item?.outBoundObj?.Legs[0]?.DepartureTime}</div>
                            <div className="dot"></div>
                            <div>
                              <h6 className="mb-0">
                                {itineraryData?.departureCityObj?.airportName} ({itineraryData?.departureCityObj?.airportCode})
                              </h6>
                              <i className="small">Travel Time: {item?.outBoundObj?.JourneyTime}</i>
                            </div>
                          </li>
                          <li>
                            <div className="time">{item?.outBoundObj?.Legs[0]?.ArrivalTime}</div>
                            <div className="dot"></div>
                            <div>
                              <h6 className="mb-0">
                                {itineraryData?.arrivalCityObj?.airportName} ({itineraryData?.arrivalCityObj?.airportCode})
                              </h6>
                              <i className="small">
                                {item?.outBoundObj?.Legs[0]?.FlightName} - {item?.outBoundObj?.Legs[0]?.Cabin} - {item?.outBoundObj?.Legs[0]?.AircraftCode} -{item?.outBoundObj?.Legs[0]?.AircraftType}
                              </i>
                            </div>
                          </li>
                        </ul>
                        <p className="travel-time text-dark fw-semibold">
                          {/* 1 hr 50 min layover Kyiv (KBR) */} Return
                        </p>
                        <ul>

                          <li>
                            <div className="time">{item?.inboundObj?.Legs[0]?.DepartureTime}</div>
                            <div className="dot"></div>
                            <div>
                              <h6 className="mb-0">
                                {itineraryData?.arrivalCityObj?.airportName} ({itineraryData?.arrivalCityObj?.airportCode})
                              </h6>
                              <i className="small">
                                {item?.inboundObj?.Legs[0]?.FlightName} - {item?.inboundObj?.Legs[0]?.Cabin} - {item?.inboundObj?.Legs[0]?.AircraftCode} -{item?.inboundObj?.Legs[0]?.AircraftType}
                              </i>
                            </div>
                          </li>
                          <li>
                            <div className="time">{item?.inboundObj?.Legs[0]?.ArrivalTime}</div>
                            <div className="dot"></div>
                            <div>
                              <h6 className="mb-0">
                                {itineraryData?.departureCityObj?.airportName} ({itineraryData?.departureCityObj?.airportCode})
                              </h6>
                              <i className="small">Travel Time: {item?.inboundObj?.JourneyTime}</i>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials-style-2">
        <div className="title-section bg text-center w-100 mb-4">
          <h1 className="heading fw-semibold">Testimonials</h1>
          <p className="text-dark fs-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          autoplay={{ disableOnInteraction: false }}
          loop
          speed={2000}
          modules={[Autoplay, Navigation, EffectFade]}
          navigation
        // effect="fade"
        >
          <SwiperSlide>
            <div className="testimonials-style-2-box">
              <div className="row align-items-center gx-5">
                <div className="col-12 col-lg-6">
                  <div className="image left">
                    <img src={images.testimonial1} alt="" />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="right">
                    <div className="icon red fs-1 mb-md-4">
                      <FaQuoteLeft />
                    </div>
                    <h4 className="tour-to">Maldives</h4>
                    <div className="content">
                      <p>
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                    <div className="name">
                      <h5>Anjali Nayak</h5>
                      <p>
                        <i>Maldives Honeymoon</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonials-style-2-box">
              <div className="row align-items-center gx-5">
                <div className="col-12 col-lg-6">
                  <div className="image left">
                    <img src={images.testimonial2} alt="" />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="right">
                    <div className="icon red fs-1 mb-md-4">
                      <FaQuoteLeft />
                    </div>
                    <h4 className="tour-to">Maldives</h4>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Fusce eleifend tempor malesuada. Donec tempor quam vel
                        mi laoreet convallis. Praesent euismod tortor quis
                        iaculis ultrices. Phasellus felis lorem, ultricies nec
                        ligula finibus, mollis aliquet erat. Nulla neque ipsum,
                        laoreet ut lacinia id, finibus eget arcu. Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit. Fusce
                        eleifend tempor malesuada.
                      </p>
                    </div>
                    <div className="name">
                      <h5>Anjali Nayak</h5>
                      <p>
                        <i>Maldives Honeymoon</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonials-style-2-box">
              <div className="row align-items-center gx-5">
                <div className="col-12 col-lg-6">
                  <div className="image left">
                    <img src={images.testimonial3} alt="" />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="right">
                    <div className="icon red fs-1 mb-md-4">
                      <FaQuoteLeft />
                    </div>
                    <h4 className="tour-to">Maldives</h4>
                    <div className="content">
                      <p>
                        Praesent euismod tortor quis iaculis ultrices. Phasellus
                        felis lorem, ultricies nec ligula finibus, mollis
                        aliquet erat. Nulla neque ipsum, laoreet ut lacinia id,
                        finibus eget arcu. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Fusce eleifend tempor
                        malesuada.
                      </p>
                    </div>
                    <div className="name">
                      <h5>Anjali Nayak</h5>
                      <p>
                        <i>Maldives Honeymoon</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Flight;
