import React, { useEffect, useState, useContext } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../App";
import { handleLogin, registerNewUser, setJwt } from "../services/users.service";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { images } from "./Utility/Images";

function SignIn() {
  const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
  const navigate = useNavigate();
  const [eye, setEye] = useState(false);
  const [show, setShow] = useState("sign-in");
  const [currentQues, setCurrentQues] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [data, setData] = useState([
    {
      name: "Enter Your Email",
    },
    {
      name: "Enter Your Password",
    },
    {
      name: "Welcome to Deyor",
    },
  ]);

  const [signUpData, setSignUpData] = useState(["1", "2", "3", "4", "5"]);

  useEffect(() => {
    if (currentQues === 2 && show === "sign-in") {
      setTimeout(() => {
        handleLoginUser();
      }, 1000);
    }
    if (currentQues === 4 && show === "sign-up") {
      setTimeout(() => {
        handleRegisterUser();
      }, 1000);
    }
  }, [currentQues]);

  const handleRegisterUser = async () => {
    try {
      let obj = {
        name,
        email,
        phone: contact,
        password,
      };
      const { data: res } = await registerNewUser(obj);
      if (res) {
        toastSuccess(res.message);
        setName("");
        setEmail("");
        setPassword("");
        setContact("");
        setShow("sign-in");
        setCurrentQues(0);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleLoginUser = async () => {
    try {
      let obj = {
        email,
        password,
      };
      const { data: res } = await handleLogin(obj);
      if (res) {
        toastSuccess(res.message);
        await setJwt(res.token);
        setIsAuthorized(true);
        navigate("/");
      }
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <>
      <main className="border-top">
        <section className="sign-in">
          <div className="row h-100">
            <div className="col-12 col-lg-6 d-none d-lg-block">
              <div className="left h-100">
                <img src={images.login} alt="" className="left-image" />
                <ul className="row justify-content-center boxes">
                  <li className="col-12 col-md-4">
                    <div className="why-us-box">
                      <div className="icon">
                        <img src={images.best_price} alt="" />
                      </div>
                      <h5>Best Price Guarantee</h5>
                    </div>
                  </li>
                  <li className="col-12 col-md-4">
                    <div className="why-us-box">
                      <div className="icon">
                        <img src={images.concierge} alt="" />
                      </div>
                      <h5>24x7 Concierge</h5>
                    </div>
                  </li>
                  <li className="col-12 col-md-4">
                    <div className="why-us-box">
                      <div className="icon">
                        <img src={images.expert} alt="" />
                      </div>
                      <h5>Expert assistance</h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="right">
                <div className="text-center mb-4">
                  <Link to="/" className="navbar-brand">
                    <img src={images.logo_red} className="main-logo" alt="" />
                  </Link>
                </div>
                {show === "sign-in" && (
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="title-section mb-5">
                        <h2 className="bg-2 mx-auto">Sign In</h2>
                      </div>
                      <div className="d-flex gap-4 justify-content-center sign-in-with-timeline">
                        <ul className="timeline verticle-timeline">
                          {data.map((item, i) => {
                            return (
                              <li key={i} className={`${currentQues === i ? "half" : currentQues > i ? "full" : currentQues < i ? "empty" : ""}`} onClick={() => setCurrentQues(i)}>
                                <div className="dot"></div>
                                <div className="empty-line"></div>
                                {currentQues === i && (
                                  <div className="half-filled">
                                    <div className="half-line"></div>
                                  </div>
                                )}
                                {currentQues > i && <div className="full-filled"></div>}
                              </li>
                            );
                          })}
                        </ul>
                        <form action="/" className="form row w-100">
                          <div className="col-12">
                            {currentQues === 0 ? (
                              <>
                                <label className="text-dark">Email Address</label>
                                <div className="d-flex gap-2">
                                  <input type="email" className="form-control rounded-0" onChange={(e) => setEmail(e.target.value)} value={email} />
                                  <button
                                    className="btn red-bg px-4 py-2 rounded-0"
                                    type="submit"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (email) {
                                        setCurrentQues((item) => item + 1);
                                      }
                                    }}
                                  >
                                    Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              <p className="red fw-semibold">{email === "" ? "Enter Your Email" : email}</p>
                            )}
                          </div>
                          <div className="col-12">
                            {currentQues === 1 ? (
                              <>
                                <div className="d-flex align-items-center justify-content-between">
                                  <label className="text-dark w-auto">Password</label>
                                  <button onClick={() => setShow("forger-password")} className="red fw-semibold border-0 bg-transparent">
                                    Forget Password?
                                  </button>
                                </div>
                                <div className="d-flex gap-2">
                                  <div className="d-flex align-items-center justify-content-between form-control">
                                    <input
                                      type={`${eye ? "text" : "password"}`}
                                      className="w-100 bg-transparent border-0 rounded-0"
                                      value={password}
                                      onChange={(e) => {
                                        setPassword(e.target.value);
                                      }}
                                    />
                                    <div className="eye pointer px-2" onClick={() => setEye(!eye)}>
                                      {eye ? <FaEyeSlash /> : <FaEye />}
                                    </div>
                                  </div>
                                  <button
                                    className="btn red-bg px-4 py-2 rounded-0"
                                    type="submit"
                                    onClick={(e) => {
                                      {
                                        e.preventDefault();
                                        if (password) {
                                          setCurrentQues((item) => item + 1);
                                        }
                                      }
                                    }}
                                  >
                                    Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              <p className="red fw-semibold">{password === "" ? "Enter Your Password" : "Your Password has been stored."}</p>
                            )}
                          </div>
                          <div className="col-12">
                            <p className={`fw-semibold ${currentQues === 2 ? "red" : ""}`}>Welcome to Deyor</p>
                          </div>
                        </form>
                      </div>
                      <div className="text-center mt-4">
                        <p>
                          Don't have an account?
                          <button className="green fw-semibold border-0 bg-transparent" onClick={() => setShow("sign-up")}>
                            Signup
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {show === "sign-up" && (
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="title-section mb-5">
                        <h2 className="bg-2 mx-auto">Sign In</h2>
                      </div>
                      <div className="d-flex gap-4 justify-content-center sign-in-with-timeline">
                        <ul className="timeline verticle-timeline">
                          {signUpData.map((item, i) => {
                            return (
                              <li key={i} className={`${currentQues === i ? "half" : currentQues > i ? "full" : currentQues < i ? "empty" : ""}`} onClick={() => setCurrentQues(i)}>
                                <div className="dot"></div>
                                <div className="empty-line"></div>
                                {currentQues === i && (
                                  <div className="half-filled">
                                    <div className="half-line"></div>
                                  </div>
                                )}
                                {currentQues > i && <div className="full-filled"></div>}
                              </li>
                            );
                          })}
                        </ul>
                        <form action="/" className="form row w-100">
                          <div className="col-12">
                            {currentQues === 0 ? (
                              <>
                                <label className="text-dark">Name</label>
                                <div className="d-flex gap-2">
                                  <input type="text" className="form-control rounded-0" value={name} onChange={(e) => setName(e.target.value)} />
                                  <button
                                    className="btn red-bg px-4 py-2 rounded-0"
                                    type="submit"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (name) {
                                        setCurrentQues((item) => item + 1);
                                      }
                                    }}
                                  >
                                    Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              <p className="red fw-semibold">{name === "" ? "Enter Your Name" : name}</p>
                            )}
                          </div>
                          <div className="col-12">
                            {currentQues === 1 ? (
                              <>
                                <label className="text-dark">Email Address</label>
                                <div className="d-flex gap-2">
                                  <input type="email" value={email} className="form-control rounded-0" onChange={(e) => setEmail(e.target.value)} />
                                  <button
                                    className="btn red-bg px-4 py-2 rounded-0"
                                    type="submit"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (email) {
                                        setCurrentQues((item) => item + 1);
                                      }
                                    }}
                                  >
                                    Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              <p className="red fw-semibold">{email === "" ? "Enter Your Email" : email}</p>
                            )}
                          </div>
                          <div className="col-12">
                            {currentQues === 2 ? (
                              <>
                                <label className="text-dark">Phone No.</label>
                                <div className="d-flex gap-2">
                                  <input type="number" className="form-control rounded-0" value={contact} onChange={(e) => setContact(e.target.value)} />
                                  <button
                                    className="btn red-bg px-4 py-2 rounded-0"
                                    type="submit"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (contact) {
                                        setCurrentQues((item) => item + 1);
                                      }
                                    }}
                                  >
                                    Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              <p className="red fw-semibold">{contact === "" ? "Enter Your Phone No." : contact}</p>
                            )}
                          </div>
                          <div className="col-12">
                            {currentQues === 3 ? (
                              <>
                                <label className="text-dark w-auto">Password</label>
                                <div className="d-flex gap-2">
                                  <div className="d-flex align-items-center justify-content-between form-control">
                                    <input
                                      type={`${eye ? "text" : "password"}`}
                                      className="w-100 bg-transparent border-0 rounded-0"
                                      value={password}
                                      onChange={(e) => {
                                        setPassword(e.target.value);
                                      }}
                                    />
                                    <div className="eye pointer px-2" onClick={() => setEye(!eye)}>
                                      {eye ? <FaEyeSlash /> : <FaEye />}
                                    </div>
                                  </div>
                                  <button
                                    className="btn red-bg px-4 py-2 rounded-0"
                                    type="submit"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (password) {
                                        setCurrentQues((item) => item + 1);
                                      }
                                    }}
                                  >
                                    Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              <p className="red fw-semibold">{password === "" ? "Enter Your Password" : "Your Password has been stored."}</p>
                            )}
                          </div>
                          <div className="col-12">
                            <p className={`fw-semibold ${currentQues === 4 ? "red" : ""}`}>Welcome to Deyor</p>
                          </div>
                        </form>
                      </div>
                      <div className="text-center mt-4">
                        <p>
                          Don't have an account?
                          <button className="green fw-semibold border-0 bg-transparent" onClick={() => setShow("sign-in")}>
                            Signin
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {show === "forger-password" && (
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="title-section text-center mb-5">
                        <h2 className="bg-2 mx-auto">Reset Your Password</h2>
                        <p className="text-dark fs-5">We will send you an email to reset your password.</p>
                      </div>
                      <form action="/" className="form row">
                        <div className="col-12 mb-4">
                          <label className="text-dark">Email Address</label>
                          <input type="email" className="form-control rounded-0" />
                        </div>
                        <div className="col-12 text-center mb-4">
                          <button className="btn btn-custom red-bg px-4 py-2" type="submit">
                            Submit
                          </button>
                        </div>
                        <div className="col-12 text-center mb-4">
                          <p>
                            <button onClick={() => setShow("sign-in")} className="green fw-semibold bg-transparent border-0">
                              Go Back
                            </button>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default SignIn;
