import React, { useEffect, useState } from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Select from "react-select";
import { images } from "./Utility/Images";
import { Link } from "react-router-dom";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { MdFlightTakeoff } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import imgflight from "../assets/images/flight.jfif";
import rocket from "../assets/images/rocket.png";
import { useSearchParams } from "react-router-dom";
import { getFlightsSearchFromApi } from "../services/Flights";
import SliderTabs from "./SliderTabs";
import { BsInfoCircle, BsSliders } from "react-icons/bs";

import _ from "lodash";
import moment from "moment";

function Flight() {
  const [searchParams, setSearchParams] = useSearchParams("");
  const [flightSearch, setFlightSearch] = useState(searchParams.get("search"));
  const [dataPrefill, setDataPrefill] = useState(JSON.parse(searchParams.get("dataPrefill")));
  const sort_by = [
    { value: "popular", label: "Popular" },
    { value: "Date, new to old", label: "Date, new to old" },
    { value: "Date, old to new", label: "Date, old to new" },
    { value: "Price, low to high", label: "Price, low to high" },
    { value: "Price, high to low", label: "Price, high to low" },
  ];
  const [searchCount, setSearchCount] = useState(0);
  const [origin, setOrigin] = useState(false);
  const [destination, setDestination] = useState(false);
  const [resultJourney1FlightArr, setResultJourney1FlightArr] = useState([]);
  const [mainArr1, setMainArr1] = useState([]);
  const [resultJourney2FlightArr, setResultJourney2FlightArr] = useState([]);
  const [mainArr2, setMainArr2] = useState([]);
  const [getDate, GetDate] = useState([new Date(), new Date()]);
  const [travellerType, setTravellerType] = useState(false);
  const [isReturnJourney, setIsReturnJourney] = useState(false);
  const [selectedFlightObj, setSelectedFlightObj] = useState(null);
  const [nonStopFliter, setNonStopFliter] = useState(false);
  const [oneStop, setOneStop] = useState(false);

  const [selectedFlight1, setSelectedFlight1] = useState({});
  const [selectedFlight2, setSelectedFlight2] = useState({});

  const handleFlightsGet = async () => {
    try {
      let tempObj = JSON.parse(flightSearch);
      console.log(tempObj, "tempObj");
      const { data: res } = await getFlightsSearchFromApi(tempObj);
      if (res) {
        console.log(res.data);
        if (res.data.journey2arr.length > 0) {
          setIsReturnJourney(true);
        }

        setResultJourney1FlightArr(
          res.data.journey1arr.map((el) => {
            let obj = {
              ...el,
              selectedTab: "details",
              active: false,
            };
            return obj;
          })
        );
        setMainArr1(
          res.data.journey1arr.map((el) => {
            let obj = {
              ...el,
              selectedTab: "details",
              active: false,
            };
            return obj;
          })
        );
        setResultJourney2FlightArr(
          res.data.journey2arr.map((el) => {
            let obj = {
              ...el,
              selectedTab: "details",
              active: false,
            };
            return obj;
          })
        );
        setMainArr2(
          res.data.journey2arr.map((el) => {
            let obj = {
              ...el,
              selectedTab: "details",
              active: false,
            };
            return obj;
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleFlightsGet();
  }, [searchParams.get("dataPrefill"), searchCount]);

  useEffect(() => {
    console.log(JSON.parse(searchParams.get("dataPrefill")), 'searchParams.get("dataPrefill")');
  }, [searchParams.get("dataPrefill")]);

  const handleSelectFlightObj = (obj, i) => {
    let tempArr = resultJourney1FlightArr;

    tempArr = tempArr.map((elx, index) => {
      if (index == i) {
        elx.active = true;
      } else {
        elx.active = false;
      }
      return elx;
    });

    setResultJourney1FlightArr([...tempArr]);
  };

  const handleCheckIfFlight2isSelected = (obj) => {
    return _.isEqual(obj, selectedFlight2);
  };
  const handleCheckIfFlight1isSelected = (obj) => {
    return _.isEqual(obj, selectedFlight1);
  };

  const handleSelectNonStop = () => {
    let currentVal = !nonStopFliter;
    setNonStopFliter(currentVal);
    if (currentVal) {
      setOneStop(false);
      setResultJourney1FlightArr(mainArr1.filter((el) => el?.outBoundObj?.Legs.length == 1));
      setResultJourney2FlightArr(mainArr2.filter((el) => el?.inBoundObj?.Legs.length == 1));
    } else {
      setResultJourney1FlightArr(mainArr1);
      setResultJourney2FlightArr(mainArr2);
    }
  };
  const handleSelectOneStop = () => {
    let currentVal = !oneStop;
    setOneStop(currentVal);
    if (currentVal) {
      setNonStopFliter(false);
      setResultJourney1FlightArr(mainArr1.filter((el) => el?.outBoundObj?.Legs.length == 2));
      setResultJourney2FlightArr(mainArr2.filter((el) => el?.inBoundObj?.Legs.length == 2));
    } else {
      setResultJourney1FlightArr(mainArr1);
      setResultJourney2FlightArr(mainArr2);
    }
  };

  const handleSetActiveTab = (i, val) => {
    let tempArr = resultJourney1FlightArr;
    tempArr[i].selectedTab = val;
    setResultJourney1FlightArr([...tempArr]);
  };

  return (
    <>
      <Header />
      <main style={{ backgroundImage: `url(${images.flight_bg})` }}>
        <section className="search-flight-form mb-80 px-4pc">
          <div className="container-fluid">
            <SliderTabs
              setSearchCount={setSearchCount}
              searchCount={searchCount}
              selecteDestinationObj={dataPrefill?.destinationObj}
              selectedOriginObj={dataPrefill?.originObj}
              selectedDatesObj={dataPrefill?.date}
              selectedUsers={JSON.parse(flightSearch)}
              isFlight={true}
            />
          </div>
        </section>

        <section className="pb-80">
          {
            <>
              {dataPrefill?.date && dataPrefill?.date.length > 0 && (
                <>
                  {isReturnJourney == false ? (
                    <div className="d-flex mb-3">
                      <h4 className="me-2 ms-3 flight_page_destination_color">{dataPrefill?.originObj?.name}</h4>
                      <h4 className="flight_page_destination_color">-</h4>
                      <h4 className="ms-2 flight_page_destination_color">{dataPrefill?.destinationObj?.name}</h4>
                    </div>
                  ) : (
                    <div className="d-flex mb-3">
                      <h4 className="me-2 ms-3 flight_page_destination_color">{dataPrefill?.originObj?.name}</h4>
                      <h4 className="flight_page_destination_color">-</h4>
                      <h4 className="ms-2 flight_page_destination_color">{dataPrefill?.destinationObj?.name}</h4>
                      <h4 className="ms-2 flight_page_destination_color">-</h4>
                      <h4 className="ms-2 flight_page_destination_color">{dataPrefill?.originObj?.name}</h4>
                    </div>
                  )}
                </>
              )}

              <div className="container-fluid">
                <ul className="flights">
                  <div className="flights-right flex-1">
                    {/* ====================head======================== */}
                    <div className="flights-head flights-box mb-3">
                      <div>
                        <div className="form-check form-check-inline">
                          <label>
                            <input type="checkbox" value="option1" name="flight-filter" checked={nonStopFliter} onChange={() => handleSelectNonStop()} />
                            Non-Stop
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <label>
                            <input type="checkbox" value="option2" name="flight-filter" checked={oneStop} onChange={() => handleSelectOneStop()} />1 Stop
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <label>
                            <input type="checkbox" value="option3" name="flight-filter" />
                            Refundable
                          </label>
                        </div>
                      </div>
                      <Select
                        classNamePrefix="sort-by"
                        options={sort_by}
                        placeholder="Sort By"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#eeeeee",
                            primary: "#FF5050",
                          },
                        })}
                      />
                    </div>
                    {/* ====================head======================== */}

                    {/* ============flights====================== */}
                    {isReturnJourney == false &&
                      resultJourney1FlightArr &&
                      resultJourney1FlightArr.length > 0 &&
                      resultJourney1FlightArr.map((el, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => {
                              setSelectedFlightObj(el);
                              handleSelectFlightObj(el, i);
                              console.log(el);
                            }}
                          >
                            <div className="flights-box flights-inner">
                              <div className="left">
                                <div className="image">
                                  <Link to="/">
                                    <img src={images.flight2} alt="" className="w-100 h-100" />
                                  </Link>
                                </div>
                              </div>
                              <ul className="right flex-1">
                                <li>
                                  <h5>
                                    {el?.outBoundObj?.Legs[0]?.DepartureTime} - {el?.outBoundObj?.Legs[el?.outBoundObj?.Legs.length - 1].ArrivalTime}
                                  </h5>
                                  <p>
                                    {el?.outBoundObj?.Legs[0]?.FlightName} ({el?.outBoundObj?.Legs[el?.outBoundObj?.Legs.length - 1]?.FlightNumber})
                                  </p>
                                </li>
                                <li>
                                  <h5>{el?.outBoundObj?.JourneyTime}</h5>
                                  <p>Flight Info</p>
                                </li>
                                <li>
                                  <h5 className="mt-3">{el?.outBoundObj?.Legs.length == 1 ? "non-stop" : `${el?.outBoundObj?.Legs.length - 1} stops`}</h5>
                                </li>
                                <li>
                                  <h5 className="mt-3">₹{el?.Fare?.TotalFareWithOutMarkUp}</h5>
                                </li>
                                <li>
                                  <button className="outlineButton">Details</button>
                                </li>
                              </ul>
                            </div>
                            {el.active && (
                              <div className="active_flight">
                                <div className="container">
                                  <div className="row py-4">
                                    <div className="col-lg-8">
                                      <div className="filght_card">
                                        <ul>
                                          <li>
                                            <a onClick={() => handleSetActiveTab(i, "details")} className={el.selectedTab == "details" && "active"}>
                                              Flight Details
                                            </a>
                                          </li>
                                          <li>
                                            <a onClick={() => handleSetActiveTab(i, "faresummary")} className={el.selectedTab == "faresummary" && "active"}>
                                              Fare Summary
                                            </a>
                                          </li>
                                          <li>
                                            <a onClick={() => handleSetActiveTab(i, "cancellation")} className={el.selectedTab == "cancellation" && "active"}>
                                              Cancellation
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 text-end">
                                      <Link
                                        className="btn btn-book"
                                        to={`/Newcheckout?bookingFlight1=${encodeURIComponent(JSON.stringify(selectedFlightObj))}&search=${encodeURIComponent(JSON.stringify(flightSearch))}&dataPrefill=${encodeURIComponent(
                                          JSON.stringify(dataPrefill)
                                        )}`}
                                      >
                                        Book Now
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="table_headermain">
                                        <img src={imgflight} alt="" className="img-fluid" />{" "}
                                        <p>
                                          {el?.outBoundObj?.Legs[0]?.FlightName} ({el?.outBoundObj?.Legs[el?.outBoundObj?.Legs.length - 1]?.FlightNumber})
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 table_re">
                                      <table class="table table-bordered table_tpading">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <p> Time frame </p>
                                              <h6> From Scheduled flight departure </h6>
                                            </td>
                                            <td>
                                              <p> Airline Fee + Deyor Fee + Fare difference </p>
                                              <h6> (Per passenger)</h6>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>0 hours to 2 hours*</td>
                                            <td>ADULT : Non Changeable</td>
                                          </tr>
                                          <tr>
                                            <td>2 hours to 3 days*</td>
                                            <td>ADULT : ₹ 3,250 + ₹ 300 + Fare difference</td>
                                          </tr>
                                          <tr>
                                            <td>3 days to 365 days*</td>
                                            <td>ADULT : ₹ 2,750 + ₹ 300 + Fare difference</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}

                    {/* ============flights====================== */}
                  </div>

                  {selectedFlightObj && selectedFlightObj?.SearchId && (
                    <div className="flights-left">
                      <div className="flights-box">
                        <div className="box-head">
                          <p className="text-dark fw-semibold mb-0">
                            {selectedFlightObj?.outBoundObj?.Legs[0]?.Origin} To {selectedFlightObj?.outBoundObj?.Legs[0]?.Destination}
                          </p>
                          <p className="fw-semibold">
                            30 Dec - 2 Jan
                            <span className="red ps-2 pointer">
                              <FiEdit />
                            </span>
                          </p>
                        </div>
                        <div className="box">
                          <h5 className="title text-center mb-4">Selected Flights</h5>
                          <p className="py-2 fw-semibold text-center selected-flight">Departing Flight (30 Dec)</p>
                          {selectedFlightObj?.outBoundObj &&
                            selectedFlightObj?.outBoundObj.Legs.map((el) => {
                              return (
                                <div className="selected-flight mb-3">
                                  <div className="d-flex gap-2">
                                    <div className="text-center">
                                      <div className="image">
                                        <img src={images.flight2} alt="" />
                                      </div>
                                      <h6 className="poppin">
                                        {el.AircraftCode} - {el?.FlightNumber}
                                      </h6>
                                    </div>
                                    <ul className="d-flex gap-2 flex-1">
                                      <li>
                                        <h6 className="mb-0">{el?.DepartureTime}</h6>
                                        <p className="mb-0"> {el?.Origin}</p>
                                      </li>
                                      <li>
                                        <h6 className="mb-0">{el?.Duration}</h6>
                                        <p className="mb-0">1 stops</p>
                                      </li>
                                      <li>
                                        <h6 className="mb-0">{el?.ArrivalTime}</h6>
                                        <p className="mb-0">{el?.Destination}</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              );
                            })}

                          {/* <p className="py-2 fw-semibold text-center selected-flight">Return Flight (02 Jan)</p>
                      <div className="selected-flight mb-3">
                        <div className="d-flex gap-2">
                          <div className="text-center">
                            <div className="image">
                              <img src={images.flight2} alt="" />
                            </div>
                            <h6 className="poppin">Al - 266Q</h6>
                          </div>
                          <ul className="d-flex gap-2 flex-1">
                            <li>
                              <h6 className="mb-0">06:10</h6>
                              <p className="mb-0">DEL</p>
                            </li>
                            <li>
                              <h6 className="mb-0">8h 15m</h6>
                              <p className="mb-0">1 stops</p>
                            </li>
                            <li>
                              <h6 className="mb-0">13:55</h6>
                              <p className="mb-0">MLK</p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                          <div className="trip-price-detail mb-3">
                            <ul>
                              <li>
                                <span>Trip Cost</span>₹{selectedFlightObj?.Fare?.BasicFare}
                              </li>
                              <li>
                                <span>GST</span>₹{selectedFlightObj?.Fare?.TotalTaxWithOutMarkUp}
                              </li>
                              <li>
                                <span>TCS</span>₹{selectedFlightObj?.Fare?.TotalConvFees}
                              </li>
                              <li>
                                <span>Payable Amount</span>
                                <span className="total text-success fw-bold">₹{selectedFlightObj?.Fare?.TotalFareWithOutMarkUp}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="text-center mt-4">
                            <Link
                              className="btn red-bg btn-custom"
                              to={`/Newcheckout?bookingFlight1=${encodeURIComponent(JSON.stringify(selectedFlightObj))}&search=${encodeURIComponent(JSON.stringify(flightSearch))}&dataPrefill=${encodeURIComponent(
                                JSON.stringify(dataPrefill)
                              )}`}
                            >
                              Proceed To Book Online
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </>
          }

          {isReturnJourney && (
            <div className="container-fluid">
              <div className="row d-flex justify-content-between">
                <div className="flights col-lg-12">
                  <div className="flights-right flex-1">
                    <div className="flights-head">
                      <div className="flex-1 me-2 border_categoru">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <h4 className="me-2">{dataPrefill?.originObj?.name}</h4>
                            <h4>-</h4>
                            <h4 className="ms-2">{dataPrefill?.destinationObj?.name}</h4>
                          </div>
                          <div className="flight_page_filter">
                            <BsSliders color="white" size={20} />
                          </div>
                        </div>
                        {dataPrefill?.date && dataPrefill.date.length > 0 && <span> {moment(dataPrefill?.date[0]).format("dddd, DD MMM")}</span>}
                      </div>
                      <div className="flex-1 ms-2 border_categoru">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <h4 className="me-2">{dataPrefill?.destinationObj?.name}</h4>
                            <h4>-</h4>
                            <h4 className="ms-2">{dataPrefill?.originObj?.name}</h4>
                          </div>
                          <div className="flight_page_filter">
                            <BsSliders color="white" size={20} />
                          </div>
                        </div>
                        {dataPrefill?.date && dataPrefill.date.length > 0 && <span> {moment(dataPrefill?.date[1]).format("dddd, DD MMM")}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isReturnJourney && (
            <div className="container-fluid mt-3">
              <div className="row d-flex justify-content-between">
                <div className="flights col-lg-12">
                  <div className="flights-right flex-1">
                    <div className="flights-head">
                      <div className="flex-1 me-2 border_categoru">
                        <div className="heigh_filter_section">
                          {resultJourney1FlightArr &&
                            resultJourney1FlightArr.length > 0 &&
                            resultJourney1FlightArr.map((el, i) => {
                              return (
                                <div key={i} onClick={() => setSelectedFlight1(el)} style={{ border: `solid 1px ${handleCheckIfFlight1isSelected(el) ? "#FF5050" : "#D9D9D9"}` }} className="filter_card_area">
                                  <div className="min-card-filter">
                                    <div className="min-headr">
                                      <div className="left_min-card">
                                        <img src={imgflight} alt="" className="img-fluid" />
                                        <p className="pb-3">
                                          {el?.outBoundObj?.Legs[0]?.FlightName} ({el?.outBoundObj?.Legs[el?.outBoundObj?.Legs.length - 1]?.FlightNumber})
                                        </p>
                                      </div>
                                      <div className="right_min-card">
                                        <h4>₹{el?.Fare?.TotalFareWithOutMarkUp}/-</h4>
                                      </div>
                                    </div>
                                    <div className="min_body_filter">
                                      <div className="icon_absoli">
                                        <BsInfoCircle />
                                      </div>
                                      <div className="l_bodyfilter">
                                        <p className="time_filter">{el?.outBoundObj?.Legs[0]?.DepartureTime}</p>
                                        <p className="docureiton_filter">{el?.outBoundObj?.Legs[0]?.Origin}</p>
                                      </div>
                                      <div className="c_bodyfilter">
                                        <p className="hourse">{el?.outBoundObj?.JourneyTime}</p>
                                        <p className="rocket_img">
                                          <img src={rocket} alt="" className="img-fluid" />
                                        </p>
                                        <p className="non_stoop">{el?.outBoundObj?.Legs.length == 1 ? "non-stop" : `${el?.outBoundObj?.Legs.length - 1} stops`}</p>
                                        <p></p>
                                      </div>
                                      <div className="r_bodyfilter">
                                        <p className="time_filter">{el?.outBoundObj?.Legs[el?.outBoundObj?.Legs.length - 1]?.ArrivalTime}</p>
                                        <p className="docureiton_filter">{el?.outBoundObj?.Legs[el?.outBoundObj?.Legs.length - 1]?.Destination}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="flex-1 ms-2 border_categoru">
                        <div className="heigh_filter_section">
                          {resultJourney2FlightArr &&
                            resultJourney2FlightArr.length > 0 &&
                            resultJourney2FlightArr.map((el, i) => {
                              return (
                                <div key={i} style={{ border: `solid 1px ${handleCheckIfFlight2isSelected(el) ? "#FF5050" : "#D9D9D9"}` }} onClick={() => setSelectedFlight2(el)} className="filter_card_area">
                                  <div className="min-card-filter">
                                    <div className="min-headr">
                                      <div className="left_min-card">
                                        <img src={imgflight} alt="" className="img-fluid" />
                                        <p className="pb-3">
                                          {el?.inBoundObj?.Legs[0]?.FlightName} ({el?.inBoundObj?.Legs[el?.inBoundObj?.Legs.length - 1]?.FlightNumber})
                                        </p>
                                      </div>
                                      <div className="right_min-card">
                                        <h4>₹{el?.Fare?.TotalFareWithOutMarkUp}/-</h4>
                                      </div>
                                    </div>
                                    <div className="min_body_filter">
                                      <div className="icon_absoli">
                                        <BsInfoCircle />
                                      </div>
                                      <div className="l_bodyfilter">
                                        <p className="time_filter">{el?.inBoundObj?.Legs[0]?.DepartureTime}</p>
                                        <p className="docureiton_filter">{el?.inBoundObj?.Legs[0]?.Origin}</p>
                                      </div>
                                      <div className="c_bodyfilter">
                                        <p className="hourse">{el?.inBoundObj?.JourneyTime}</p>
                                        <p className="rocket_img">
                                          <img src={rocket} alt="" className="img-fluid" />
                                        </p>
                                        <p className="non_stoop">{el?.inBoundObj?.Legs.length == 1 ? "non-stop" : `${el?.inBoundObj?.Legs.length - 1} stops`}</p>
                                        <p></p>
                                      </div>
                                      <div className="r_bodyfilter">
                                        <p className="time_filter">{el?.inBoundObj?.Legs[el?.inBoundObj?.Legs.length - 1]?.ArrivalTime}</p>
                                        <p className="docureiton_filter">{el?.inBoundObj?.Legs[el?.inBoundObj?.Legs.length - 1]?.Destination}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedFlight1 && selectedFlight1?.SearchId && selectedFlight2 && selectedFlight2?.SearchId && (
                    <div className=" flights-left">
                      <div className="flights-box">
                        <div className="box-head">
                          <p className="text-dark fw-semibold mb-0">
                            {selectedFlight1?.outBoundObj?.Legs[0]?.Origin} - {selectedFlight1?.outBoundObj?.Legs[0]?.Destination} - {selectedFlight1?.outBoundObj?.Legs[0]?.Origin}
                          </p>
                          <p className="fw-semibold">
                            {new Date(dataPrefill?.date[0]).getDate()} {moment(dataPrefill?.date[0]).format("MMMM").slice(0, 3)} - {new Date(dataPrefill?.date[1]).getDate()} {moment(dataPrefill?.date[1]).format("MMMM").slice(0, 3)}
                            {/* <span className="red ps-2 pointer">
                              <FiEdit />
                            </span> */}
                          </p>
                        </div>
                        <div className="box">
                          <h5 className="title text-center mb-4">Selected Flights</h5>
                          <p className="py-2 fw-semibold text-center selected-flight">
                            Departing Flight ({new Date(dataPrefill?.date[0]).getDate()} {moment(dataPrefill?.date[0]).format("MMMM").slice(0, 3)})
                          </p>
                          {selectedFlight1?.outBoundObj &&
                            selectedFlight1?.outBoundObj.Legs.map((el) => {
                              return (
                                <div className="selected-flight mb-3">
                                  <div className="d-flex gap-2">
                                    <div className="text-center">
                                      <div className="image">
                                        <img src={images.flight2} alt="" />
                                      </div>
                                      <h6 className="poppin">
                                        {el.FlightName} - {el?.FlightNumber}
                                      </h6>
                                    </div>
                                    <ul className="d-flex gap-2 flex-1">
                                      <li>
                                        <h6 className="mb-0">{el?.DepartureTime}</h6>
                                        <p className="mb-0"> {el?.Origin}</p>
                                      </li>
                                      <li>
                                        <h6 className="mb-0">{el?.Duration}</h6>
                                        <p className="mb-0">1 stop</p>
                                      </li>
                                      <li>
                                        <h6 className="mb-0">{el?.ArrivalTime}</h6>
                                        <p className="mb-0">{el?.Destination}</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              );
                            })}

                          <p className="py-2 fw-semibold text-center selected-flight">
                            Return Flight ({new Date(dataPrefill?.date[1]).getDate()} {moment(dataPrefill?.date[1]).format("MMMM").slice(0, 3)})
                          </p>
                          {selectedFlight2?.inBoundObj &&
                            selectedFlight2?.inBoundObj.Legs.map((el) => {
                              return (
                                <div className="selected-flight mb-3">
                                  <div className="d-flex gap-2">
                                    <div className="text-center">
                                      <div className="image">
                                        <img src={images.flight2} alt="" />
                                      </div>
                                      <h6 className="poppin">
                                        {el.FlightName} - {el?.FlightNumber}
                                      </h6>
                                    </div>
                                    <ul className="d-flex gap-2 flex-1">
                                      <li>
                                        <h6 className="mb-0">{el?.DepartureTime}</h6>
                                        <p className="mb-0"> {el?.Origin}</p>
                                      </li>
                                      <li>
                                        <h6 className="mb-0">{el?.Duration}</h6>
                                        <p className="mb-0">1 stops</p>
                                      </li>
                                      <li>
                                        <h6 className="mb-0">{el?.ArrivalTime}</h6>
                                        <p className="mb-0">{el?.Destination}</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              );
                            })}

                          <div className="trip-price-detail mb-3">
                            <ul>
                              <li>
                                <span>Trip Cost</span>₹{selectedFlight2?.Fare?.BasicFare + selectedFlight1?.Fare?.BasicFare}
                              </li>
                              <li>
                                <span>Total tax</span>₹{selectedFlight2?.Fare?.TotalTaxWithOutMarkUp + selectedFlight1?.Fare?.TotalTaxWithOutMarkUp}
                              </li>
                              <li>
                                <span>Payable Amount</span>
                                <span className="total text-success fw-bold">₹{selectedFlight2?.Fare?.TotalFareWithOutMarkUp + selectedFlight1?.Fare?.TotalFareWithOutMarkUp}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="text-center mt-4">
                            <Link
                              className="btn red-bg btn-custom"
                              to={`/Newcheckout?bookingFlight1=${encodeURIComponent(JSON.stringify(selectedFlight1))}&bookingFlight2=${encodeURIComponent(
                                JSON.stringify(selectedFlight2)
                              )}&search=${flightSearch}&dataPrefill=${encodeURIComponent(JSON.stringify(dataPrefill))}`}
                            >
                              Proceed To Book Online
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Flight;
