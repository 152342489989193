import React from "react";
import { FaCoins } from "react-icons/fa";
import { Link } from "react-router-dom";

function DeyorCoins() {
  return (
    <section className="pb-80 deyor-coins">
      <div className="container">
        <h2>Deyor Coins</h2>
        <p className="price d-flex align-items-baseline">
          <span className="text-dark fs-5 fw-500">Your Balance</span>
          <h3 className="text-warning mb-0">&nbsp;1,500&nbsp;<FaCoins /></h3>
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt,
          dignissimos similique autem est doloremque soluta, sit consequuntur
          quasi excepturi expedita laudantium sunt atque fuga totam quas ab.
          Totam, incidunt voluptas. Lorem ipsum dolor sit amet consectetur,
          adipisicing elit. Incidunt, dignissimos similique autem est doloremque
          soluta, sit consequuntur quasi excepturi expedita laudantium sunt
          atque fuga totam quas ab. Totam, incidunt voluptas.
        </p>
        <Link className="btn red-bg btn-custom" to='/'>Redeem Now</Link>
      </div>
    </section>
  );
}

export default DeyorCoins;
