import axios from "./axios.service";
import { url } from "./url";

const serverUrl = url + "/resorts";



export const getResort = (query) => {
    return axios.get(`${serverUrl}/?${query}`);
};

export const getResortRoomsForChange = (query) => {
    return axios.get(`${serverUrl}/getResortRoomsForChange?${query}`)
}