import { Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { getActivitiesForItinerary } from "../../services/Itinerary.service";
import { generateFilePath } from "../../services/url";
import { toastError } from "../../utils/toastUtils";
import { images } from "../Utility/Images";

function Activity({ itineraryObj }) {
  const [itineraryData, setItineraryData] = useState(null);
  const [activityArr, setActivityArr] = useState([]);

  const getActivities = async (id) => {
    try {
      const { data: res } = await getActivitiesForItinerary(id);
      if (res) {
        setActivityArr(res.data)
      }
    } catch (error) {
      toastError(error)
    }
  }


  useEffect(() => {
    if (itineraryObj) {
      setItineraryData(itineraryObj)
      getActivities(itineraryObj?._id)
    }
  }, [itineraryObj])
  return (
    <>
      <div className="trending-blog mb-80">
        <div className="container">
          <div className="title-section bg text-center w-100 mb-4">
            <h1 className="heading fw-semibold">Select Activities</h1>
          </div>
          <div
            className="row align-items-center g-0"
            style={{ backgroundColor: "#FAEDED" }}
          >
            <div className="col-12 col-md-6">
              <div className="content">
                <h4 className="mb-3">
                  Adaaran Select Hudhuran Fushi: Beyond your Expectations!
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                  aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                  nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                  aliquip ex ea commodo consequat.
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fw-semibold">13 Sept, 2021</p>
                  <p className="red fw-semibold mb-0">For Limited Period</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="image">
                <img src={images.dubai} alt="" className="h-100 w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="itinery-activity row gy-4">

        {activityArr && activityArr.length && activityArr.map(el => {
          return (
            <li className="col-12 col-md-4 col-lg-3">
              <div className="box">
                <div className="image">
                  <img src={generateFilePath(el.image)} alt="" />
                </div>
                <div className="content">
                  <h5>{el?.name}</h5>
                  {/* <p className="mb-0">{el.}.</p> */}
                  <Rating name="size-medium" defaultValue={2} readOnly />
                  <button className="red-bg border-0 bg-transparent">
                    <BsPlus />
                  </button>
                </div>
              </div>
            </li>

          )
        })}


      </ul>
    </>
  );
}

export default Activity;
