import { Rating } from "@mui/material";
import React from "react";
import { BsClockFill, BsFillStarFill, BsSearch } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { GoSettings } from "react-icons/go";
import { Link } from "react-router-dom";
import Filter from "./Filter";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import PageBanner from "./PageBanner";
import { images } from "./Utility/Images";
import { useState } from "react";
import { useEffect } from "react";
import { toastError } from "../utils/toastUtils";
import { getPackage } from "../services/Package.service";

function BikeTrips() {

  const [showFilter, setShowFilter] = useState(false);

  const [packagesArr, setPackagesArr] = useState([]);



  useEffect(() => {
    setShowFilter(showFilter)
  }, [showFilter])

  const HandleGet = async () => {
    try {
      let { data: res } = await getPackage("bikeTrip=true")
      if (res.data) {
        setPackagesArr(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  useEffect(() => {
    HandleGet()
  }, [])


  return (
    <>
      <Header />
      <main>
        <PageBanner
          banner1
          img1={images.bike_banner_bg}
          banner1className="img-position-top"
          className="page-banner-1"
        />
        <section className="mb-80 mt-5">
          <div className="container-fluid">
            <ul className="packages">
              <div className="packages-left" style={{ display: "unset" }}>
                <Filter sticky showFilter setShowFilter />
              </div>
              <div className="packages-right flex-1">
                <div className="packages-head packages-box mb-4">
                  <h4 className="heading mb-0">Bike Trips</h4>
                  <div>
                    <div className="form-control search-bar w-50">
                      <input
                        type="search"
                        className="border-0 bg-transparent w-100"
                        placeholder="Searh Here...."
                      />
                      <button className="border-0 bg-transparent py-0 icon green fs-5">
                        <BsSearch />
                      </button>
                    </div>
                    <button className="btn btn-custom red-bg" onClick={() => setShowFilter(true)}><GoSettings /></button>
                  </div>
                </div>
                {/* ============packages====================== */}
                <div className="row gy-4">
                  {
                    packagesArr && packagesArr.length > 0 && packagesArr.map((el, index) => {
                      return (
                        <div key={index} className="col-12 col-lg-6 col-xxl-12">
                          <div className="packages-box packages-inner row g-0">
                            <div className="packages-inner-left col-12 col-xxl-4">
                              <Link to="/TripDetail">
                                <img
                                  src={images.slide2}
                                  alt=""
                                  className="w-100 img-cover"
                                />
                              </Link>
                            </div>
                            <div className="col-12 col-xxl-8">
                              <div className="packages-inner-right">
                                <div className="d-flex heading gap-3">
                                  <div>
                                    <Link to="/TripDetail">
                                      <h4>{el.name}</h4>
                                    </Link>
                                    <ul className="offer">
                                      <li>
                                        <Link to="/TripDetail">
                                          <img src={images.secure} alt="" />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/TripDetail">
                                          <img src={images.guide} alt="" />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/TripDetail">
                                          <img src={images.dinner} alt="" />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/TripDetail">
                                          <img src={images.tent} alt="" />
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                  <div>
                                    <div className="d-flex align-items-start gap-3 total-review">
                                      <div className="text-end">
                                        <p
                                          className="mb-0 text-dark fw-semibold"
                                          style={{ lineHeight: "normal" }}
                                        >
                                          Superb
                                        </p>
                                        <span className="fs-15 fw-semibold">
                                          (153 Reviews)
                                        </span>
                                      </div>
                                      <button className="btn green-bg text-white with-icon">
                                        <div className="icon">
                                          <BsFillStarFill />
                                        </div>
                                        4.6
                                      </button>
                                    </div>
                                    <div className="hotel-rating text-end mt-1">
                                      <Rating
                                        name="read-only"
                                        // size="small"
                                        value={3}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="price">
                                  Starts from
                                  <h3 className="red mb-0">&nbsp;₹{el.datesArr[0].price}</h3>
                                  <span className="text-muted fs-15">/Person</span>
                                </p>
                                <ul className="detail">
                                  {/* <li style={{ color: "#70B6C1" }}>
                              <div className="icon">
                                <BsClockFill />
                              </div>
                              5D/3N
                            </li> */}
                                  <li>
                                    <div className="icon">
                                      <ImLocation />
                                    </div>
                                    Leh
                                  </li>
                                </ul>
                                <ul className="itinarary">
                                  <li>2D Leh</li>
                                  <li>1D Nubra Valley</li>
                                  <li>1D Pangong Tso</li>
                                  <li>1D Leh</li>
                                </ul>
                                <Link
                                  to="/TripDetail"
                                  className="btn btn-custom red-bg px-4"
                                >
                                  View Deal
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }

                </div>
                {/* ============packages====================== */}
              </div>
            </ul>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default BikeTrips;
