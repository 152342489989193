import React, { useEffect, useState } from "react";
import rocket from "../assets/images/rocket.png";
import redrocket from "../assets/images/red-rocket.png";
import { BsInfoCircle } from 'react-icons/bs';
import Select from 'react-select'
import icon1 from '../assets/images/icon01.png'
import icon2 from '../assets/images/icon02.png'
import icon3 from '../assets/images/icon03.png'
import icon4 from '../assets/images/icon04.png'
import icon5 from '../assets/images/icon05.png'
import pamenticon from '../assets/images/payment_img.png'
import { useSearchParams } from "react-router-dom";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { CreateBooking } from "../services/Flights";
import moment from "moment/moment";
export const Newcheckout = () => {
    const options = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'other' }
    ]

    const [searchParams, setSearchParams] = useSearchParams("");
    const [dataPrefill, setDataPrefill] = useState();
    const [flight1, setFlight1] = useState({});
    const [flight2, setFlight2] = useState({});
    const [search, setSearch] = useState({});
    useEffect(() => {
        setFlight1(JSON.parse(searchParams.get("bookingFlight1")))
        setFlight2(JSON.parse(searchParams.get("bookingFlight2")))
        setSearch(JSON.parse(searchParams.get("search")));
        console.log(searchParams.get("dataPrefill"));
    }, [])

    const handleCreateBooking = async () => {
        try {
            console.log(dataPrefill, "dataPrefill?.date")
            let obj = {
                BookSegment: [
                    flight1,

                ],
                PaymentDetails: {
                    BookingCurrencyCode: "INR",
                    BookingAmount: ((search?.Adults + search?.Childs) * ((flight2?.Fare?.TotalFareWithOutMarkUp ? flight2?.Fare?.TotalFareWithOutMarkUp : 0) + flight1?.Fare?.TotalFareWithOutMarkUp))
                },
                FlightSearchDetails: [{
                    BeginDate: moment(dataPrefill?.date[0]).format("yyyy-MM-DD"),
                    Origin: flight1?.outBoundObj.Legs[0]?.Origin,
                    Destination: flight1?.outBoundObj.Legs[0].Destination
                },

                ],
                Cabin: 0,
                TripType: flight2 && flight2?.inBoundObj ? 1 : 0,
                Traveller: {
                    AdultTraveller: [{
                        DOB: "1998-01-02",
                        LastName: "Ponnann",
                        ResidentCountry: "India",
                        Title: "Mr",
                        Nationality: "IN",
                        MiddleName: "",
                        Gender: 1,
                        EmailAddress: "aasd@aasd.com",
                        FrequentFlierNumber: "",
                        FirstName: "K",
                        CountryCode: "IN",
                        PassportExpiryDate: "2024-01-01",
                        PassportNo: "",
                        MobileNumber: "9910724205"
                    }
                    ],
                }
            }
            if (flight2 && flight2?.inBoundObj) {
                obj.FlightSearchDetails.push({
                    BeginDate: flight2?.inBoundObj?.Legs[0]?.DepartureDate,
                    Origin: flight2?.outBoundObj.Legs[0]?.Origin,
                    Destination: flight2?.outBoundObj.Legs[0].Destination
                })
                obj.BookSegment.push(flight2)
            }
            let { data: res } = await CreateBooking(obj);
            if (res.message) {
                toastSuccess(res.message);
            }
        }
        catch (err) {
            toastError(err);
        }
    }


    return (
        <>
            <div className="newceckout_top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="bg_trapsprent">
                                <h3>Flights</h3>
                                <p>you’re one step away form your dream destination</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flight_details">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 text-center">
                            <div className="details_ldt">
                                <h5>Flights Details</h5>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="card_fligjaplly">
                                {flight1?.outBoundObj &&
                                    flight1?.outBoundObj.Legs.map((el, i) => {
                                        return (
                                            <div key={i}>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="list_updonw">
                                                            <ul>
                                                                <li>{el?.Origin}</li>
                                                                <li>
                                                                    <img src={rocket} alt="" className="img-fluid" />
                                                                </li>
                                                                <li>{el?.Destination}</li>
                                                            </ul>
                                                            <div mt-2>
                                                                <ul className="lightfont">
                                                                    <li>{el.DepartureDate}</li>
                                                                    <li> {el.NumberOfStops == 0 ? "non stop" : `${el.NumberOfStops} stops`}. {el?.ArrivalTime}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6 text-end">
                                                    <div className="right_pp">
                                                        <p>Cancellation Fee Apply &nbsp; <BsInfoCircle /> </p>
                                                    </div>
                                                </div> */}
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="left_ari">
                                                            <h4><img src={redrocket} alt="" className="img-fluid" />  {el.FlightName} - {el?.FlightNumber}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 text-end">
                                                        <div className="right_sale">
                                                            <h3>{el.Cabin} {">"} <span> SALE <BsInfoCircle /> </span>  </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-5">
                                                    <div className="col-lg-12">
                                                        <div className="bgtable_chekcout">
                                                            <table className="table details_table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{el.DepartureTime}</th>
                                                                        <td><p><strong> {el?.Origin} </strong></p></td>
                                                                        <th><p>Cabin Baggage</p></th>
                                                                        <th><p>Luggage</p></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>{el.ArrivalTime}</th>
                                                                        <td><p><strong> {el?.Destination} </strong></p></td>
                                                                        <td>{el.CabinBagWT} {el.CabinBagUT}</td>
                                                                        <td>{el.BaggageWeight} {el.BaggageUnit}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {flight2?.inBoundObj &&
                                    flight2?.inBoundObj.Legs.map((el, i) => {
                                        return (
                                            <div key={i} className="mt-5">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="list_updonw">
                                                            <ul>
                                                                <li>{el?.Origin}</li>
                                                                <li>
                                                                    <img src={rocket} alt="" className="img-fluid" />
                                                                </li>
                                                                <li>{el?.Destination}</li>
                                                            </ul>
                                                            <div mt-2>
                                                                <ul className="lightfont">
                                                                    <li>{el.DepartureDate}</li>
                                                                    <li> {el.NumberOfStops == 0 ? "non stop" : `${el.NumberOfStops} stops`}. {el?.ArrivalTime}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6 text-end">
                                                        <div className="right_pp">
                                                            <p>Cancellation Fee Apply &nbsp; <BsInfoCircle /> </p>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="left_ari">
                                                            <h4><img src={redrocket} alt="" className="img-fluid" />  {el.FlightName} - {el?.FlightNumber}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 text-end">
                                                        <div className="right_sale">
                                                            <h3>{el.Cabin} {">"} <span> SALE <BsInfoCircle /> </span>  </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-5">
                                                    <div className="col-lg-12">
                                                        <div className="bgtable_chekcout">
                                                            <table className="table details_table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{el.DepartureTime}</th>
                                                                        <td><p><strong> {el?.Origin} </strong> </p></td>
                                                                        <th><p>Cabin Baggage</p></th>
                                                                        <th><p>Luggage</p></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>{el.ArrivalTime}</th>
                                                                        <td><p><strong> {el?.Destination} </strong> </p></td>
                                                                        <td>{el.CabinBagWT} {el.CabinBagUT}</td>
                                                                        <td>{el.BaggageWeight} {el.BaggageUnit}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className="row">
                                    <div className="col-lg-12 summery_scio py-4">
                                        <h4>Fare Summary</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="borderfare_summery">
                                            <h3>Base Fare</h3>
                                            <div className="brder_round">
                                                <div className="row mb-2">
                                                    <div className="col-lg-6">
                                                        <h5>Adult(s)</h5>
                                                    </div>
                                                    <div className="col-lg-6 text-end">
                                                        <h5>{(search?.Adults + search?.Childs)} X ₹{(flight2?.Fare?.BasicFare ? flight2?.Fare?.BasicFare : 0) + flight1?.Fare?.BasicFare}</h5>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h5>Total Fee & surcharges</h5>
                                                    </div>
                                                    <div className="col-lg-6 text-end">
                                                        <h5>{(search?.Adults + search?.Childs)} X ₹{(flight1?.Fare?.TotalTaxWithOutMarkUp + (flight2?.Fare?.TotalTaxWithOutMarkUp ? flight2?.Fare?.TotalTaxWithOutMarkUp : 0))}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4 taotal_amount">
                                                <div className="col-lg-6">
                                                    <h5>Total Amount</h5>
                                                </div>
                                                <div className="col-lg-6 text-end">
                                                    <h5>₹{((search?.Adults + search?.Childs) * ((flight2?.Fare?.TotalFareWithOutMarkUp ? flight2?.Fare?.TotalFareWithOutMarkUp : 0) + flight1?.Fare?.TotalFareWithOutMarkUp))}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 text-center">
                        <div className="details_ldt">
                            <h5>Important Information</h5>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="card_fligjaplly import_lnfo_list">
                            <h3>Check travel guidelines and baggage information below:</h3>
                            <ul>
                                <li>Wearing masks/face covers is no longer mandatory. However, all travellers are advised to wear them, in view of the threat posed by COVID-19.</li>
                                <li>For the complete list of travel guidelines issued by Indian States and UTs, click here</li>
                                <li> Carry no more than 1 check-in baggage and 1 hand baggage per passenger. If violated, airline may levy extra charges</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flight_details">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 text-center">
                            <div className="details_ldt">
                                <h5>Passenger Details</h5>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="card_fligjaplly passender_details">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h6>ADULT-12years+</h6>
                                    </div>
                                    <div className="col-lg-6 text-end">
                                        <h6>Passenger - {search.Adults + search.Childs + search.Infants}</h6>
                                    </div>
                                </div>
                                <div className="row bg_entername mt-4 mb-4">
                                    <div className="col-lg-12">
                                        <p>Enter name as mentioned on your passport or Government approved IDs.</p>
                                    </div>
                                </div>
                                <div className="row input_text_contgrol mt-4">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="from-group">
                                            <label>First Name</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-5">
                                        <div className="from-group">
                                            <label>Last Name</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="from-group">
                                            <label>Gender</label>
                                            <Select options={options} className="select_mk" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="flight_details">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 text-center">
                            <div className="details_ldt">
                                <h5>Fare Summary</h5>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="card_fligjaplly import_lnfo_list">
                                <h3>Check travel guidelines and baggage information below:</h3>
                                <ul>
                                    <li>Wearing masks/face covers is no longer mandatory. However, all travellers are advised to wear them, in view of the threat posed by COVID-19.</li>
                                    <li>For the complete list of travel guidelines issued by Indian States and UTs, click here</li>
                                    <li> Carry no more than 1 check-in baggage and 1 hand baggage per passenger. If violated, airline may levy extra charges</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flight_details">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 text-center">
                            <div className="details_ldt">
                                <h5>Booking</h5>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="card_fligjaplly payment_info">
                                <ul>
                                    <li>
                                        <div className="row justify-content-end">
                                            <a onClick={() => handleCreateBooking()} className="btn btn-paynew px-5">Book Now</a>
                                        </div>
                                    </li>
                                    {/* <li><img src={icon4} alt="" className="img-fluid" /> UPI</li>
                                    <li><img src={icon3} alt="" className="img-fluid" /> Wallet</li>
                                    <li><img src={icon2} alt="" className="img-fluid" /> Net Banking</li>
                                    <li><img src={icon1} alt="" className="img-fluid" /> Paylater</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="flight_details">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 text-center">
                            <div className="details_ldt">
                                <h5>Tickets Policies</h5>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="card_fligjaplly tickets_point">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3>Confirmation Policy</h3>
                                        <p>The customer receives a confirmation voucher via email within 2 business hours of successful booking.
                                            In case the preferred slots are unavailable, an alternate schedule of the customer’s preference will be arranged and a new confirmation voucher will be sent via email.
                                            Alternatively, the customer may choose to cancel their booking before confirmation and a full refund will be processed.</p>
                                    </div>
                                    <div className="col-lg-12">
                                        <h3>Refund Policy</h3>
                                        <p>Refunds in the DEYOR wallet will be transferred within 3 business days.
                                            Cash Refunds once initiated, usually take around 5-7 business days to get transferred to the same account through which the payment is made.</p>
                                    </div>
                                    <div className="col-lg-12">
                                        <h3>Booking Policy</h3>
                                        <p>For confirm booking100% payment at the time of booking.</p>
                                    </div>
                                    <div className="col-lg-12">
                                        <h3>Cancellation Policy</h3>
                                        <ul>
                                            <li>If cancellation are made 1 days before the date of travel then 100.0% of total tour cost will be charged as cancellation fees</li>
                                            <li> If cancellation are made 0 days to 1 days before the date of travel then 100.0% of total tour cost will be charged as cancellation fees</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-12">
                                        <h3>Payment Terms Policy</h3>
                                        <p>100.0% of total tour cost will have to be paid 0 days before the date of booking</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
