import React from "react";
import { Link } from "react-router-dom";
import { images } from "./Utility/Images";
import { BsClockFill } from "react-icons/bs";
import { GiTicket } from "react-icons/gi";

function Profile() {
  return (
    <>
      <section className="account-profile pb-80 px-4pc pt-5">
        <div className="container-fluid">
          <div className="row gy-5">
            <div className="col-12 col-xxl-3">
              <div className="trip-price-detail sticky-top">
                <div className="d-flex align-items-center gap-3 user-name">
                  <div className="image">
                    <img
                      src={images.testimonial1}
                      alt=""
                      className="h-100 w-100"
                    />
                  </div>
                  <div>
                    <h5 className="mb-0 fw-semibold">Profile Name</h5>
                    <p className="text-dark">ancd123@gmail.com</p>
                  </div>
                </div>
                <ul>
                  <li>
                    <span>Full Name</span>Rahul Kumar
                  </li>
                  <li>
                    <span>Email Address</span>abcd@gmail.com
                  </li>
                  <li>
                    <span>Phone</span>+91 8383-990-333
                  </li>
                  <li>
                    <span>Country</span>India
                  </li>
                  <li>
                    <span>State/Region</span>Delhi
                  </li>
                  <li>
                    <span>City</span>Delhi
                  </li>
                  <li>
                    <span>Address</span>A-100 New Delhi ,India
                  </li>
                  <li>
                    <span>Zip Code</span>110023
                  </li>
                </ul>
                <div className="text-center my-2">
                  <ul className="social-links justify-content-center">
                    <li className="border-0">
                      <Link to="">
                        <img src={images.fb} alt="" />
                      </Link>
                    </li>
                    <li className="border-0">
                      <Link to="">
                        <img src={images.insta} alt="" />
                      </Link>
                    </li>
                    <li className="border-0">
                      <Link to="">
                        <img src={images.youtube} alt="" />
                      </Link>
                    </li>
                    <li className="border-0">
                      <Link to="">
                        <img src={images.linkedin} alt="" />
                      </Link>
                    </li>
                    <li className="border-0">
                      <Link to="">
                        <img src={images.twitter} alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="text-center">
                  <a className="btn red-bg btn-custom w-100" href="#">
                    Edit
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-xxl-9">
              <div className="row gy-4">
                <div className="col-12">
                  <div className="iternary-main row align-items-center g-0">
                    <div className="col-12 col-md-4">
                      <div className="img">
                        <img
                          src={images.profile_itinery}
                          alt=""
                          className="h-100 w-100 img-cover"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-8">
                      <div className="content">
                        <h4>Rahul Kumar to Maldives 6 Nights </h4>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Consequuntur obcaecati natus omnis debitis
                          possimus ducimus aliquam voluptas!
                        </p>
                        <div className="price d-flex align-items-baseline">
                          <span className="text-dark fs-5 fw-500">
                            Total Price
                          </span>
                          <h3 className="red mb-0">&nbsp;₹1,500</h3>
                          <span className="text-muted fs-15">/Person</span>
                        </div>
                        <h6 className="poppin">5 Adults | Created 54 Secend</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="iternary-main small-box row g-0">
                    <div className="col-12 col-md-6">
                      <div className="img">
                        <img
                          src={images.profile_itinery}
                          alt=""
                          className="h-100 w-100 img-cover"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="content">
                        <h4>Rahul Kumar to Maldives 6 Nights </h4>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Consequuntur obcaecati natus omnis debitis
                          possimus ducimus aliquam voluptas!
                        </p>
                        <div className="price d-flex align-items-baseline">
                          <span className="text-dark fs-5 fw-500">
                            Total Price
                          </span>
                          <h3 className="red mb-0">&nbsp;₹1,500</h3>
                          <span className="text-muted fs-15">/Person</span>
                        </div>
                        <h6 className="poppin">5 Adults | Created 54 Secend</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="iternary-main small-box row g-0">
                    <div className="col-12 col-md-6">
                      <div className="img">
                        <img
                          src={images.profile_itinery}
                          alt=""
                          className="h-100 w-100 img-cover"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="content">
                        <h4>Rahul Kumar to Maldives 6 Nights </h4>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Consequuntur obcaecati natus omnis debitis
                          possimus ducimus aliquam voluptas!
                        </p>
                        <div className="price d-flex align-items-baseline">
                          <span className="text-dark fs-5 fw-500">
                            Total Price
                          </span>
                          <h3 className="red mb-0">&nbsp;₹1,500</h3>
                          <span className="text-muted fs-15">/Person</span>
                        </div>
                        <h6 className="poppin">5 Adults | Created 54 Secend</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="best-off mb-80">
        <div className="container">
          <div className="title-section mb-4">
            <h2 className="heading">Best Of Europe</h2>
          </div>
          <div className="row gy-4 gy-lg-0">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="best-off-box m-0">
                <Link to="/Bike-Trips">
                  <div className="image">
                    <img src={images.france} alt="" />
                  </div>
                  <div className="content">
                    <p>Create Memories in </p>
                    <h4>France</h4>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="best-off-box m-0">
                <Link to="/Bike-Trips">
                  <div className="image">
                    <img src={images.greece} alt="" />
                  </div>
                  <div className="content">
                    <p>Create Memories in </p>
                    <h4>Greece</h4>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="best-off-box m-0">
                <Link to="/Bike-Trips">
                  <div className="image">
                    <img src={images.italy} alt="" />
                  </div>
                  <div className="content">
                    <p>Create Memories in </p>
                    <h4>Italy</h4>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="best-off-box m-0">
                <Link to="/Bike-Trips">
                  <div className="image">
                    <img src={images.portugal} alt="" />
                  </div>
                  <div className="content">
                    <p>Create Memories in </p>
                    <h4>Portugal</h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;
