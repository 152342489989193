import React, { useEffect, useState, useContext } from "react";
import { images } from "../Utility/Images";
import { FaPhoneAlt } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { IoIosCall } from "react-icons/io";
import { GoChevronDown } from "react-icons/go";
import { BsFillEnvelopeFill } from "react-icons/bs";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../App";

function Header({ className }) {
  const [isAuthorized, setIsAuthorized] = useContext(AuthContext);

  const [getWidth, setGetWidth] = useState(window.innerWidth);
  const [honeyMoon, setHoneyMoon] = useState([
    {
      name: "Maldives",
      path: "/Bike-Trips",
    },
    {
      name: "Bali",
      path: "/Bike-Trips",
    },
    {
      name: "Thailand",
      path: "/Bike-Trips",
    },
    {
      name: "Mauritius",
      path: "/Bike-Trips",
    },
    {
      name: "Dubai",
      path: "/Bike-Trips",
    },
    {
      name: "Singapore",
      path: "/Bike-Trips",
    },
  ]);

  const [bikeTrip, setBikeTrip] = useState([
    {
      name: "Manali to Srinagar",
      path: "/Bike-Trips",
    },
    {
      name: "Srinagar to Manali",
      path: "/Bike-Trips",
    },
    {
      name: "Leh to Leh",
      path: "/Bike-Trips",
    },
    {
      name: "Spiti Valley",
      path: "/Bike-Trips",
    },
    {
      name: "Bhutan ",
      path: "/Bike-Trips",
    },
    {
      name: "Sikkim ",
      path: "/Bike-Trips",
    },
  ]);
  useEffect(() => {
    if (getWidth > 992) {
      var elems = document.querySelectorAll(".nav-link.dropdown-toggle");

      for (var i = 0; i < elems.length; i++) {
        elems[i].removeAttribute("data-bs-toggle");
      }
    }
  }, []);

  return (
    <>


      <header
        className={`${className ? className + " position-absolute" : " position-static"
          }`}
      >

        {/* <div className="topheader ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="listheader">
                <ul>
                  <li>
                    <a className="btn" href="tel:0000000000">
                      {" "}
                      <IoIosCall /> Call Us <GoChevronDown />{" "}
                    </a>
                  </li>
                  <li>
                    <a href="mailto:care@deyor.in" className="btn">
                      <BsFillEnvelopeFill /> care@deyor.in
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}




        {/* <div className="topbar">
          <div className="container-fluid">
            <ul className="topbar-inner">
              <li>
                <a href="#" className="btn red-bg with-icon btn-custom">
                  <div className="icon">
                    <FaPhoneAlt />
                  </div>
                  Call us
                </a>
              </li>
              <li>
                <a href="#" className="btn red-bg with-icon btn-custom">
                  <div className="icon">
                    <GrMail />
                  </div>
                  care@deyor.in
                </a>
              </li>
            </ul>
          </div>
        </div> */}
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand">
              <img
                src={className ? images.logo : images.logo_red}
                className="main-logo"
                alt=""
              />
            </Link>

            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header align-items-start">
                <Link to="/" className="navbar-brand">
                  <img src={images.logo_red} className="main-logo" alt="" />
                </Link>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body p-0">
                <ul className="navbar-nav flex-grow-1">
                  <li className="nav-item dropdown">
                    <NavLink
                      className="nav-link dropdown-toggle"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Top Honemoon Destinations
                    </NavLink>
                    <ul className="dropdown-menu">
                      {honeyMoon.map((item, i) => {
                        return (
                          <li key={i}>
                            <Link className="dropdown-item" to={item.path}>
                              {item.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink
                      className="nav-link dropdown-toggle"
                      to="/Bike-Trips"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Top Bike Trips
                    </NavLink>
                    <ul className="dropdown-menu">
                      {bikeTrip.map((item, i) => {
                        return (
                          <li key={i}>
                            <Link className="dropdown-item" to={item.path}>
                              {item.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <ul className="navbar-right">
              <li className="nav-item">
                {isAuthorized ? (
                  <Link className="btn red-bg btn-custom" to="/Account">
                    Account
                  </Link>
                ) : (
                  <Link className="btn red-bg btn-custom" to="/SignIn">
                    Log in
                  </Link>
                )}
                <button
                  className={`navbar-toggler ms-3 border-0 p-0 fs-3 ${className ? "text-white" : "text-dark"
                    }`}
                  style={{ transform: "scaleX(-1)" }}
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                  aria-controls="offcanvasNavbar"
                >
                  <span>
                    <RiBarChartHorizontalLine />
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
