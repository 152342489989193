import axios from "./axios.service";
import { url } from "./url";
import jwtDecode from "jwt-decode";
let serverlUrl = `${url}/users`;

export const registerNewUser = async (obj) => {
  return axios.post(`${serverlUrl}/register`, obj);
};

export const handleLogin = async (obj) => {
  return axios.post(`${serverlUrl}/login`, obj);
};

export const setJwt = async (token) => {
  await localStorage.setItem("Session", token);
  return true;
};

export const getJwt = async () => {
  let token = await localStorage.getItem("Session");
  return token;
};

export const decodeSessionJwt = async () => {
  let token = await localStorage.getItem("Session");
  if (token) {
    return await jwtDecode(token);
  } else {
    return null;
  }
};
