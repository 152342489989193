import React, { useLayoutEffect, useState } from "react";
import "swiper/swiper-bundle.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
import SliderTabs from "./SliderTabs";
import { images } from "./Utility/Images";
import { BsSearch } from "react-icons/bs";

function MainSlider() {
  const [getWindow, setGetWindow] = useState(window.innerWidth);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => setGetWindow(getWindow));
    setGetWindow(getWindow);
    return () =>
      window.addEventListener("resize", () => setGetWindow(getWindow));
  }, []);

  return (
    <section className="main-slider">
      {/* <Swiper
          modules={[Autoplay, EffectFade]}
          autoplay={{ disableOnInteraction: false }}
          loop
          speed={4000}
          effect="fade"
          slidesPerView={1}
          className='h-100'
        >
          <SwiperSlide>
            <div
              className="main-slider-box"
              style={{ backgroundImage: `url(${images.slide6})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="main-slider-box"
              style={{ backgroundImage: `url(${images.slide7})` }}
            ></div>
          </SwiperSlide>
        </Swiper> */}
      <div className="main-slider-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 col-xxl-10">
              <div className="main-slider-container">
                <div className="title-section">
                  <h1 className="heading">World's Best, Awaits You</h1>
                  <p>Discover amzing places at exclusive deals</p>
                  {getWindow <= 576 && (
                    <div className="mobile-search">
                    <div
                      className="form-control"
                    >
                      <input
                        type="search"
                        className="border-0 bg-transparent w-100 pe-3"
                        placeholder="Search Holidays"
                      />
                      <button className="border-0 p-0 bg-transparent">
                        <div className="icon">
                          <BsSearch />
                        </div>
                      </button>
                    </div>
                    </div>
                  )}
                </div>
                <SliderTabs />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSlider;
