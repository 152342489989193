import React, { useRef, useState } from "react";
import {
  BsCalendarWeek,
  BsClockFill,
  BsCloudDownloadFill,
  BsFillCalendarWeekFill,
  BsFillPatchCheckFill,
  BsPatchCheckFill,
  BsTagsFill,
} from "react-icons/bs";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import PageBanner from "./PageBanner";
import { images } from "./Utility/Images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { Link } from "react-router-dom";
import { ImLocation } from "react-icons/im";
import { AiFillCloseCircle } from "react-icons/ai";
import { SlMagicWand } from "react-icons/sl";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Modal from "react-modal";
import { BiChevronDown } from "react-icons/bi";

function TripDetail() {
  const ininerary = useRef(null);
  const package_option = useRef(null);
  const pickup_drop = useRef(null);
  const overview = useRef(null);
  const highlight = useRef(null);
  const inclusion_exclusion = useRef(null);
  const date_rate = {
    0: {
      slidesPerView: 1,
    },
    1000: {
      slidesPerView: 3,
    },
  };

  const [filterTabs, setFilterTabs] = useState([
    {
      name: "Itinerary",
      active: true,
      tab: "1",
      refTo: ininerary,
    },
    {
      name: "Package Options",
      active: false,
      tab: "2",
      refTo: package_option,
    },
    {
      name: "Pickup & Drop",
      active: false,
      tab: "3",
      refTo: pickup_drop,
    },
    {
      name: "Overview",
      active: false,
      tab: "4",
      refTo: overview,
    },
    {
      name: "Highlights",
      active: false,
      tab: "5",
      refTo: highlight,
    },
    {
      name: "Inclusions/Exclusions",
      active: false,
      tab: "6",
      refTo: inclusion_exclusion,
    },
  ]);

  const activeTab = (i, refTo) => {
    var height = document.querySelector("ul.filter-tabs").offsetHeight + 30;
    window.scrollTo(0, refTo.current.offsetTop - height);
    const temp = filterTabs.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setFilterTabs([...temp]);
  };
  const [monthValue, setMonthValue] = useState([new Date(), new Date()]);
  const [showCalender, setShowCalender] = useState(false);
  const [packageModal, setPackageModal] = useState(false);
  const [itineraryList, setItineraryList] = useState([
    {
      title: "Arrival in Jaipur | Welcome to the 'Pink City of India'",
      desp: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, saepe facilis. Sit blanditiis ipsum deleniti veniam iste quibusdam delectus laboriosam expedita quisquam illo, nostrum consectetur officia amet magni labore nobis.",
      active: false,
    },
    {
      title:
        "Jaipur - Jodhpur | Ride to the shades of Blue | Home to the Impregnable Mehrangarh Fort",
      desp: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, saepe facilis. Sit blanditiis ipsum deleniti veniam iste quibusdam delectus laboriosam expedita quisquam illo, nostrum consectetur officia amet magni labore nobis.",
      active: false,
    },
    {
      title:
        "Jodhpur - Jaisalmer | Ride to the Shimmering 'Golden City' of Rajasthan",
      desp: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, saepe facilis. Sit blanditiis ipsum deleniti veniam iste quibusdam delectus laboriosam expedita quisquam illo, nostrum consectetur officia amet magni labore nobis.",
      active: false,
    },
    {
      title:
        "Jaisalmer - Khimsar | Watch a beautiful sunset over the golden Thar desert",
      desp: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, saepe facilis. Sit blanditiis ipsum deleniti veniam iste quibusdam delectus laboriosam expedita quisquam illo, nostrum consectetur officia amet magni labore nobis.",
      active: false,
    },
    {
      title:
        "Khimsar to Jaipur | Marvel at the beautiful wall of the Hawa Mahal",
      desp: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, saepe facilis. Sit blanditiis ipsum deleniti veniam iste quibusdam delectus laboriosam expedita quisquam illo, nostrum consectetur officia amet magni labore nobis.",
      active: false,
    },
    {
      title: "Departure from Jaipur | Head back with happy memories",
      desp: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est, saepe facilis. Sit blanditiis ipsum deleniti veniam iste quibusdam delectus laboriosam expedita quisquam illo, nostrum consectetur officia amet magni labore nobis.",
      active: false,
    },
  ]);

  const activeItinerary = (i) => {
    const temp = itineraryList.map((item, index) => {
      i === index ? (item.active = !item.active) : (item.active = false);
      return item;
    });
    setItineraryList([...temp]);
  };

  const showAllItinerary = () => {
    setExpand(!expand);
    const temp = itineraryList.map((item) => {
      item.active = !item.active;
      return item;
    });
    setItineraryList([...temp]);
  };

  const [expand, setExpand] = useState(false);

  return (
    <>
      <Header />
      <main>
        <PageBanner
          banner2
          img1={images.slide1}
          img2={images.slide2}
          img3={images.slide3}
          img4={images.slide4}
          img5={images.maldive}
          className="page-banner-2"
        />

        <section className="trip-info mt-1 py-4">
          <div className="container">
            <ul>
              <li>
                <h5>
                  <div className="icon">
                    <BsClockFill />
                  </div>
                  Ideal Duration
                </h5>
                <p>3 - 4 Nights</p>
              </li>
              <li>
                <h5>
                  <div className="icon">
                    <BsFillCalendarWeekFill />
                  </div>
                  Best Time
                </h5>
                <p>jul - Aug</p>
              </li>
              <li>
                <h5>
                  <div className="icon">
                    <BsTagsFill />
                  </div>
                  Starting Price at
                </h5>
                <p>₹50,000/person</p>
              </li>
              <li>
                <h5>
                  <div className="icon">
                    <BsPatchCheckFill />
                  </div>
                  Visa Processing
                </h5>
                <p>Visa on Arrival </p>
              </li>
            </ul>
          </div>
        </section>

        <ul className="filter-tabs mb-5 sticky-top">
          {filterTabs.map((item, i) => {
            return (
              <li key={i}>
                <button
                  className={`btn ${item.active ? "active" : ""}`}
                  onClick={() => activeTab(i, item.refTo)}
                >
                  {item.name}
                </button>
              </li>
            );
          })}
          <li>
            <a className="btn red-bg px-4" download>
              PDF <BsCloudDownloadFill />
            </a>
          </li>
        </ul>

        <section className="trip-desp mb-80">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="title-section bg mb-4">
                  <h3 className="heading">
                    Ladakh Bike Tour With KTM 390 Adventure
                  </h3>
                </div>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est
                  at voluptatum vero expedita velit! Hic illo dolore adipisci
                  sequi, exercitationem suscipit sed, asperiores deserunt iste
                  possimus voluptates, vitae itaque delectus? Lorem ipsum dolor,
                  sit amet consectetur adipisicing elit. Est at voluptatum vero
                  expedita velit! Hic illo dolore adipisci sequi, exercitationem
                  suscipit sed, asperiores deserunt iste possimus voluptates,
                  vitae itaque delectus?
                </p>
                <ul className="trip-includes mb-5">
                  <li>
                    <div className="icon">
                      <img src={images.dinner} alt="" />
                    </div>
                    <h5>Meals Included</h5>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={images.secure} alt="" />
                    </div>
                    <h5>DEYOR Safe</h5>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={images.tent} alt="" />
                    </div>
                    <h5>Camping</h5>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={images.guide} alt="" />
                    </div>
                    <h5>Guide</h5>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-4">
                <div className="trip-price-detail rounded-3 mb-4">
                  <ul>
                    <li>
                      <span>Price for 2 persons</span>₹20,860
                    </li>
                    <li>
                      <span>Trip Cost</span>₹19,101
                    </li>
                    <li>
                      <span>GST</span>₹461
                    </li>
                    <li>
                      <span>TCS</span>₹934
                    </li>
                    <li>
                      <span>Payable Amount</span>
                      <span className="total text-success fw-bold">
                        ₹20,860
                      </span>
                    </li>
                  </ul>
                  <div className="text-center mt-4">
                    <Link to="/Checkout" className="btn red-bg btn-custom">
                      Proceed To Book Online
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-5 trip-desp">
          <div className="container">
            <div className="title-section bg w-100">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="heading">Dates & Rate</h3>
                <h3
                  onClick={() => setShowCalender(!showCalender)}
                  className="red pointer"
                >
                  <BsCalendarWeek />
                </h3>
              </div>
              {showCalender && (
                <div className="date-picker-module">
                  <DateRangePicker
                    onChange={setMonthValue}
                    value={monthValue}
                    isOpen={showCalender}
                    onCalendarClose={() => setShowCalender(!showCalender)}
                  />
                </div>
              )}
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={3}
              loop
              speed={2000}
              modules={[Pagination]}
              pagination={{ clickable: true }}
              className="pb-5 pt-3 px-3 trip-dates"
              breakpoints={date_rate}
            >
              <SwiperSlide>
                <div className="trip-dates-box">
                  <div className="heading">
                    <h5>Date & Durations</h5>
                    <h5>Price</h5>
                  </div>

                  <div className="flex-timeline">
                    <ul className="trip-timeline">
                      <li>
                        <div className="icon"></div>
                        <div>
                          <h6>02 Jun, 2023</h6>
                          <p>10 Nights 11 Days</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon"></div>
                        <div>
                          <h6>02 Jun, 2023</h6>
                          <p>10 Nights 11 Days</p>
                        </div>
                      </li>
                    </ul>
                    <h3 className="price red">₹29,999</h3>
                  </div>
                  <div className="text-center">
                    <Link to="/" className="btn btn-custom red-bg px-4">
                      Book Now
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="trip-dates-box">
                  <div className="heading">
                    <h5>Date & Durations</h5>
                    <h5>Price</h5>
                  </div>

                  <div className="flex-timeline">
                    <ul className="trip-timeline">
                      <li>
                        <div className="icon"></div>
                        <div>
                          <h6>02 Jun, 2023</h6>
                          <p>10 Nights 11 Days</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon"></div>
                        <div>
                          <h6>02 Jun, 2023</h6>
                          <p>10 Nights 11 Days</p>
                        </div>
                      </li>
                    </ul>
                    <h3 className="price red">₹29,999</h3>
                  </div>
                  <div className="text-center">
                    <Link to="/" className="btn btn-custom red-bg px-4">
                      Book Now
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="trip-dates-box">
                  <div className="heading">
                    <h5>Date & Durations</h5>
                    <h5>Price</h5>
                  </div>

                  <div className="flex-timeline">
                    <ul className="trip-timeline">
                      <li>
                        <div className="icon"></div>
                        <div>
                          <h6>02 Jun, 2023</h6>
                          <p>10 Nights 11 Days</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon"></div>
                        <div>
                          <h6>02 Jun, 2023</h6>
                          <p>10 Nights 11 Days</p>
                        </div>
                      </li>
                    </ul>
                    <h3 className="price red">₹29,999</h3>
                  </div>
                  <div className="text-center">
                    <Link to="/" className="btn btn-custom red-bg px-4">
                      Book Now
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>

        <section className="trip-all-detail mb-80">
          <div className="container">
            <div className="row gy-5">
              <div className="col-12 m-0" id="tab1" ref={ininerary}>
                <div className="itinerary-head mb-4">
                  <h2 className="mb-0">Itinerary</h2>
                  <p
                    className="mb-0 red fw-semibold pointer"
                    onClick={() => showAllItinerary()}
                  >
                    {expand ? "Shrink all" : "Expand all"}
                  </p>
                </div>
                <ul className="trip-itinerary">
                  {itineraryList.map((item, i) => {
                    return (
                      <li key={i}>
                        <div
                          className={`title mb-2 ${item.active ? "active" : ""
                            }`}
                          onClick={() => activeItinerary(i)}
                        >
                          <div className="d-flex align-items-center gap-3">
                            <span>Day {i + 1}</span>
                            <h6 className="flex-1">{item.title}</h6>
                          </div>
                          <div className="icon">
                            <BiChevronDown />
                          </div>
                        </div>
                        {item.active && <p>{item.desp}</p>}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-12" id="tab2" ref={package_option}>
                <h2 className="mb-4">Package Options</h2>
                <Swiper
                  spaceBetween={20}
                  slidesPerView={3}
                  loop
                  speed={2000}
                  modules={[Pagination]}
                  pagination={{ clickable: true }}
                  className="trip-package pb-5 pt-3 px-3"
                  breakpoints={date_rate}
                >
                  <SwiperSlide>
                    <div className="trip-package-box">
                      <h2>Own Vehicle</h2>
                      <h6 className="red">6 Days, 5 Nights</h6>
                      <h4 className="price">
                        <span>₹50,000</span>
                      </h4>
                      <h2 className="price">₹30,000</h2>
                      <p
                        className="text-end red fw-semibold mb-0 small pointer"
                        onClick={() => setPackageModal(true)}
                      >
                        Show Details
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="trip-package-box">
                      <h2>Dual Rider</h2>
                      <h6 className="red">6 Days, 5 Nights</h6>
                      <h4 className="price">
                        <span>₹60,000</span>
                      </h4>
                      <h2 className="price">₹40,000</h2>
                      <p
                        className="text-end red fw-semibold mb-0 small pointer"
                        onClick={() => setPackageModal(true)}
                      >
                        Show Details
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="trip-package-box">
                      <h2>Solo Rider</h2>
                      <h6 className="red">8 Days, 7 Nights</h6>
                      <h4 className="price">
                        <span>₹80,000</span>
                      </h4>
                      <h2 className="price">₹60,000</h2>
                      <p
                        className="text-end red fw-semibold mb-0 small pointer"
                        onClick={() => setPackageModal(true)}
                      >
                        Show Details
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="col-12" id="tab3" ref={pickup_drop}>
                <h2 className="mb-4">Pickup & Drop</h2>
                <div className="trip-pickup-drop">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d3658626.272183182!2d78.25959955575345!3d26.427438527404473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x39ecde9099f277ef%3A0x83615f8404907d32!2sDelho%2C%20Bihar%20845436!3m2!1d26.4208657!2d85.2191905!4m5!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sjaipur!3m2!1d26.9124336!2d75.7872709!5e0!3m2!1sen!2sin!4v1668497120009!5m2!1sen!2sin"
                    width="100%"
                    height="350"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <ul>
                    <li className="text-end">
                      <h5>Delhi</h5>
                      <p>Delhi, Delhi, India</p>
                    </li>
                    <li>
                      <div className="d-flex align-items-center">
                        <div className="line"></div>
                        <div className="icon red">
                          <ImLocation />
                        </div>
                        <div className="line"></div>
                      </div>
                    </li>
                    <li>
                      <h5>Jaipur</h5>
                      <p>Jaipur, Rajasthan, India</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12" id="tab4" ref={overview}>
                <h2 className="mb-4">Overview</h2>
                <div className="trip-overview">
                  <p>
                    Dive into the stories of glory, valour, and grandeur of
                    Rajasthan, from princely and colourful cities to remote
                    deserts and holy lakes, Rajasthan will give you an
                    experience worth cherishing. This tour is sure to satisfy
                    your restless spirit and give you an experience out of the
                    ordinary while elevating the true essence of Rajasthan as it
                    takes you through the massive golden forts that appear to
                    rise from the golden sands.
                  </p>
                  <ul className="quick-info">
                    <li>
                      <span>Route:</span>Jaipur - Jodhpur - Jaisalmer - Khimsar
                      - Jaipur
                    </li>
                    <li>
                      <span>Duration:</span> 6 Days / 5 Nights
                    </li>
                    <li>
                      <span>Start Point:</span> Jaipur
                    </li>
                    <li>
                      <span>End Point: </span>Jaipur
                    </li>
                  </ul>
                  <h5>How to reach?</h5>
                  <p>
                    One can easily travel to Jaipur by road, railways, or
                    airways. One can get a direct flight to Jaipur from all the
                    major cities in India. The railway junction at Jaipur
                    connects it with various cities as well. Jaipur has a great
                    network of roads connecting it with major Indian cities. NH
                    8, NH 11, and NH 12 are the main national highways
                    connecting the city of Jaipur with other cities.
                  </p>
                  <h5>Things not to miss:</h5>
                  <ul className="not-to-miss">
                    <li>
                      <div className="icon">
                        <BsFillPatchCheckFill />
                      </div>
                      Take a tour of Amer fort in Maharaja Style by riding on an
                      elephant’s back
                    </li>
                    <li>
                      <div className="icon">
                        <BsFillPatchCheckFill />
                      </div>
                      Learn about the unique culture and language of the
                      indigenous tribes by taking a Bishnoi Village Safari in
                      Jodhpur.
                    </li>
                    <li>
                      <div className="icon">
                        <BsFillPatchCheckFill />
                      </div>
                      Pay homage to the 350cc Royal Enfield Bullet bike and
                      learn about its mysterious appearance at Om Banna Temple
                      in Jodhpur.
                    </li>
                    <li>
                      <div className="icon">
                        <BsFillPatchCheckFill />
                      </div>
                      Visit Longewala War Memorial to see the weapons and
                      tankers used during the Indo-Pak war in Jaisalmer.
                    </li>
                    <li>
                      <div className="icon">
                        <BsFillPatchCheckFill />
                      </div>
                      Glide over the desert and feel the sand hitting your face
                      as you go dune bashing in an SUV at Sam Sand Dunes.
                    </li>
                  </ul>
                  <h5>Available Group Departures (Every Saturday):</h5>
                  <ul className="quick-info">
                    <li>
                      <span> December:</span>25
                    </li>
                    <li>
                      <span> January:</span>1,8,15,22,29
                    </li>
                    <li>
                      <span>February:</span>5,12,19,26
                    </li>
                  </ul>
                  <h5>
                    NOTE<span className="red">*</span> :
                  </h5>
                  <p>Your Pickup and Drop is from your hotel in Jaipur only.</p>
                  <p>
                    Every day, your ride will start between 8 am to 9 am sharp.
                    All the participants are requested to follow the timings for
                    the smooth functioning of the tour.
                  </p>
                </div>
              </div>
              <div className="col-12" id="tab5" ref={highlight}>
                <h2 className="mb-4">Highlights</h2>
                <div className="inclusion-exclusion">
                  <ul>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Backpack (Min 60 Ltrs) with Rain Cover
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Gum boots
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      4 Pairs of Full sleeve shirts/T-Shirt and Track Pants (1
                      should be waterproof/windproof)
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      4 Pair of cotton socks. 3 pairs of wollen socks.
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      2 Full Sleeve Sweater
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      One Heavy Down Jacket
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Thermal Inners -2 Pair
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      2 pair water proof hand gloves
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      2 Pair woollen Gloves
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Sun Cap
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      One Scarf
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Light towel
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Lip Balm
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Cold Cream & Sun Screen (SPF 40+)
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Water Bottle 1 Lt
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Headlamp or torch with fresh pair of batteries
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Personal Toilet Kit and toilet paper
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Personal Medicine Kit
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Dark Sunglasses (U/V protected)
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      Walking Stick (At least one)
                    </li>
                    <li>
                      <div className="icon red">
                        <SlMagicWand />
                      </div>
                      People who were spectacles- Should avoid contact lenses
                      and use use photo chromatic glasses instead.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12" id="tab6" ref={inclusion_exclusion}>
                <h2 className="mb-4">Inclusions/Exclusions</h2>
                <div className="inclusion-exclusion row gy-4">
                  <div className="col-12 col-lg-6">
                    <h5>Inclusions</h5>
                    <ul className="inclusion">
                      <li>
                        <div className="icon text-success">
                          <BsFillPatchCheckFill />
                        </div>
                        Meals on MAP Plan(Breakfast and Dinner) starting with
                        Dinner on Day 1.
                      </li>
                      <li>
                        <div className="icon text-success">
                          <BsFillPatchCheckFill />
                        </div>
                        Accomodation on double (couples(without additional
                        charges) and special requests(on additional charges))
                        and triple sharing(general) basis in hygienic budget
                        hotels/camps.
                      </li>
                      <li>
                        <div className="icon text-success">
                          <BsFillPatchCheckFill />
                        </div>
                        Royal Enfield Himalayan Bike
                      </li>
                      <li>
                        <div className="icon text-success">
                          <BsFillPatchCheckFill />
                        </div>
                        Helmets(Suggest you to bring your own)
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-lg-6">
                    <h5>Exclusions</h5>
                    <ul className="exclusion">
                      <li>
                        <div className="icon text-danger">
                          <AiFillCloseCircle />
                        </div>
                        Any meals/services not mentioned in the inclusions
                      </li>
                      <li>
                        <div className="icon text-danger">
                          <AiFillCloseCircle />
                        </div>
                        Travel Expenses
                      </li>
                      <li>
                        <div className="icon text-danger">
                          <AiFillCloseCircle />
                        </div>
                        Security Deposit for Bike - INR 5000/bike.
                      </li>
                      <li>
                        <div className="icon text-danger">
                          <AiFillCloseCircle />
                        </div>
                        Biking Gears( Jackets, Knee and Elbow Guard)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <Modal
        isOpen={packageModal}
        onRequestClose={() => setPackageModal(false)}
        className="custom-modal"
        bodyOpenClassName="custom-modal-body"
        overlayClassName="custom-modal-overlay"
        htmlOpenClassName="custom-modal-html"
        portalClassName="custom-modal-parent"
        contentLabel="Example Modal"
      >
        <div className="talk-to-expert">
          <div className="title-section mb-3">
            <h4 className="heading mb-1">Own Vehicle</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint quis
              veniam vitae itaque enim culpa, ducimus corrupti eum qui rerum
              temporibus, officia amet. Maxime, vero unde. Dignissimos, odit
              ullam. Quidem?
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default TripDetail;
