import React from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import PageBanner from "./PageBanner";
import { images } from "./Utility/Images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, EffectFade } from "swiper";
import { Link } from "react-router-dom";
import { FaQuoteLeft } from "react-icons/fa";

function Career() {
  const clients = {
    0: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 4,
    },
    900: {
      slidesPerView: 5,
    },
    1000: {
      slidesPerView: 6,
    },
    1400: {
      slidesPerView: 7,
    },
    1600: {
      slidesPerView: 8,
    },
  };
  return (
    <>
      <Header />
      <main>
        <PageBanner
          banner3
          img1={images.slide4}
          className="page-banner-3"
          title="Careers"
          desp="Join us on our travel journey"
        />

        <section className="mb-80">
          <div className="container">
            <div className="title-section bg text-center w-100 mb-4">
              <h1 className="heading fw-semibold">Why work with us?</h1>
              <p className="text-dark fs-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <ul className="row">
              <li className="col-12 col-md-4">
                <div className="why-us-box">
                  <div className="icon">
                    <img src={images.destination} alt="" />
                  </div>
                  <h5>Created Meals</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </div>
              </li>
              <li className="col-12 col-md-4">
                <div className="why-us-box">
                  <div className="icon">
                    <img src={images.tour_bus} alt="" />
                  </div>
                  <h5>Health Care</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </div>
              </li>
              <li className="col-12 col-md-4">
                <div className="why-us-box">
                  <div className="icon">
                    <img src={images.hotl_star} alt="" />
                  </div>
                  <h5>Flexible Enviroment</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section className="testimonials-style-2 mb-80 px-4pc">
          <div className="container-fluid">
            <div className="title-section bg text-center w-100 mb-4">
              <h1 className="heading fw-semibold">
                What Our Jobholders Saying
              </h1>
              <p className="text-dark fs-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <div className="row g-5">
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial1}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Rahul Kumar</h5>
                        <p>
                          <i>HR</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial2}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Rahul Kumar</h5>
                        <p>
                          <i>HR</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial3}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Rahul Kumar</h5>
                        <p>
                          <i>HR</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial1}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Rahul Kumar</h5>
                        <p>
                          <i>HR</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial2}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Rahul Kumar</h5>
                        <p>
                          <i>HR</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial3}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Rahul Kumar</h5>
                        <p>
                          <i>HR</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="career-apply mb-80">
            <ul className="filter-tabs sticky-top mb-5 py-1">
              <li>
                <Link className="btn" to="/">
                  Customer Experiences
                </Link>
              </li>
              <li>
                <Link className="btn" to="/">
                  Design
                </Link>
              </li>
              <li>
                <Link className="btn" to="/">
                  Engineering
                </Link>
              </li>
              <li>
                <Link className="btn" to="/">
                  Founder’s Finance
                </Link>
              </li>
              <li>
                <Link className="btn" to="/">
                  Marketing
                </Link>
              </li>
              <li>
                <Link className="btn" to="/">
                  Product Development
                </Link>
              </li>
              <li>
                <Link className="btn" to="/">
                  Sales
                </Link>
              </li>
              <li>
                <Link className="btn" to="/">
                  Supply & Category Management
                </Link>
              </li>
            </ul>
          <div className="px-4pc">
            <div className="title-section bg text-center w-100 mb-4">
              <h1 className="heading fw-semibold">Current Oenings</h1>
              <p className="text-dark fs-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row gy-4">
              <div className="col-12">
                <div className="career-apply-box">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                      <div className="left">
                        <h4>Design</h4>
                        <h6>
                          <i className="red">1 - 3 Years Experience</i>
                        </h6>
                        <p className="fw-light text-dark">
                          You’ll be a part of our in house design team which
                          will constantly challenge the status quo to define
                          exceptional user experience journeys and product
                          interactions. You will feel right at home here if you
                          love rapid prototyping, sketching user stories,
                          detailing uber cool icons, animating awesome
                          interactions or coming up with creative messaging.
                        </p>
                        <Link to="/CareerForm" className="btn red-bg btn-custom">
                          Apply
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="right row">
                        <div className="col-12 col-md-6">
                          <div className="image">
                            <img
                              src={images.europe}
                              alt=""
                              className="h-100 w-100"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="image mt-5">
                            <img
                              src={images.egypt}
                              alt=""
                              className="h-100 w-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="career-apply-box">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                      <div className="left">
                        <h4>Design</h4>
                        <h6>
                          <i className="red">1 - 3 Years Experience</i>
                        </h6>
                        <p className="fw-light text-dark">
                          You’ll be a part of our in house design team which
                          will constantly challenge the status quo to define
                          exceptional user experience journeys and product
                          interactions. You will feel right at home here if you
                          love rapid prototyping, sketching user stories,
                          detailing uber cool icons, animating awesome
                          interactions or coming up with creative messaging.
                        </p>
                        <Link to="/CareerForm" className="btn red-bg btn-custom">
                          Apply
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="right row">
                        <div className="col-12 col-md-6">
                          <div className="image">
                            <img
                              src={images.europe}
                              alt=""
                              className="h-100 w-100"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="image mt-5">
                            <img
                              src={images.egypt}
                              alt=""
                              className="h-100 w-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="career-apply-box">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                      <div className="left">
                        <h4>Design</h4>
                        <h6>
                          <i className="red">1 - 3 Years Experience</i>
                        </h6>
                        <p className="fw-light text-dark">
                          You’ll be a part of our in house design team which
                          will constantly challenge the status quo to define
                          exceptional user experience journeys and product
                          interactions. You will feel right at home here if you
                          love rapid prototyping, sketching user stories,
                          detailing uber cool icons, animating awesome
                          interactions or coming up with creative messaging.
                        </p>
                        <Link to="/CareerForm" className="btn red-bg btn-custom">
                          Apply
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="right row">
                        <div className="col-12 col-md-6">
                          <div className="image">
                            <img
                              src={images.europe}
                              alt=""
                              className="h-100 w-100"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="image mt-5">
                            <img
                              src={images.egypt}
                              alt=""
                              className="h-100 w-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="brand my-5 px-4pc">
          <div className="container-fluid">
            <div className="title-section bg">
              <h1>Trusted by Agency and Companies</h1>
            </div>
            <Swiper
              slidesPerView={8}
              spaceBetween={20}
              autoplay={{ disableOnInteraction: false }}
              loop
              speed={2000}
              modules={[Autoplay]}
              breakpoints={clients}
            >
              <SwiperSlide>
                <div className="image">
                  <img src={images.brand1} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image">
                  <img src={images.brand2} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image">
                  <img src={images.brand3} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image">
                  <img src={images.brand4} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image">
                  <img src={images.brand5} alt="" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Career;
