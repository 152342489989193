import React from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import PageBanner from "./PageBanner";
import { images } from "./Utility/Images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, EffectFade } from "swiper";
import { Link } from "react-router-dom";
import { FaQuoteLeft } from "react-icons/fa";
import NewsLetter from "./NewsLetter";

function Testimonials() {
  return (
    <>
      <Header />
      <main>
        <PageBanner
          banner3
          img1={images.holiday2}
          className="page-banner-3"
          title="Testimonials"
          desp="Know what people say about us!!!"
        />

        <ul className="filter-tabs mt-5">
          <li>
            <a className="btn" href="#go-to-bike">
              Bike Trips
            </a>
          </li>
          <li>
            <a className="btn" href="#go-to-holiday">
              Holidays Tips
            </a>
          </li>
        </ul>

        <section className="mt-5 mb-80 px-4pc" id="go-to-bike">
          <div className="container-fluid">
            <div className="title-section bg mb-5 w-100">
              <h1>Bike Trips</h1>
            </div>
            <div className="row gy-4">
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial1}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial2}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial3}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial1}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial2}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial3}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5 mb-80 px-4pc" id="go-to-holiday">
          <div className="container-fluid">
            <div className="title-section bg mb-5 w-100">
              <h1>Holidays Tips</h1>
            </div>
            <div className="row gy-4">
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial1}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial2}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial3}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial1}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial2}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img
                          src={images.testimonial3}
                          alt=""
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="name">
                        <h5 className="mb-0">Anjali Nayak</h5>
                        <p>
                          <i>Maldives Honeymoon</i>
                        </p>
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <NewsLetter className="mb-80 ptb-80 mx-4pc" />
      </main>
      <Footer />
    </>
  );
}

export default Testimonials;
