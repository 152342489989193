import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import Lottie from 'react-lottie';
import { Link, useNavigate } from "react-router-dom";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import * as animationData from '../../assets/37179-travel-tickets.json';
import { getArrivalCityForItinerary, getDepartureCityForItinerary } from "../../services/City.service";
import { generateItinerary, generateItineraryForHotelApiDisabled } from "../../services/Itinerary.service";
import { getTravelPurposes } from "../../services/TravelPurpose";
import { generateFilePath } from "../../services/url";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { images } from "../Utility/Images";
import { getResort } from "../../services/resort.service";
import { decodeSessionJwt } from "../../services/users.service";
function Questions() {
  const navigate = useNavigate()
  const [displayLoader, setDisplayLoader] = useState(false);
  const [totalQues, setTotalQues] = useState(6);
  const [currentQues, setCurrentQues] = useState(0);
  const [tempMonthSelectIndex, setTempMonthSelectIndex] = useState(0);
  const [monthValue, setMonthValue] = useState([new Date(), new Date()]);
  const [showCalender, setShowCalender] = useState(false);
  const [people, setPeople] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState(1);
  const [selectedAdult, setSelectedAdult] = useState(1);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(1);

  const [selectedChild, setSelectedChild] = useState(0);
  const [selectedInfant, setSelectedInfant] = useState(0);
  const [adultArr, setAdultArr] = useState([]);
  const [childArr, setChildArr] = useState([]);
  const [infantArr, setInfantArr] = useState([]);
  const [budgetArr, setBudgetArr] = useState([]);
  const [isHotelApiDisabled, setIsHotelApiDisabled] = useState(false);

  const [personsArr, setPersonsArr] = useState([{ label: 0, value: 0 }, { label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }, { label: 6, value: 6 }, { label: 7, value: 7 }, { label: 8, value: 8 }, { label: 9, value: 9 }]);
  const [quesData, setQuesData] = useState([
    {
      quesName: "where are you traveling?",
      isSearchRequired: true,
      datePickerRequired: false,
      options: [],
    },
    {
      quesName: "where are you departing from?",
      isSearchRequired: true,
      datePickerRequired: false,
      options: [],
    },
    {
      quesName: "When are you planning to travel?",
      isSearchRequired: false,
      datePickerRequired: true,
      options: [
        {
          name: "January",
          isSelected: false,
          img: images.Ice,
          desp: "OUR PICK",
        },
        {
          name: "February",
          isSelected: false,
          img: images.Ice,
          desp: "Second Best",
        },
        {
          name: "March",
          isSelected: false,
          img: images.Cloud,
          desp: "OUR PICK",
        },
        {
          name: "April",
          isSelected: false,
          img: images.Sun,
          desp: "OUR PICK",
        },
        {
          name: "May",
          isSelected: false,
          img: images.Sun_Cloud,
          desp: "Second Best",
        },
        {
          name: "June",
          isSelected: false,
          img: images.Cloud,
          desp: "OUR PICK",
        },
        {
          name: "July",
          isSelected: false,
          img: images.Ice,
          desp: "Second Best",
        },
        {
          name: "August",
          isSelected: false,
          img: images.Ice,
          desp: "Off Season",
        },
        {
          name: "September",
          isSelected: false,
          img: images.Cloud,
          desp: "OUR PICK",
        },
        {
          name: "October",
          isSelected: false,
          img: images.Sun,
          desp: "Off Season",
        },
        {
          name: "November",
          isSelected: false,
          img: images.Sun_Cloud,
          desp: "Second Best",
        },
        {
          name: "December",
          isSelected: false,
          img: images.Cloud,
          desp: "OUR PICK",
        },
      ],
      selectedStartDate: "",
      selectedEndDate: "",
    },
    {
      quesName: "Purpose of your traveling?",
      isSearchRequired: false,
      datePickerRequired: false,
      options: [],
    },
    // {
    //   quesName: "Cities you would like to visit?",
    //   isSearchRequired: true,
    //   datePickerRequired: false,
    //   options: [
    //     {
    //       name: "Barcelona, Spain",
    //       isSelected: false,
    //       img: images.Barcelona,
    //     },
    //     {
    //       name: "Lisbon, Portugal",
    //       isSelected: false,
    //       img: images.lisbon,
    //     },
    //     {
    //       name: "Prague, Czech Republic",
    //       isSelected: false,
    //       img: images.prague,
    //     },
    //     {
    //       name: "Seville, Spain",
    //       isSelected: false,
    //       img: images.seville,
    //     },
    //   ],
    // },
    {
      quesName: "How many people are travelling?",
      isSearchRequired: false,
      datePickerRequired: false,
      hasMultipleQues: true,
      options: [],
      ques2: "Select the ages of all your travel companions",
      options2: [
        {
          age: "65+",
          isSelected: false,
        },
        {
          age: "41 - 64",
          isSelected: false,
        },

        {
          age: "21 - 40",
          isSelected: false,
        },
        {
          age: "10 - 20",
          isSelected: false,
        },

      ],
    },
  ]);


  const handleLoginCheck = async () => {
    try {
      const obj = await decodeSessionJwt();
      console.log(obj, "asd")

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    handleLoginCheck()
  }, [])

  const handleGenerateArr = () => {
    let tempArr = [];
    for (let i = 1; i <= 9; i++) {
      tempArr.push(`${i}`);
    }

    // setPeople([...tempArr]);
    setChildArr([0, ...tempArr])
    setAdultArr(tempArr)
    setBudgetArr(tempArr.map((el, i) => `${el}-${parseInt(el) + 1} Lakhs`))
    setInfantArr([0, ...tempArr])
  };

  useEffect(() => {
    handleGenerateArr();
    //  console.log(age,'age');
  }, []);

  const how_many_people = {
    0: {
      slidesPerView: 4,
    },
    400: {
      slidesPerView: 6,
    },
    576: {
      slidesPerView: 6,
    },
  };



  const buttonStyle = {
    display: 'block',
    margin: '10px auto'
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const getArrivalCities = async () => {
    try {
      console.log("gerring arival")
      let query = `isFirstTime=true`
      const { data: res } = await getArrivalCityForItinerary(query);
      if (res) {
        console.log(res)
        let tempArr = [...quesData]
        tempArr[0].options = res.data
        setQuesData(tempArr)
      }
    } catch (error) {
      toastError(error)
    }
  }
  const getDepartureCities = async () => {
    try {
      let selectedArrivalMainObj = quesData[0];

      let query = `isFirstTime=true&rezliveCityId=${selectedArrivalMainObj?.options.find(el => el.isSelected)?.cityRezId}`
      const { data: res } = await getDepartureCityForItinerary(query);
      if (res) {
        let tempArr = [...quesData]
        console.log(tempArr)
        tempArr[1].options = res.data
        setQuesData(tempArr)
      }
    } catch (error) {
      toastError(error)
    }
  }

  const getAllTravelPurposes = async () => {
    try {
      const { data: res } = await getTravelPurposes();
      if (res) {
        let tempArr = [...quesData]
        console.log(tempArr)
        tempArr[3].options = res.data
        setQuesData(tempArr)
      }
    } catch (error) {
      toastError(error)
    }
  }

  useEffect(() => {
    getArrivalCities()
    getAllTravelPurposes()
  }, [])


  const handleQuestionSelection = async (questionArrIndex, value) => {
    let tempArr = [...quesData]
    if (questionArrIndex === 0) {
      tempArr[questionArrIndex].options = tempArr[questionArrIndex]?.options?.map(el => ({ ...el, isSelected: value.cityRezId == el.cityRezId }))
      let tempObj = tempArr[questionArrIndex].options.find(el => el.isSelected);
      if (tempObj && tempObj.isHotelApiDisabled) {
        let { data: res } = await getResort();
        if (res) {

          setIsHotelApiDisabled(tempObj?.isHotelApiDisabled)
          let tempResortQuestion = {
            quesName: "Find Your Resort",
            isSearchRequired: false,
            datePickerRequired: false,
            options: [...res.data.map(el => ({ ...el, isSelected: false }))]
          }
          let tempAmountQuestion = {
            quesName: "Your Budget?",
            isSearchRequired: false,
            datePickerRequired: false,
            options: []
          }
          tempArr = [...tempArr, tempAmountQuestion, tempResortQuestion]
        }
      }
    }
    else if (questionArrIndex === 1) {
      tempArr[questionArrIndex].options = tempArr[questionArrIndex]?.options?.map(el => ({ ...el, isSelected: value.cityRezId == el.cityRezId }))
    }
    else if (questionArrIndex === 2) {
      console.log(tempArr[questionArrIndex])
      tempArr[questionArrIndex].options = tempArr[questionArrIndex].options.map((el, i) => ({ ...el, isSelected: i == tempMonthSelectIndex }))

    }
    else if (questionArrIndex === 3) {
      tempArr[questionArrIndex].options = tempArr[questionArrIndex]?.options?.map((el, index) => ({ ...el, isSelected: index == value }))

    }
    else if (questionArrIndex === 6) {
      tempArr[questionArrIndex].options = tempArr[questionArrIndex]?.options?.map((el, index) => ({ ...el, isSelected: index == value }))
      handleItinearyGenerate()

    }

    setQuesData(tempArr)
    setCurrentQues(currentQues + 1)
  }



  const handleItinearyGenerate = async () => {
    try {

      if (parseInt(selectedAdult) + parseInt(selectedChild) + parseInt(selectedInfant) >= 9) {
        toastError({ message: "Total travellers cannot be greater than 9" })
        return 0;
      }
      setCurrentQues(5)
      setDisplayLoader(true)
      let obj = {
        arrivalCityObj: quesData[0]?.options?.find(el => el.isSelected),
        departureCityObj: quesData[1]?.options?.find(el => el.isSelected),
        travelDateObj: {
          dateArr: monthValue,
          month: quesData[2]?.options?.find(el => el.isSelected)?.name
        },
        peopleTravelling: parseInt(selectedAdult) + parseInt(selectedChild) + parseInt(selectedInfant),
        purposeOfTravelling: quesData[3]?.options?.find(el => el.isSelected)?.name,

      }


      let loginObj = await decodeSessionJwt();
      if (loginObj && loginObj?.userId) {
        obj.userId = loginObj?.userId
        obj.userObj = {
          name: loginObj?.name,
          email: loginObj?.email,
          phone: loginObj?.phone
        }
      }
      if (isHotelApiDisabled) {
        obj.resortId = quesData[6].options.find(el => el.isSelected)?._id;
        obj.isHotelApiDisabled = isHotelApiDisabled;
        let { data: res } = await generateItineraryForHotelApiDisabled(obj);
        if (res) {
          setDisplayLoader(false)
          navigate(`/Itinerary?itineraryid=${res.ItineraryId}`)
          toastSuccess(res.message)
        }
      }
      else {
        let { data: res } = await generateItinerary(obj);
        if (res) {
          setDisplayLoader(false)
          navigate(`/Itinerary?itineraryid=${res.ItineraryId}`)
          toastSuccess(res.message)
        }

      }
    } catch (error) {
      toastError(error)
      setDisplayLoader(false)
    }
  }

  useEffect(() => {
    setCurrentQues(currentQues);
    if (currentQues == 1) {
      getDepartureCities()
    }

  }, [currentQues]);


  const handleMonthValueChange = (e) => {
    setMonthValue(e)
    handleQuestionSelection(currentQues, tempMonthSelectIndex)

  }



  const handleDateSelectionInQuestion = (i) => {
    setTempMonthSelectIndex(i); setShowCalender(!showCalender)
    let tempDate = new Date(new Date().getFullYear(), i, new Date().getDate())
    setMonthValue([tempDate, tempDate])

  }



  return (
    <main>
      <section className="questions">
        <div className="container-fluid">
          <div>
            <div className="text-center w-100 mb-5">
              <Link to="/" className="navbar-brand">
                <img src={images.logo_red} className="main-logo" alt="" />
              </Link>
            </div>
            <ul className="timeline">
              {quesData.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={`${currentQues === i
                      ? "half"
                      : currentQues > i
                        ? "full"
                        : currentQues < i
                          ? "empty"
                          : ""
                      }`}
                    onClick={() => setCurrentQues(i)}
                  >
                    <div className="dot"></div>
                    <div className="empty-line"></div>
                    {currentQues === i && (
                      <div className="half-filled">
                        <img src={images.man} alt="" />
                        <div className="half-line"></div>
                      </div>
                    )}
                    {currentQues > i && <div className="full-filled"></div>}
                    {/* {currentQues===i && <div className="walking-men"><img src={images.man} alt="" /></div> } */}
                  </li>
                );
              })}
            </ul>
          </div>
          {quesData.map((item, index) => {
            if (currentQues === index) {
              return (
                <div className="questions-inner" key={index}>
                  <div className="title-section text-center mb-3 mb-md-4">
                    <h3 className="heading fw-500">{item.quesName}</h3>
                  </div>
                  {item.isSearchRequired && (
                    <div className="col-12 col-lg-10 col-xxl-7 mx-auto">
                      <div className="form-control search-bar mb-5 border-0">
                        <input
                          type="search"
                          className="border-0 bg-transparent w-100"
                          placeholder="Searh Here...."
                        />
                        <button className="btn red-bg with-icon btn-custom btn-lg">
                          <div className="icon">
                            <BsSearch />
                          </div>
                          <span>Search</span>
                        </button>
                      </div>
                    </div>
                  )}
                  {currentQues === 0 && (
                    <div className="destination-list">
                      {item.options.map((row, i) => {
                        return (
                          <div className={[`best-off-box ${row?.isSelected ? 'red_border' : ''}  `]} onClick={() => handleQuestionSelection(index, row)} style={{ cursor: 'pointer' }} key={i}>
                            <div className="image">
                              <img src={generateFilePath(row.displayImage)} alt="" />
                            </div>
                            <div className="content">
                              <p>{row?.itineraryText}</p>
                              <h4>{row.name}</h4>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {currentQues === 1 && (
                    <div className="destination-list">
                      {item.options.map((row, i) => {
                        return (
                          <div className={[`best-off-box ${row?.isSelected ? 'red_border' : ''}  `]} onClick={() => handleQuestionSelection(index, row)} style={{ cursor: 'pointer' }} key={i}>
                            <div className="image">
                              <img src={generateFilePath(row.displayImage)} alt="" />
                            </div>
                            <div className="content">
                              <p>{row?.itineraryText}</p>
                              <h4>{row.name}</h4>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {currentQues === 2 && (
                    <div className="ques-2">
                      <ul className="travel-month">
                        {item.options.map((option, i) => {
                          return (
                            <li
                              key={i}
                              className={[`${option?.isSelected ? 'red_border' : ''}  `]}
                              onClick={() => { handleDateSelectionInQuestion(i) }}
                            >
                              <p
                                className={`${option.desp === "OUR PICK"
                                  ? "our-pick"
                                  : option.desp === "Off Season"
                                    ? "off-season"
                                    : option.desp === "Second Best"
                                      ? "second-best"
                                      : ""
                                  } m-0`}
                              >
                                {option.desp}
                              </p>
                              <img src={option.img} alt="" />
                              <h5 className="m-0">{option.name}</h5>
                            </li>
                          );
                        })}
                      </ul>
                      {showCalender && (
                        <div className="date-picker-module">
                          <DateRangePicker
                            onChange={(e) => handleMonthValueChange(e)}
                            value={monthValue}
                            minDate={new Date()}
                            isOpen={showCalender}
                            onCalendarClose={() =>
                              setShowCalender(!showCalender)
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {currentQues === 3 && (
                    <ul className="travel-purpose">
                      {item.options.map((option, i) => {
                        return (
                          <li key={i} className={[`${option?.isSelected ? 'red_border' : ''}  `]} onClick={() => handleQuestionSelection(index, i)}>
                            <img src={generateFilePath(option.image)} alt="" />
                            <h5>{option.name}</h5>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {/* {currentQues ===  && (
                    <ul className="travel-purpose">
                      {item.options.map((option, i) => {
                        return (
                          <li key={i}>
                            <img src={option.img} alt="" />
                            <h5>{option.name}</h5>
                          </li>
                        );
                      })}
                    </ul>
                  )} */}
                  {currentQues === 4 && (
                    <div className="how-many-people col-12 col-md-9 col-lg-8 col-xxl-5 mx-auto">
                      <div className="row">

                        <div className="col-12">
                          {isHotelApiDisabled &&
                            <>
                              <label>Rooms</label>
                              <Swiper
                                modules={[Navigation]}
                                slidesPerView={10}
                                breakpoints={how_many_people}
                              >
                                {adultArr.map((item, i) => {
                                  return (
                                    <SwiperSlide>
                                      <div onClick={(e) => setSelectedRoom(item)} className={[`num ${selectedRoom == item && 'red_border'}`]}>{item}</div>
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                            </>
                          }
                          <label>Adults</label>
                          <Swiper
                            modules={[Navigation]}
                            slidesPerView={10}
                            breakpoints={how_many_people}
                          >
                            {adultArr.map((item, i) => {
                              return (
                                <SwiperSlide>
                                  <div onClick={(e) => setSelectedAdult(item)} className={[`num ${selectedAdult == item && 'red_border'}`]}>{item}</div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                        <div className="col-6 mt-2">

                          <label className="mt-2">Children (2-12 yrs)</label>

                          <Swiper
                            modules={[Navigation]}
                            slidesPerView={10}
                            breakpoints={how_many_people}
                          >
                            {childArr.map((item, i) => {
                              return (
                                <SwiperSlide>
                                  <div onClick={(e) => setSelectedChild(item)} className={[`num ${selectedChild == item && 'red_border'}`]}>{item}</div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                        <div className="col-6 mt-2">

                          <label className="mt-2">Infants (0-2 yrs)</label>

                          <Swiper
                            modules={[Navigation]}
                            slidesPerView={10}
                            breakpoints={how_many_people}
                          >
                            {infantArr.map((item, i) => {
                              return (
                                <SwiperSlide>
                                  <div onClick={(e) => setSelectedInfant(item)} className={[`num ${selectedInfant == item && 'red_border'}`]}>{item}</div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      </div>
                    </div>

                  )}

                  {currentQues === 5 && (
                    <div className="how-many-people col-12 col-md-9 col-lg-8 col-xxl-5 mx-auto">
                      <div className="row">

                        <div className="col-12">

                          <label>Budget</label>
                          <Swiper
                            modules={[Navigation]}
                            slidesPerView={10}
                            breakpoints={how_many_people}
                          >
                            {budgetArr.map((item, i) => {
                              return (
                                <SwiperSlide>
                                  <div onClick={(e) => setSelectedBudget(item)} className={[`num ${selectedBudget == item && 'red_border'}`]}>{item}</div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>

                      </div>
                    </div>

                  )}
                  {/* 
                  {item?.hasMultipleQues && (
                    <div className="title-section text-center mb-3 mb-md-4">
                      <h3 className="heading fw-500">{item.ques2}</h3>
                    </div>
                  )} */}
                  {currentQues === 6 && (
                    <div className="destination-list">
                      {item.options.map((row, i) => {
                        return (
                          <div className={[`best-off-box ${row?.isSelected ? 'red_border' : ''}  `]} onClick={() => handleQuestionSelection(index, i)} style={{ cursor: 'pointer' }} key={i}>
                            <div className="image">
                              <img src={generateFilePath(row.imageUrl)} alt="" />
                            </div>
                            <div className="content">
                              {/* <p>{row?.name}</p> */}
                              <h4>{row.name}</h4>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {!isHotelApiDisabled && currentQues === 4 && (
                    <>
                      <div className="select-age">

                      </div>
                      <ul className="ques-footer">
                        <li>

                        </li>
                        <li>
                          <button className="btn red-bg btn-custom btn-lg px-4 px-lg-5" onClick={() => { handleItinearyGenerate() }}>Next</button>
                        </li>
                      </ul>
                    </>

                  )}
                  {isHotelApiDisabled && (currentQues === 4 || currentQues === 5) && (
                    <>
                      <div className="select-age">

                      </div>
                      <ul className="ques-footer">
                        <li>

                        </li>
                        <li>
                          <button className="btn red-bg btn-custom btn-lg px-4 px-lg-5" onClick={() => { setCurrentQues(currentQues + 1) }}>Next</button>
                        </li>
                      </ul>
                    </>

                  )}

                  {isHotelApiDisabled && currentQues === 6 && (
                    <>
                      <div className="select-age">

                      </div>
                      <ul className="ques-footer">
                        <li>

                        </li>
                        <li>
                          <button className="btn red-bg btn-custom btn-lg px-4 px-lg-5" onClick={() => {

                            handleItinearyGenerate()

                          }}>Next</button>
                        </li>
                      </ul>
                    </>

                  )}

                </div>
              );
            }
          })}
          {displayLoader &&
            <div>
              <Lottie options={defaultOptions}
                height={400}
                width={400}
                isStopped={false}
                isPaused={false} />

            </div>
          }


          {/* /> */}
        </div>
      </section>
    </main>
  );
}

export default Questions;
