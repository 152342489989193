import React, { useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import Select from "react-select";
import Modal from "react-modal";
import { images } from "./Utility/Images";

function Visa() {
  const [country, setCountry] = useState("");
  const [showVisaDetail, setShowVisaDetail] = useState(false);
  const sort_by = [
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "India", label: "India" },
    { value: "Colombia", label: "Colombia" },
    { value: "Thailand", label: "Thailand" },
    { value: "South Korea", label: "South Korea" },
  ];
  const [instructions, setInstructions] = useState([
    {
      name: "IDENTITY",
      icon: images.identity,
      value: "identity",
      active: true,
    },
    {
      name: "OCCUPATION",
      icon: images.occupation,
      value: "occupation",
      active: false,
    },
    {
      name: "FINANCIALS",
      icon: images.financial,
      value: "financial",
      active: false,
    },
    {
      name: "VOUCHERS",
      icon: images.vocher,
      value: "voucher",
      active: false,
    },
  ]);
  const [smallInfo, setSmallInfo] = useState(false);
  const [bigInfo, setBigInfo] = useState(false);
  const [submission, setSubmission] = useState(false);
  const activeTab = (i) => {
    const temp = instructions.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setSubmission(false);
    setInstructions([...temp]);
  };
  const activeSubmission = () => {
    const temp = instructions.map((item, index) => {
      item.active ? (item.active = false) : (item.active = false);
      return item;
    });
    setSubmission(true);
    setInstructions([...temp]);
  };

  return (
    <>
      <section className="get-visa">
        <div className="container">
          {showVisaDetail ? (
            <>
              <h1 className="text-white mb-5 text-center d-flex gap-2 justify-content-center align-items-center">
                {country}
                <div
                  className="icon pointer red"
                  onClick={() => setSmallInfo(true)}
                >
                  <AiFillSetting />
                </div>
              </h1>
              <div className="row">
                {instructions.map((item, i) => {
                  return (
                    <div className="col-12 col-md-3" key={i}>
                      <div
                        className="visa-box pointer"
                        onClick={() => {
                          activeTab(i);
                          setBigInfo(true);
                        }}
                      >
                        <div className="icon">
                          <img src={item.icon} alt="" />
                        </div>
                        <h5 className="mb-0">{item.name}</h5>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="row justify-content-center">
              <div className="col-12 col-md-5">
                <form className="form row">
                  {country === "" && (
                    <div className="col-12 mb-3">
                      <h2 className="text-white mb-4 text-center">
                        All your Visa info in one go.
                      </h2>
                      <Select
                        classNamePrefix="sort-by"
                        options={sort_by}
                        placeholder="Which Country are you going to?"
                        defaultInputValue={country}
                        onChange={(e) => setCountry(e.value)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#eeeeee",
                            primary: "#FF5050",
                          },
                        })}
                      />
                    </div>
                  )}
                  {country !== "" && (
                    <div className="col-12 mb-3">
                      <h2 className="text-white mb-4 text-center">
                        Great! Your Visa Process should be very easy!
                      </h2>
                      <div className="text-center d-flex gap-3 justify-content-center">
                        <button
                          className="btn red-bg btn-custom btn-lg"
                          onClick={() => {
                            setShowVisaDetail(!showVisaDetail);
                            setSmallInfo(true);
                          }}
                        >
                          {country} Checklist
                        </button>
                        <button
                          className="btn red-bg btn-custom btn-lg"
                          onClick={() => setCountry("")}
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      </section>

      <Modal
        isOpen={smallInfo}
        onRequestClose={() => setSmallInfo(false)}
        className="custom-modal"
        bodyOpenClassName="custom-modal-body"
        overlayClassName="custom-modal-overlay"
        htmlOpenClassName="custom-modal-html"
        portalClassName="custom-modal-parent"
        contentLabel="Example Modal"
      >
        <div className="talk-to-expert">
          <div className="title-section mb-3">
            <h4 className="heading mb-1">Enter Your Information Here!!</h4>
          </div>
          <form className="form row">
            <div className="col-12 mb-4">
              <h5>Your Occupation</h5>
              <div className="form-check form-check-inline">
                <label className="fs-6">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="occupation"
                    value="option1"
                  />
                  Salaried
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="fs-6">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="occupation"
                    value="option2"
                  />
                  Self Employed
                </label>
              </div>
            </div>
            <div className="col-12 mb-4">
              <h5>Your Marital Status</h5>
              <div className="form-check form-check-inline">
                <label className="fs-6">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="maritial_status"
                    value="option1"
                  />
                  Single
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="fs-6">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="maritial_status"
                    value="option2"
                  />
                  Couple
                </label>
              </div>
            </div>
            <div className="col-12">
              <button
                className="btn btn-custom red-bg px-4"
                onClick={() => setSmallInfo(false)}
              >
                Done
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={bigInfo}
        onRequestClose={() => setBigInfo(false)}
        className="custom-modal rounded-0"
        bodyOpenClassName="custom-modal-body"
        overlayClassName="custom-modal-overlay"
        htmlOpenClassName="custom-modal-html"
        portalClassName="custom-modal-parent"
        contentLabel="Example Modal"
      >
        <div className="visa-procedure">
          <ul className="tabs">
            {instructions.map((item, i) => {
              return (
                <li
                  className={item.active ? "active" : ""}
                  onClick={() => activeTab(i)}
                >
                  {item.name}
                </li>
              );
            })}
            <li
              className={`border-0 ${submission ? "active" : ""}`}
              onClick={activeSubmission}
            >
              SUBMISSION
            </li>
          </ul>
          <div className="tab-content">
            {instructions.map((item, i) => {
              if (item.active && item.value === "identity") {
                return (
                  <div className="content">
                    <p className="text-end mb-0">
                      Last Updated On : 13/06/2022
                    </p>
                    <h5 className="text-center mb-3">Identity</h5>
                    <h6>PASSPORT</h6>
                    <p>
                      Physical Passport (with 10-year validity) with at least 6
                      months validity from the date of return to your home
                      country. Please note that the passport booklet should have
                      a minimum of 2 blank pages for official use.
                    </p>
                    <ul className="list-circle">
                      <li>All old Passport (s) (If any).</li>
                      <li>
                        Passports with handwritten entries are not accepted.
                      </li>
                      <li>Photocopy of the Passport front & Last page.</li>
                      <li>Photocopy of visa issued in the past (If any).</li>
                      <li>
                        Passports issued beyond 10 years will not be accepted by
                        the Embassy.
                      </li>
                    </ul>
                    <h6>PHOTOGRAPHS</h6>
                    <p>
                      Four hard copies of studio-o-clicked photographs (35X45 MM
                      with 75-80% face coverage) with white background and
                      without border.
                    </p>
                    <ul className="list-circle">
                      <li>
                        The photograph must be colored and recently taken within
                        the last three months (Black and white photographs will
                        not be accepted).
                      </li>
                      <li>
                        The visa photo should not have been used in any previous
                        visa applications.
                      </li>
                      <li>
                        Size - 35X45 MM, against White Background with darker
                        clothes and 75 to 80 % Face Visibility.
                      </li>
                      <li>Face Should be facing straight toward camera.</li>
                      <li>
                        The parts of the face and the eyes must be clearly
                        presented.
                      </li>
                      <li>
                        Hair or fringes should not cover the face and both ears
                        should be clearly visible.
                      </li>
                      <li>Teeth must not be visible.</li>
                      <li>Spectacles or Sunglasses are not allowed.</li>
                      <li>
                        Headgears are not allowed Except Religious reasons.
                      </li>
                    </ul>
                  </div>
                );
              }
              if (item.active && item.value === "occupation") {
                return (
                  <div className="content">
                    <p className="text-end mb-0">
                      Last Updated On : 13/06/2022
                    </p>
                    <h5 className="text-center mb-3">Occupation</h5>
                    <h6>Salaried</h6>
                    <ul className="list-circle">
                      <li>
                        Cover letter from the ‘applicant’ with complete travel
                        dates, explaining his/her Current Occupation details
                        along with other co-traveller’s current occupation
                        details (if any) and the purpose of travel also the
                        details of the person who will be bearing the trip
                        expenses (Typed on A4 Sheet).
                        <ul className="list-circle ms-4">
                          <li>Covering Letter Format</li>
                        </ul>
                      </li>
                      <li>
                        Cover letter from the ‘applicant’ with complete travel
                        dates, explaining his/her Current Occupation details
                        along with other co-traveller’s current occupation
                        details (if any) and the purpose of travel also the
                        details of the person who will be bearing the trip
                        expenses (Typed on A4 Sheet).
                        <ul className="list-circle ms-4">
                          <li>Covering Letter Format</li>
                        </ul>
                      </li>
                      <li>
                        Cover letter from the ‘applicant’ with complete travel
                        dates, explaining his/her Current Occupation details
                        along with other co-traveller’s current occupation
                        details (if any) and the purpose of travel also the
                        details of the person who will be bearing the trip
                        expenses (Typed on A4 Sheet).
                        <ul className="list-circle ms-4">
                          <li>Covering Letter Format</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                );
              }
              if (item.active && item.value === "financial") {
                return (
                  <div className="content">
                    <p className="text-end mb-0">
                      Last Updated On : 13/06/2022
                    </p>
                    <h5 className="text-center mb-3">Financials</h5>
                    <h6>PASSPORT</h6>
                    <p>
                      Physical Passport (with 10-year validity) with at least 6
                      months validity from the date of return to your home
                      country. Please note that the passport booklet should have
                      a minimum of 2 blank pages for official use.
                    </p>
                    <ul className="list-circle">
                      <li>All old Passport (s) (If any).</li>
                      <li>
                        Passports with handwritten entries are not accepted.
                      </li>
                      <li>Photocopy of the Passport front & Last page.</li>
                      <li>Photocopy of visa issued in the past (If any).</li>
                      <li>
                        Passports issued beyond 10 years will not be accepted by
                        the Embassy.
                      </li>
                    </ul>
                    <h6>PHOTOGRAPHS</h6>
                    <p>
                      Four hard copies of studio-o-clicked photographs (35X45 MM
                      with 75-80% face coverage) with white background and
                      without border.
                    </p>
                    <ul className="list-circle">
                      <li>
                        The photograph must be colored and recently taken within
                        the last three months (Black and white photographs will
                        not be accepted).
                      </li>
                      <li>
                        The visa photo should not have been used in any previous
                        visa applications.
                      </li>
                      <li>
                        Size - 35X45 MM, against White Background with darker
                        clothes and 75 to 80 % Face Visibility.
                      </li>
                      <li>Face Should be facing straight toward camera.</li>
                      <li>
                        The parts of the face and the eyes must be clearly
                        presented.
                      </li>
                      <li>
                        Hair or fringes should not cover the face and both ears
                        should be clearly visible.
                      </li>
                      <li>Teeth must not be visible.</li>
                      <li>Spectacles or Sunglasses are not allowed.</li>
                      <li>
                        Headgears are not allowed Except Religious reasons.
                      </li>
                    </ul>
                  </div>
                );
              }
              if (item.active && item.value === "voucher") {
                return (
                  <div className="content">
                    <p className="text-end mb-0">
                      Last Updated On : 13/06/2022
                    </p>
                    <h5 className="text-center mb-3">Financials</h5>
                    <h6>Vouchers</h6>
                    <p>
                      Physical Passport (with 10-year validity) with at least 6
                      months validity from the date of return to your home
                      country. Please note that the passport booklet should have
                      a minimum of 2 blank pages for official use.
                    </p>
                    <ul className="list-circle">
                      <li>All old Passport (s) (If any).</li>
                      <li>
                        Passports with handwritten entries are not accepted.
                      </li>
                      <li>Photocopy of the Passport front & Last page.</li>
                      <li>Photocopy of visa issued in the past (If any).</li>
                      <li>
                        Passports issued beyond 10 years will not be accepted by
                        the Embassy.
                      </li>
                    </ul>
                    <h6>PHOTOGRAPHS</h6>
                    <p>
                      Four hard copies of studio-o-clicked photographs (35X45 MM
                      with 75-80% face coverage) with white background and
                      without border.
                    </p>
                    <ul className="list-circle">
                      <li>
                        The photograph must be colored and recently taken within
                        the last three months (Black and white photographs will
                        not be accepted).
                      </li>
                      <li>
                        The visa photo should not have been used in any previous
                        visa applications.
                      </li>
                      <li>
                        Size - 35X45 MM, against White Background with darker
                        clothes and 75 to 80 % Face Visibility.
                      </li>
                      <li>Face Should be facing straight toward camera.</li>
                      <li>
                        The parts of the face and the eyes must be clearly
                        presented.
                      </li>
                      <li>
                        Hair or fringes should not cover the face and both ears
                        should be clearly visible.
                      </li>
                      <li>Teeth must not be visible.</li>
                      <li>Spectacles or Sunglasses are not allowed.</li>
                      <li>
                        Headgears are not allowed Except Religious reasons.
                      </li>
                    </ul>
                  </div>
                );
              }
            })}
            {submission && (
              <div className="content">
                <p className="text-end mb-0">Last Updated On : 13/06/2022</p>
                <h5 className="text-center mb-3">Submission</h5>
                <ul className="list-circle">
                  <li>
                    The travellers can submit an application at the nearest visa
                    processing centre that is open.
                  </li>
                </ul>
                <p>
                  Processing Centre Location: New Delhi, Ahmedabad, Bengaluru,
                  Chandigarh, Chennai, Hyderabad, Jalandhar, Kochi, Kolkata,
                  Mumbai, Puducherry, White field Bengaluru & Pune, Goa,
                  Gurugram, Jaipur , Thiruvananthapuram.
                </p>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Visa;
