import React, { useState } from "react";
import Select from "react-select";
import { HiUserGroup } from "react-icons/hi";
import { GiGymBag } from "react-icons/gi";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { MdFlightTakeoff } from "react-icons/md";
import {
  BsArrowDown,
  BsArrowUp,
  BsChevronDown,
  BsSearch,
} from "react-icons/bs";
import { images } from "../Utility/Images";
import { BiChevronDown } from "react-icons/bi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, EffectFade } from "swiper";
import { FaQuoteLeft } from "react-icons/fa";

function Transfer() {
  const [origin, setOrigin] = useState(false);
  const [destination, setDestination] = useState(false);
  const [getDate, GetDate] = useState([new Date(), new Date()]);
  const sort_by = [
    { value: "popular", label: "Popular" },
    { value: "Date, new to old", label: "Date, new to old" },
    { value: "Date, old to new", label: "Date, old to new" },
    { value: "Price, low to high", label: "Price, low to high" },
    { value: "Price, high to low", label: "Price, high to low" },
  ];
  const [departure, setDeparture] = useState([
    {
      img: images.car,
      active: false,
      info: {
        taxiName: "Hyundai (verna)",
        taxiSeat: "4 Seater ",
        taxiPaymentMethod: "Cash/Card/UPI",
        taxiComfort: "Comfort",
        taxiTiming: "09:00 to 13:00",
        isPersonnel: false,
        taxiPayment: "₹10,000",
      },
    },
    {
      img: images.car,
      active: false,
      info: {
        taxiName: "Tata Safari",
        taxiSeat: "4 Seater ",
        taxiPaymentMethod: "Cash/Card/UPI",
        taxiComfort: "Comfort Electric",
        taxiTiming: "09:00 to 13:00",
        isPersonnel: false,
        taxiPayment: "₹10,000",
      },
    },
    {
      img: images.car,
      active: false,
      info: {
        taxiName: "Tata Tigor EV",
        taxiSeat: "4 Seater ",
        taxiPaymentMethod: "Cash/Card/UPI",
        taxiComfort: "Black SUV",
        taxiTiming: "09:00 to 13:00",
        isPersonnel: true,
        taxiPayment: "₹10,000",
      },
    },
    {
      img: images.car,
      active: false,
      info: {
        taxiName: "Tata Safari",
        taxiSeat: "4 Seater ",
        taxiPaymentMethod: "Cash/Card/UPI",
        taxiComfort: "Comfort XL",
        taxiTiming: "09:00 to 13:00",
        isPersonnel: true,
        taxiPayment: "₹10,000",
      },
    },
  ]);

  const ActiveTab = (i) => {
    const temp = departure.map((item, index) => {
      i === index ? (item.active = !item.active) : (item.active = false);
      return item;
    });
    setDeparture([...temp]);
  };

  return (
    <div className="container">
      <div className="itinerary-flight mb-80">
        <div className="row gy-5">
          <div className="col-12">
            <form className="form row select-date-origin">
              <div className="col-12 col-md-3">
                <label>Origin</label>
                <input
                  type="search"
                  className="form-control"
                  onClick={() => setOrigin(!origin)}
                />
                {origin && (
                  <div className="custum-dropdown">
                    <p className="text-muted">Popular cities</p>
                    <ul className="list">
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-3">
                <label>Destination</label>
                <input
                  type="search"
                  className="form-control"
                  onClick={() => setDestination(!destination)}
                />
                {destination && (
                  <div className="custum-dropdown">
                    <p className="text-muted">Popular cities</p>
                    <ul className="list">
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <MdFlightTakeoff />
                        </div>
                        <div>
                          <p className="text-dark">Mumbai, India</p>
                          <p className="text-muted">
                            Chhatrapati Shivaji International Airport
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-4">
                <label>Departure - Return</label>
                <div className="date-picker-module">
                  <DateRangePicker onChange={GetDate} value={getDate} />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <button
                  type="submit"
                  className="btn red-bg with-icon btn-custom btn-lg"
                >
                  <div className="icon">
                    <BsSearch />
                  </div>
                  Search
                </button>
              </div>
            </form>
          </div>
          <div className="col-12">
            <ul className="flight-filter">
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Seater"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Personal/Shareing"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="1 carry-on bag"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Price"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Time"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
              <li>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Duration"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </li>
            </ul>
          </div>
          <div className="col-12">
            <div className="departing-flights transfer-flight">
              <div className="heading">
                <div>
                  <h4>Taxi</h4>
                  <p>
                    Total price includes raxes + fees for 4 passnger, Additional
                    bag fees may apply
                  </p>
                </div>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  placeholder="Cheapest"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </div>
              {departure.map((item, i) => {
                return (
                  <div className="box" key={i}>
                    <div
                      className={`inner-box pointer ${
                        item.active ? "active" : ""
                      }`}
                      onClick={() => ActiveTab(i)}
                    >
                      <div className="left">
                        <div className="image">
                          <img
                            src={item.img}
                            alt=""
                            className="h-100 w-100 img-contain"
                          />
                        </div>
                      </div>
                      <ul className="right flex-1">
                        <li>
                          <h5>{item.info.taxiComfort}</h5>
                          <p>{item.info.taxiName}</p>
                        </li>
                        <li>
                          <h5>{item.info.taxiTiming}</h5>
                          <p>{item.info.taxiSeat}</p>
                        </li>
                        <li>
                          {item.active ? (
                            <button className="btn btn-custom red-bg px-4">
                              Select Taxi
                            </button>
                          ) : item.info.isPersonnel ? (
                            <h5>Personal</h5>
                          ) : (
                            <h5>Sharing</h5>
                          )}
                        </li>
                        <li>
                          <h5>{item.info.taxiPayment}</h5>
                          <p>{item.info.taxiPaymentMethod}</p>
                        </li>
                        <li>
                          <div className="icon down-arrow">
                            <BiChevronDown />
                          </div>
                        </li>
                      </ul>
                    </div>
                    {item.active && (
                      <div className="transfer-outer">
                        <ul className="location">
                          <li>
                            <h6>12:15</h6>
                            <h6>International Airport Kharkiv (HRK)</h6>
                          </li>
                          <li>
                            <h5 className="red">To</h5>
                          </li>
                          <li>
                            <h6>12:15</h6>
                            <h6>International Airport Kharkiv (HRK)</h6>
                          </li>
                        </ul>
                        <p>₹11.30</p>
                        <p>In 8 mins. 11:12 AM dropoff</p>
                        <p>6 Seats. Affordable rides for groups up to 6</p>
                        <p>
                          You agree to pay the upfront price and applicable wait
                          time fees. The amount presented may be adjusted up or
                          down at the end of the trip if the actual route has
                          tolls/surcharges that differ from the estimated route.
                          Your price may also change based on the rates below if
                          the length, duration, or route of your trip changes.
                        </p>
                        <ul className="price col-12 col-md-8 col-lg-5">
                          <li>
                            <span>Base Fare:- </span> ₹3.00
                          </li>
                          <li>
                            <span>Minimum Fare:- </span>₹10.00
                          </li>
                          <li>
                            <span>+ Per Minute:- </span> ₹0.42
                          </li>
                          <li>
                            <span>+ Per Mile:- </span> ₹1.72
                          </li>
                          <li>
                            <span>Estimated Surcharges:- </span> ₹0.95
                          </li>
                          <li>
                            <span>Marketplace fee:- </span> ₹1.80
                          </li>
                        </ul>

                        <p>
                          Additional wait time charges may apply to your trip if
                          the driver has waited 2 minute(s): ₹0.43 per minute.
                          For multiple-stop trips, an additional charge of up to
                          ₹0.81 per minute at a stop will apply.
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials-style-2">
        <div className="title-section bg text-center w-100 mb-4">
          <h1 className="heading fw-semibold">Testimonials</h1>
          <p className="text-dark fs-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        <Swiper
          slidesPerView={1}
          autoplay={{ disableOnInteraction: false }}
          loop
          speed={2000}
          modules={[Autoplay, Navigation, EffectFade]}
          navigation
          // effect="fade"
        >
          <SwiperSlide>
            <div className="testimonials-style-2-box">
              <div className="row align-items-center gx-5">
                <div className="col-12 col-lg-6">
                  <div className="image left">
                    <img src={images.testimonial1} alt="" />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="right">
                    <div className="icon red fs-1 mb-4">
                      <FaQuoteLeft />
                    </div>
                    <h4 className="tour-to">Maldives</h4>
                    <div className="content">
                      <p>
                        We booked our Maldives Honeymoon through Deyor. Aman was
                        super helpful and helped us understand the destination,
                        properties and curated such an amazing tour for us. We
                        can't wait to travel with Deyor again!
                      </p>
                    </div>
                    <div className="name">
                      <h5>Anjali Nayak</h5>
                      <p>
                        <i>Maldives Honeymoon</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonials-style-2-box">
              <div className="row align-items-center gx-5">
                <div className="col-12 col-lg-6">
                  <div className="image left">
                    <img src={images.testimonial2} alt="" />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="right">
                    <div className="icon red fs-1 mb-4">
                      <FaQuoteLeft />
                    </div>
                    <h4 className="tour-to">Maldives</h4>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Fusce eleifend tempor malesuada. Donec tempor quam vel
                        mi laoreet convallis. Praesent euismod tortor quis
                        iaculis ultrices. Phasellus felis lorem, ultricies nec
                        ligula finibus, mollis aliquet erat. Nulla neque ipsum,
                        laoreet ut lacinia id, finibus eget arcu. Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit. Fusce
                        eleifend tempor malesuada.
                      </p>
                    </div>
                    <div className="name">
                      <h5>Anjali Nayak</h5>
                      <p>
                        <i>Maldives Honeymoon</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonials-style-2-box">
              <div className="row align-items-center gx-5">
                <div className="col-12 col-lg-6">
                  <div className="image left">
                    <img src={images.testimonial3} alt="" />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="right">
                    <div className="icon red fs-1 mb-4">
                      <FaQuoteLeft />
                    </div>
                    <h4 className="tour-to">Maldives</h4>
                    <div className="content">
                      <p>
                        Praesent euismod tortor quis iaculis ultrices. Phasellus
                        felis lorem, ultricies nec ligula finibus, mollis
                        aliquet erat. Nulla neque ipsum, laoreet ut lacinia id,
                        finibus eget arcu. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Fusce eleifend tempor
                        malesuada.
                      </p>
                    </div>
                    <div className="name">
                      <h5>Anjali Nayak</h5>
                      <p>
                        <i>Maldives Honeymoon</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Transfer;
