import React from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";

function FAQ() {
  return (
    <>
      <Header />
      <main>
        <section className="faq-page pb-80 border-top pt-3">
          <div className="container">
            <div className="title-section bg mb-4">
              <h1>Frequently Asked Questions</h1>
            </div>
            <div className="faq p-0">
              <div className="row">
                <div className="col-12 col-md-8">
                  <details>
                    <summary>Is this trip costed in real time prices?</summary>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Soluta, asperiores laudantium. Dolorem excepturi
                        doloribus, inventore magnam adipisci explicabo assumenda
                        perferendis reiciendis perspiciatis mollitia, nesciunt
                        deserunt nostrum ut earum aspernatur. Aliquid.
                      </p>
                    </div>
                  </details>
                  <details>
                    <summary>
                      How do I change my flights and hotel options?
                    </summary>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Soluta, asperiores laudantium. Dolorem excepturi
                        doloribus, inventore magnam adipisci explicabo assumenda
                        perferendis reiciendis perspiciatis mollitia, nesciunt
                        deserunt nostrum ut earum aspernatur. Aliquid.
                      </p>
                    </div>
                  </details>
                  <details>
                    <summary>
                      What help would be provided in Visa assistance?
                    </summary>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Soluta, asperiores laudantium. Dolorem excepturi
                        doloribus, inventore magnam adipisci explicabo assumenda
                        perferendis reiciendis perspiciatis mollitia, nesciunt
                        deserunt nostrum ut earum aspernatur. Aliquid.
                      </p>
                    </div>
                  </details>
                  <details>
                    <summary>
                      How do I change the dates of my departure?
                    </summary>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Soluta, asperiores laudantium. Dolorem excepturi
                        doloribus, inventore magnam adipisci explicabo assumenda
                        perferendis reiciendis perspiciatis mollitia, nesciunt
                        deserunt nostrum ut earum aspernatur. Aliquid.
                      </p>
                    </div>
                  </details>
                  <details>
                    <summary>
                      How can I book this trip? How will I get onfirmations of
                      all the bookings?
                    </summary>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Soluta, asperiores laudantium. Dolorem excepturi
                        doloribus, inventore magnam adipisci explicabo assumenda
                        perferendis reiciendis perspiciatis mollitia, nesciunt
                        deserunt nostrum ut earum aspernatur. Aliquid.
                      </p>
                    </div>
                  </details>
                  <details>
                    <summary>
                      How to edit the passenger count or add an extra room?
                    </summary>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Soluta, asperiores laudantium. Dolorem excepturi
                        doloribus, inventore magnam adipisci explicabo assumenda
                        perferendis reiciendis perspiciatis mollitia, nesciunt
                        deserunt nostrum ut earum aspernatur. Aliquid.
                      </p>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default FAQ;
