import logo from "../../assets/images/logo.png";
import slide1 from "../../assets/images/slides/slide1.jfif";
import slide2 from "../../assets/images/slides/slide2.jfif";
import slide3 from "../../assets/images/slides/slide3.jfif";
import slide4 from "../../assets/images/slides/slide4.jpg";
import slide5 from "../../assets/images/slides/slide5.jpg";
import bike from "../../assets/images/icons/bike.png";
import flight from "../../assets/images/icons/flight.png";
import holiday from "../../assets/images/icons/holiday.png";
import hotel from "../../assets/images/icons/hotel.png";
import best_price from "../../assets/images/icons/best_price.png";
import transparency from "../../assets/images/icons/transparency.png";
import concierge from "../../assets/images/icons/concierge.png";
import expert from "../../assets/images/icons/expert.png";
import award1 from "../../assets/images/award1.png";
import award2 from "../../assets/images/award2.png";
import award3 from "../../assets/images/award3.png";
import award4 from "../../assets/images/award4.png";
import award5 from "../../assets/images/award5.png";
import award6 from "../../assets/images/award6.png";
import award7 from "../../assets/images/award7.png";
import award8 from "../../assets/images/award8.png";
import brand1 from "../../assets/images/brand1.png";
import brand2 from "../../assets/images/brand2.png";
import brand3 from "../../assets/images/brand3.png";
import brand4 from "../../assets/images/brand4.png";
import brand5 from "../../assets/images/brand5.png";
import beach from "../../assets/images/beach.jfif";
import camp from "../../assets/images/camp.jfif";
import cycling from "../../assets/images/cycling.jfif";
import dubai from "../../assets/images/dubai.jfif";
import egypt from "../../assets/images/egypt.jfif";
import europe from "../../assets/images/europe.jfif";
import france from "../../assets/images/france.jfif";
import goa from "../../assets/images/goa.png";
import goa2 from "../../assets/images/goa2.jfif";
import greece from "../../assets/images/greece.png";
import italy from "../../assets/images/italy.png";
import jammu from "../../assets/images/jammu.jfif";
import maldive from "../../assets/images/maldive.jfif";
import manali from "../../assets/images/manali.png";
import maritius from "../../assets/images/maritius.jfif";
import maritius2 from "../../assets/images/maritius2.jfif";
import ooty from "../../assets/images/ooty.png";
import paris from "../../assets/images/paris.jfif";
import portugal from "../../assets/images/portugal.png";
import rajasthan from "../../assets/images/rajasthan.png";
import safari from "../../assets/images/safari.jfif";
import singapour from "../../assets/images/singapour.jfif";
import surfing from "../../assets/images/surfing.jfif";
import trek from "../../assets/images/trek.jfif";
import vietnum from "../../assets/images/vietnum.jfif";
import testimonial1 from "../../assets/images/testimonial1.jpeg";
import testimonial2 from "../../assets/images/testimonial2.jpeg";
import testimonial3 from "../../assets/images/testimonial3.jpeg";
import logo_red from "../../assets/images/logo_red.png";
import phone from "../../assets/images/icons/phone.png";
import whatsapp from "../../assets/images/icons/whatsapp.png";
import email from "../../assets/images/icons/email.png";
import fb from "../../assets/images/icons/fb.png";
import insta from "../../assets/images/icons/insta.png";
import linkedin from "../../assets/images/icons/linkedin.png";
import twitter from "../../assets/images/icons/twitter.png";
import youtube from "../../assets/images/icons/youtube.png";
import travel1 from "../../assets/images/travel1.png";
import maldive2 from "../../assets/images/decor/maldives.gif";
import maldive3 from "../../assets/images/decor/maldives2.gif";
import maldive4 from "../../assets/images/decor/maldives3.gif";
import maldive5 from "../../assets/images/decor/maldives4.gif";
import man from "../../assets/images/icons/man.png";
import Ice from "../../assets/images/icons/Ice.png";
import Sun_Cloud from "../../assets/images/icons/Sun-Cloud.png";
import Sun from "../../assets/images/icons/Sun.png";
import Cloud from "../../assets/images/icons/Cloud.png";
import honeymoon from "../../assets/images/honeymoon.jfif";
import leisure from "../../assets/images/leisure.jfif";
import business from "../../assets/images/business.png";
import bachelor from "../../assets/images/bachelor.png";
import holiday2 from "../../assets/images/holiday.png";
import Barcelona from "../../assets/images/Barcelona.jfif";
import lisbon from "../../assets/images/lisbon.jfif";
import prague from "../../assets/images/prague.jfif";
import seville from "../../assets/images/seville.jfif";
import Barcelona2 from "../../assets/images/Barcelona2.jfif";
import whatsapp2 from "../../assets/images/icons/whatsapp2.png";
import whatsapp_text from "../../assets/images/icons/whatsapp_text.png";
import secure from "../../assets/images/icons/secure.png";
import guide from "../../assets/images/icons/guide.png";
import dinner from "../../assets/images/icons/dinner.png";
import tent from "../../assets/images/icons/tent.png";
import hotel1 from "../../assets/images/icons/hotel1.png";
import hotel2 from "../../assets/images/icons/hotel2.png";
import hotel3 from "../../assets/images/icons/hotel3.png";
import hotel4 from "../../assets/images/icons/hotel4.png";
import hotel5 from "../../assets/images/icons/hotel5.png";
import woman_walking from "../../assets/images/woman_walking.png";
import support from "../../assets/images/icons/support.png";
import about from "../../assets/images/about.png";
import destination from "../../assets/images/icons/destination.png";
import tour_bus from "../../assets/images/icons/tour_bus.png";
import hotl_star from "../../assets/images/icons/hotl_star.png";
import assistance from "../../assets/images/icons/assistance.png";
import team from "../../assets/images/team.jfif";
import team2 from "../../assets/images/team2.jfif";
import card_payment from "../../assets/images/card_payment.png";
import bg1 from "../../assets/images/decor/bg1.jpg";
import login from "../../assets/images/login.png";
import flight2 from "../../assets/images/flight.jfif";
import flight_bg from "../../assets/images/decor/3.jpg";
import profile_itinery from "../../assets/images/profile_itinery.png";
import tour_guide from "../../assets/images/icons/tour_guide.png";
import community from "../../assets/images/icons/community.png";
import faq from "../../assets/images/icons/faq.png";
import identity from "../../assets/images/icons/identity.png";
import occupation from "../../assets/images/icons/occupation.png";
import financial from "../../assets/images/icons/financial.png";
import vocher from "../../assets/images/icons/vocher.png";
import pickup from "../../assets/images/icons/pickup.png";
import sight_seeing from "../../assets/images/icons/sight_seeing.png";
import massage from "../../assets/images/icons/massage.png";
import bar from "../../assets/images/icons/bar.png";
import bath from "../../assets/images/icons/bath.png";
import wifi from "../../assets/images/icons/wifi.png";
import lunch from "../../assets/images/icons/lunch.png";
import coffee from "../../assets/images/icons/coffee.png";
import dryer from "../../assets/images/icons/dryer.png";
import phone_2 from "../../assets/images/icons/phone (2).png";
import tv from "../../assets/images/icons/tv.png";
import ac from "../../assets/images/icons/ac.png";
import flight_info from "../../assets/images/flight_info.png";
import car from "../../assets/images/car.png";
import slide6 from "../../assets/images/slides/slide6.jpg";
import slide7 from "../../assets/images/slides/slide7.jpg";
import profile_page from "../../assets/images/profile_page.jpg";
import bike_banner_bg from "../../assets/images/bike_banner_bg.jpg";
import login_icon from "../../assets/images/icons/login_icon.png";
import mobile_plane from "../../assets/images/mobile_plane.png";

export const images = {
  logo,
  logo_red,
  slide1,
  Ice,
  honeymoon,
  pickup,
  fb,
  woman_walking,login_icon,
  sight_seeing,mobile_plane,
  identity,slide6,
  flight2,
  massage,
  tour_guide,slide7,
  occupation,
  bar,
  whatsapp2,bike_banner_bg,
  financial,
  secure,
  bath,
  Barcelona,
  community,car,
  vocher,profile_page,
  flight_bg,flight_info,
  wifi,
  team2,
  destination,
  profile_itinery,
  lunch,
  faq,
  guide,
  coffee,
  hotel1,
  bg1,
  guide,
  dryer,
  whatsapp_text,
  team,
  phone_2,
  tour_bus,
  card_payment,
  tv,
  support,
  hotel2,
  leisure,
  ac,
  about,
  hotel3,
  travel1,
  dinner,
  slide4,
  hotl_star,
  hotel4,
  assistance,
  lisbon,
  hotel5,
  man,
  business,
  prague,
  tent,
  Sun_Cloud,
  phone,
  seville,
  bachelor,
  insta,
  Sun,
  Barcelona2,
  holiday2,
  brand1,
  maldive2,
  linkedin,
  award1,
  Cloud,
  twitter,
  brand2,
  whatsapp,
  youtube,
  beach,
  email,
  brand3,
  bike,
  brand4,
  award2,
  brand5,
  login,
  best_price,
  award3,
  transparency,
  slide5,
  award4,
  slide2,
  award5,
  flight,
  award6,
  concierge,
  award7,
  slide3,
  award8,
  holiday,
  expert,
  hotel,
  camp,
  cycling,
  dubai,
  egypt,
  europe,
  france,
  maldive4,
  goa,
  goa2,
  greece,
  italy,
  jammu,
  maldive5,
  maldive3,
  maldive,
  manali,
  maritius,
  maritius2,
  ooty,
  paris,
  portugal,
  rajasthan,
  safari,
  singapour,
  surfing,
  trek,
  vietnum,
  testimonial1,
  testimonial2,
  testimonial3,
};
