import axios from "./axios.service";
import { url } from "./url";

export const getHotelsByDestination = async (obj) => {
  return axios.post(`${url}/hotel/getHotelsFromDb`, obj);
};

export const getHotelById = async (id) => {
  return axios.get(`${url}/hotel/searchHotelFromApiByHotelId/${id}`);
};

export const checkHotelBookingPreBook = async (obj) => {
  return axios.post(`${url}/hotel/checkHotelBeforeBooking`, obj);
};

export const createHotelBooking = async (obj) => {
  return axios.post(`${url}/hotel/newHotelBooking`, obj);
};
