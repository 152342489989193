import { Rating } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import React, { useEffect, useState } from "react";
import { BiBed } from "react-icons/bi";
import { BsBuilding, BsCalendarWeek, BsFillStarFill } from "react-icons/bs";
import { FaHotel, FaQuoteLeft } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdFamilyRestroom } from "react-icons/md";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { checkHotelBookingPreBook, getHotelById } from "../services/hotels";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import { images } from "./Utility/Images";
import moment from "moment";
import { toastError } from "../utils/toastUtils";
function HotelDetail() {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [hotelObj, setHotelObj] = useState({});
  const [sessionId, setSessionId] = useState("");
  const [roomsArr, setRoomsArr] = useState([]);
  const getHotelDetail = async () => {
    try {
      const { data: res } = await getHotelById(searchParams.get("hotelId"));
      if (res) {
        console.log(res.data);
        setHotelObj(res.data);
        setSelectedRoomObj(res.data.roomsArr[0]);
        let tempRoomsArr = res.data.roomsArr;
        let tempFinalRoomArr = [];
        for (let i = 0; i <= tempRoomsArr.length - 1; i++) {
          if (!tempFinalRoomArr.some((el) => el.name == tempRoomsArr[i].name)) {
            tempFinalRoomArr.push({ ...tempRoomsArr[i], active: false });
          }
        }
        setRoomsArr([...tempFinalRoomArr]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // usage example:
  const getUniqueElements = (arr) => {
    console.log([...new Set(arr.map((item) => item))]);
    return [...new Set(arr.map((item) => item.name))];
  };

  useEffect(() => {
    getHotelDetail();
  }, []);

  const date_rate = {
    0: {
      slidesPerView: 1,
    },
    1000: {
      slidesPerView: 3,
    },
  };

  const [filterTabs, setFilterTabs] = useState([
    {
      name: "Standard",
      icon: <BiBed />,
      active: true,
      tab: "1",
    },
    {
      name: "Deluxe",
      icon: <FaHotel />,
      active: false,
      tab: "2",
    },
    {
      name: "Studio",
      icon: <BsBuilding />,
      active: false,
      tab: "3",
    },
  ]);

  const handleCheckPreBook = async () => {
    try {
      console.log("asdsad", selectedRoomObj);
      if (selectedRoomObj?.BookingKey) {
        let obj = {
          arrivalDate: "29/01/2023",
          departureDate: "31/01/2023",
          countryCode: hotelObj?.countryCode,
          cityCode: hotelObj?.cityCode,
          hotelId: searchParams.get("hotelId"),
          roomName: selectedRoomObj?.name,
          city: hotelObj?.cityCode,
          hotelname: hotelObj?.name,
          bookingKey: selectedRoomObj?.BookingKey,
          roomPrice: selectedRoomObj?.price,
        };
        const { data: res } = await checkHotelBookingPreBook(obj);
        if (res) {
          console.log(res);
          if (res.data.sessionId == "") {
            alert("Invalid Session Id");
          } else {
            console.log(res);
            obj.sessionId = res.data.sessionId;
            setSessionId(res.data.sessionId);
            navigate(`/Checkout?pay=${encodeURIComponent(JSON.stringify(obj))}`);
          }
        } else {
          alert("Please Select Room");
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  const [monthValue, setMonthValue] = useState([new Date(), new Date()]);
  const [showCalender, setShowCalender] = useState(false);
  const [roomRating, setRoomRating] = useState(3);
  const [selectedRoomObj, setSelectedRoomObj] = useState(null);
  const activeTab = (i) => {
    const temp = roomsArr.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setRoomsArr([...temp]);
    setSelectedRoomObj(roomsArr[i]);
  };

  const handleDateChange = (value) => {
    setMonthValue(value);
  };

  return (
    <>
      <Header />
      <main>
        <section className="hotel-slider">
          <Swiper slidesPerView={2} spaceBetween={16} loop speed={2000} modules={[Autoplay]} autoplay={{ disableOnInteraction: false }}>
            <SwiperSlide>
              <div className="hotel-slider-box">
                <img src={hotelObj?.imageUrl} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="hotel-slider-box">
                <img src={hotelObj?.imageUrl} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </section>

        <section className="trip-info mb-80 py-4">
          <div className="container">
            <ul>
              <li>
                <h4>
                  <div className="icon">
                    <BsFillStarFill />
                  </div>
                  Reviews
                </h4>
                <p>{hotelObj?.rating} Star</p>
              </li>
              <li>
                <h4>
                  <div className="icon">
                    <MdFamilyRestroom />
                  </div>
                  Max. Guests
                </h4>
                <p>
                  {selectedRoomObj?.noOfAdults} Adults/ {selectedRoomObj?.Children ? selectedRoomObj?.Children : 0} Children
                </p>
              </li>
              <li>
                <h4>
                  <div className="icon">
                    <BiBed />
                  </div>
                  Bed Type
                </h4>
                <p>Twins Bed</p>
              </li>
              {/* <li>
                <h4>
                  <div className="icon">
                    <BiArea />
                  </div>
                  Area
                </h4>
                <p>
                  80 m<sup>2</sup>
                </p>
              </li> */}
            </ul>
          </div>
        </section>

        <section className="trip-desp hotel-despcription mb-80 px-4pc">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="title-section mb-2">
                  <h2 className="heading mb-0">{selectedRoomObj && selectedRoomObj?.name}</h2>
                  <p className="price d-flex mb-0">
                    <span className="text-dark">Starts from</span>
                    <h3 className="red mb-0">&nbsp;₹{parseInt(selectedRoomObj?.price)}</h3>
                    <span className="text-muted small">/Night</span>
                  </p>
                </div>
                <p>{hotelObj?.description}</p>
                {/* <p></p> */}

                <h4 className="mb-4">Room Categorise</h4>
                <ul className="filter-tabs mb-5">
                  {roomsArr &&
                    roomsArr.length &&
                    roomsArr.map((item, i) => {
                      return (
                        <li key={i}>
                          <button className={`btn ${item.active ? "active" : ""}`} onClick={() => activeTab(i)}>
                            <div className="icon">{item.icon}</div>
                            {item.name}
                          </button>
                        </li>
                      );
                    })}
                </ul>

                <h4 className="my-4">Additional Services</h4>
                <p>{hotelObj?.HotelAmenities}</p>

                <h4 className="my-4">Room Services</h4>

                <ul className="row  gy-3">
                  {hotelObj &&
                    hotelObj?.RoomAmenities != "" &&
                    hotelObj?.RoomAmenities?.split(",").map((el, i) => {
                      return (
                        <div className="col-12 col-md-4" key={i}>
                          <div className="service-btn btn">
                            {/* <img src={images.bar} alt="" /> */}
                            <p className="mb-0">{el}</p>
                          </div>
                        </div>
                      );
                    })}
                </ul>
              </div>
              <div className="col-12 col-md-4">
                <div className="trip-price-detail style-2 mb-4">
                  <div className="top">
                    <p>
                      {moment(monthValue[0]).format("DD MMM")} - {moment(monthValue[1]).format("DD MMM")}, {selectedRoomObj?.noOfAdults} Guests, 1 Room
                      <span className="red ms-2">
                        <FiEdit />
                      </span>
                    </p>
                  </div>
                  <div className="box">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mb-0">Check In - Check Out</h6>
                      <h5 onClick={() => setShowCalender(!showCalender)} className="red pointer" onChange={(value) => handleDateChange(value)}>
                        <BsCalendarWeek />
                      </h5>
                      {showCalender && (
                        <div className="date-picker-module">
                          <DateRangePicker onChange={handleDateChange} value={monthValue} isOpen={showCalender} onCalendarClose={() => setShowCalender(!showCalender)} />
                        </div>
                      )}
                    </div>
                  </div>
                  <ul className="box">
                    <li>
                      <span>Adults</span>
                      {selectedRoomObj?.noOfAdults}
                    </li>
                    <li>
                      <span>Children</span>
                      {selectedRoomObj?.Children ? selectedRoomObj?.Children : "0"}
                    </li>
                  </ul>
                  <ul className="box">
                    <li>
                      <span>Guests</span>
                      {parseInt(selectedRoomObj?.noOfAdults) + parseInt(selectedRoomObj?.Children)}
                    </li>
                  </ul>
                  <ul className="box">
                    <li>
                      <span>Trip Cost</span>₹{parseInt(selectedRoomObj?.price)}
                    </li>
                    <li>
                      <span>GST</span>₹0
                    </li>
                    <li>
                      <span>TCS</span>₹0
                    </li>
                    <li>
                      <span>Payable Amount</span>
                      <span className="total text-success fw-bold">₹{parseInt(selectedRoomObj?.price)}</span>
                    </li>
                  </ul>
                  <div className="text-center my-4">
                    <button onClick={() => handleCheckPreBook()} className="btn red-bg btn-custom me-2">
                      Proceed To Book Online
                    </button>
                    {/* <Link to="/Checkout" >
                    </Link> */}
                    <Link to="/" className="btn red-bg btn-custom">
                      Edit
                    </Link>
                  </div>
                </div>
                <iframe
                  src={`https://maps.google.com/maps?q=${hotelObj?.Latitude},${hotelObj?.Longitude}&hl=es&z=14&amp;output=embed`}
                  width="100%"
                  height="350"
                  allowFullScreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
                {/* <iframe width="300" height="170" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={`https://maps.google.com/maps?q=${hotelObj?.Latitude},${hotelObj?.Longitude}&hl=es&z=14&amp;output=embed`}></iframe> */}
                <br />
                {/* <small>
                  <a src={`https://maps.google.com/maps?q='+${hotelObj?.Latitude}+','+${hotelObj?.Longitude}+'&hl=es&z=14&amp;output=embed`} style="color:#0000FF;text-align:left" target="_blank">
                    See map bigger
                  </a>
                </small> */}

                {/* <iframe
                  width="300"
                  height="170"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src={`https://maps.google.com/maps?q='+${hotelObj?.Latitude}+','+${hotelObj?.Longitude}+'&hl=es&z=14&amp;output=embed`}
                ></iframe> */}
              </div>
            </div>
          </div>
        </section>

        <section className="similar-room mb-80 px-4pc">
          <div className="container-fluid">
            <div className="title-section mb-5">
              <h2>Similar Rooms</h2>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.holiday2} alt="" />
                    </Link>
                  </div>
                  <div className="content">
                    <h4>
                      <Link to="/">Hotel of Sun</Link>
                    </h4>
                    <h5 className="green fw-bold">
                      ₹1,839
                      <span className="small text-muted fw-normal">/Night</span>
                    </h5>
                    <Rating name="read-only" size="small" value={roomRating} readOnly />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.holiday2} alt="" />
                    </Link>
                  </div>
                  <div className="content">
                    <h4>
                      <Link to="/">Hotel of Sun</Link>
                    </h4>
                    <h5 className="green fw-bold">
                      ₹1,839
                      <span className="small text-muted fw-normal">/Night</span>
                    </h5>
                    <Rating name="read-only" size="small" value={roomRating} readOnly />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.holiday2} alt="" />
                    </Link>
                  </div>
                  <div className="content">
                    <h4>
                      <Link to="/">Hotel of Sun</Link>
                    </h4>
                    <h5 className="green fw-bold">
                      ₹1,839
                      <span className="small text-muted fw-normal">/Night</span>
                    </h5>
                    <Rating name="read-only" size="small" value={roomRating} readOnly />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.holiday2} alt="" />
                    </Link>
                  </div>
                  <div className="content">
                    <h4>
                      <Link to="/">Hotel of Sun</Link>
                    </h4>
                    <h5 className="green fw-bold">
                      ₹1,839
                      <span className="small text-muted fw-normal">/Night</span>
                    </h5>
                    <Rating name="read-only" size="small" value={roomRating} readOnly />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5 mb-80 px-4pc">
          <div className="container-fluid">
            <div className="title-section mb-5">
              <h2>Room Reviews</h2>
            </div>
            <div className="row gy-4">
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img src={images.testimonial1} alt="" className="h-100 w-100" />
                      </div>
                      <div className="name">
                        <h5>Anjali Nayak</h5>
                        <Rating name="read-only" value={roomRating} readOnly />
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was super helpful and helped us understand the destination, properties and curated such an amazing tour for us. We can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img src={images.testimonial2} alt="" className="h-100 w-100" />
                      </div>
                      <div className="name">
                        <h5>Anjali Nayak</h5>
                        <Rating name="read-only" value={roomRating} readOnly />
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was super helpful and helped us understand the destination, properties and curated such an amazing tour for us. We can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img src={images.testimonial3} alt="" className="h-100 w-100" />
                      </div>
                      <div className="name">
                        <h5>Anjali Nayak</h5>
                        <Rating name="read-only" value={roomRating} readOnly />
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was super helpful and helped us understand the destination, properties and curated such an amazing tour for us. We can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img src={images.testimonial1} alt="" className="h-100 w-100" />
                      </div>
                      <div className="name">
                        <h5>Anjali Nayak</h5>
                        <Rating name="read-only" value={roomRating} readOnly />
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was super helpful and helped us understand the destination, properties and curated such an amazing tour for us. We can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img src={images.testimonial2} alt="" className="h-100 w-100" />
                      </div>
                      <div className="name">
                        <h5>Anjali Nayak</h5>
                        <Rating name="read-only" value={roomRating} readOnly />
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was super helpful and helped us understand the destination, properties and curated such an amazing tour for us. We can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="testimonials-style-3-box">
                  <div className="right">
                    <div className="d-flex align-items-center gap-3">
                      <div className="image left">
                        <img src={images.testimonial3} alt="" className="h-100 w-100" />
                      </div>
                      <div className="name">
                        <h5>Anjali Nayak</h5>
                        <Rating name="read-only" value={roomRating} readOnly />
                      </div>
                    </div>
                    <div className="content mt-4">
                      <p>
                        <FaQuoteLeft className="red fs-5 me-2" />
                        We booked our Maldives Honeymoon through Deyor. Aman was super helpful and helped us understand the destination, properties and curated such an amazing tour for us. We can't wait to travel with Deyor again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default HotelDetail;
