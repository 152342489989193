import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../Utility/Images";
import Modal from "react-modal";

function Footer() {
  // ========================================================================
  const [modalIsOpen, setIsOpen] = useState(false);






  // ====================================================================
  return (
    <>
      <footer>
        <div className="footer px-4pc">
          <div className="container-fluid">
            <div className="row gy-3">
              <div className="col-12">
                <div className="logo">
                  <div className="line"></div>
                  <img src={images.logo_red} alt="" className="main-logo" />
                  <div className="line"></div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xxl-4">
                <div className="footer-inner">
                  <h4 className="heading">About</h4>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur ut diam et nibh condimentum venenatis eu ac
                    magnasin. Quisque interdum est mauris, eget ullamcorper.
                  </p>
                  <ul className="social-links">
                    <li>
                      <Link to="">
                        <img src={images.fb} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img src={images.insta} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img src={images.youtube} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img src={images.linkedin} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img src={images.twitter} alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xxl-2">
                <div className="footer-inner">
                  <h4 className="heading">Quick Links</h4>
                  <ul className="links">
                    <li>
                      <Link to="/About">About us</Link>
                    </li>
                    <li>
                      <Link to="/Careers">Careers</Link>
                    </li>
                    <li>
                      <Link to="/Blogs">Blogs</Link>
                    </li>
                    <li>
                      <Link to="/Testimonials">Testimonials</Link>
                    </li>
                    <li>
                      <Link to="/Visa">Visa</Link>
                    </li>
                    <li>
                      <Link to="/Press">Press</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xxl-3">
                <div className="footer-inner">
                  <h4 className="heading">Policy</h4>
                  <ul className="links">
                    <li>
                      <Link to="/Frequently-Asked-Questions">
                        FAQs
                      </Link>
                    </li>
                    <li>
                      <Link to="/Term-and-Condition">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/PrivacyPolicy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3 col-xxl-3">
                <div className="footer-inner">
                  <h4 className="heading">Talk To Us</h4>
                  <ul className="links details">
                    <li>
                      <div className="icon">
                        <img src={images.phone} alt="" />
                        <a href="tel:4455667788">4455667788</a>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={images.whatsapp} alt="" />
                        <a
                          href="https://api.whatsapp.com/send?phone=919289370407"
                          target="_blank"
                        >
                          5566223388
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={images.email} alt="" />
                        <a href="mailto:info@aahilya.com">info@aahilya.com</a>
                      </div>
                    </li>
                    <li>
                      <label>Sign Up for travel ideas!</label>
                      <div className="d-xl-flex gap-2 form">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your Email"
                        />
                        <button className="btn red-bg btn-custom rounded btn-sm mt-2 mt-xl-0">
                          Submit
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container-fluid text-center">
            <p>
              Copyright © 2022 DEYOR - Designed by
              <a href="ebslon.com">&nbsp;Ebslon Infotech Pvt Ltd</a>.
            </p>
          </div>
        </div>
      </footer>

      <div className="whatsapp">
        {/* <button
          className="expert-talk border-0 bg-transparent p-0"
          onClick={() => setIsOpen(true)}
        >
          <img src={images.support} alt="" />
        </button> */}
        <div className="position-relative">
          <a
            href="https://api.whatsapp.com/send?phone=919289370407"
            target="_blank"
          >
            <img src={images.whatsapp2} alt="" className="icon" />
            <img src={images.whatsapp_text} alt="" className="text" />
          </a>
        </div>
      </div>

  

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        className="custom-modal"
        bodyOpenClassName="custom-modal-body"
        overlayClassName="custom-modal-overlay"
        htmlOpenClassName="custom-modal-html"
        portalClassName="custom-modal-parent"
        contentLabel="Example Modal"
      >
        <div className="talk-to-expert">
          <div className="title-section mb-3">
            <h4 className="heading mb-1">Talk to an epert !!!</h4>
            <p>Get in touch with us, we are available 24 X 7.</p>
          </div>
          <form action="/" className="form row">
            <div className="col-12">
              <label>Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-12">
              <label>E-mail</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-12">
              <label>Phone No.</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-12">
              <label>Message</label>
              <textarea rows="3" className="form-control"></textarea>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-custom red-bg px-4">
                Send Enquiry
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default Footer;
