import React from "react";
import { images } from "./Utility/Images";

function Gallery() {
  return (
    <>
      <section className="blog account-gallery-video mb-80">
        <div className="container">
          <div className="title-section mb-4">
            <h2>Featured Video</h2>
          </div>
          <div className="row gx-3">
            <div className="col-12 col-md-8">
              <div className="blog-box h-100">
                <div className="image h-100">
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/YmoXPNxCplo"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="blog-box mb-3">
                <div className="image">
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/J5bKP0WEe5U"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div className="blog-box">
                <div className="image">
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/GYRB0687-xM"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog account-gallery-post mb-80">
        <div className="container">
          <div className="title-section mb-4">
            <h2>Our Latest Posts</h2>
          </div>
          <div className="row gy-5">
            <div className="col-12 col-md-4">
              <div className="blog-box">
                <div className="image">
                  <img src={images.testimonial3} alt="" />
                </div>
                <div className="content">
                  <h5>
                    Integer turpis ligula, tempor a libero in, pharetra aliquet
                    dolor.
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                    laoreet convallis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="blog-box">
                <div className="image">
                  <img src={images.goa} alt="" />
                </div>
                <div className="content">
                  <h5>
                    Integer turpis ligula, tempor a libero in, pharetra aliquet
                    dolor.
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                    laoreet convallis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="blog-box">
                <div className="image">
                  <img src={images.holiday2} alt="" />
                </div>
                <div className="content">
                  <h5>
                    Integer turpis ligula, tempor a libero in, pharetra aliquet
                    dolor.
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                    laoreet convallis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="blog-box">
                <div className="image">
                  <img src={images.testimonial3} alt="" />
                </div>
                <div className="content">
                  <h5>
                    Integer turpis ligula, tempor a libero in, pharetra aliquet
                    dolor.
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                    laoreet convallis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="blog-box">
                <div className="image">
                  <img src={images.goa} alt="" />
                </div>
                <div className="content">
                  <h5>
                    Integer turpis ligula, tempor a libero in, pharetra aliquet
                    dolor.
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                    laoreet convallis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="blog-box">
                <div className="image">
                  <img src={images.holiday2} alt="" />
                </div>
                <div className="content">
                  <h5>
                    Integer turpis ligula, tempor a libero in, pharetra aliquet
                    dolor.
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                    laoreet convallis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="blog-box">
                <div className="image">
                  <img src={images.testimonial3} alt="" />
                </div>
                <div className="content">
                  <h5>
                    Integer turpis ligula, tempor a libero in, pharetra aliquet
                    dolor.
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                    laoreet convallis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="blog-box">
                <div className="image">
                  <img src={images.goa} alt="" />
                </div>
                <div className="content">
                  <h5>
                    Integer turpis ligula, tempor a libero in, pharetra aliquet
                    dolor.
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                    laoreet convallis.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Gallery;
