// searchCityByName
import axios from "axios";
import { url } from "./url";
let serverlUrl = `${url}/Itinerary`;

export const generateItinerary = async (obj) => {
    return axios.post(`${serverlUrl}/`, obj);
};
export const generateItineraryForHotelApiDisabled = async (obj) => {
    return axios.post(`${serverlUrl}/generateItineraryForHotelApiDisabled`, obj);
};



export const getItineraryById = async (id) => {
    return axios.get(`${serverlUrl}/getItineraryById/${id}`)
}
export const getAlternateFlightsForItinerary = async (id) => {
    return axios.get(`${serverlUrl}/getAlternateFlightsForItinerary/${id}`)
}
export const downloadPdf = async (id) => {
    window.open(`${serverlUrl}/downloadPdf/${id}`)
}

export const getActivitiesForItinerary = async (id) => {
    return axios.get(`${serverlUrl}/getActivitiesForItinerary/${id}`)
}
export const getHotelsForItinerary = async (id) => {
    return axios.get(`${serverlUrl}/getHotelsForItinerary/${id}`)
}
export const updateItinerary = async (obj, id) => {
    return axios.patch(`${serverlUrl}/updateItineraryById/${id}`, obj)
}