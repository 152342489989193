export const calculateFinalPrice = (flightObj, datesArr, isHotelApiDisabled) => {
    let tempFlightPrice = parseInt(flightObj?.Fare);
    let hotelPrice = 0;
    let activityPricing = 0;
    let roomPrice = 0

    if (isHotelApiDisabled) {
        for (let i = 0; i <= datesArr.length; i++) {
            if (datesArr[i]?.hotelObj) {
                hotelPrice += parseInt(datesArr[i].roomObj?.adultPrice)
            }
        }
    }
    else {

        hotelPrice = 0;
        for (let i = 0; i <= datesArr.length; i++) {
            if (datesArr[i]?.hotelObj) {
                hotelPrice += parseInt(datesArr[i].hotelObj?.price)
            }
        }


        activityPricing = 0
        for (let i = 0; i <= datesArr.length; i++) {
            if (datesArr[i]?.activityObj) {
                if (datesArr[i]?.activityObj?.acitivityPriceObj?.priceFor1) {
                    console.log(parseInt(datesArr[i]?.activityObj?.acitivityPriceObj?.priceFor1))
                    activityPricing += parseInt(datesArr[i]?.activityObj?.acitivityPriceObj?.priceFor1)
                }
            }
        }
    }

    return tempFlightPrice + hotelPrice + activityPricing + roomPrice




}