import React from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <main>
        <section className="privacy-policy mb-80 border-top pt-3">
          <div className="container">
            <div className="title-section bg mb-4">
              <h1>Privacy Policy</h1>
            </div>
            <p>
              Thank you for joining the most efficiently growing online Travel
              Community. We at Deyor Adventures Private Limited (“Deyor”, “we”,
              “us”) respect your privacy and want you to understand how we
              collect, use, and share data about you. This Privacy Policy covers
              our data collection practices and describes your rights to access,
              correct, or restrict our use of your personal data.
            </p>
            <p>
              Unless we link to a different policy or state otherwise, this
              Privacy Policy applies when you visit or use the Deyor website,
              mobile applications, APIs or related services (the “Services”).
            </p>
            <p>
              By using the Services, you agree to the terms of this Privacy
              Policy. You shouldn’t use the Services if you don’t agree with
              this Privacy Policy or any other agreement that governs your use
              of the Services.
            </p>
            <h4>Contents</h4>
            <p>
              Unless we link to a different policy or state otherwise, this
              Privacy Policy applies when you visit or use the Deyor website,
              mobile applications, APIs or related services (the “Services”).
            </p>
            <p>
              By using the Services, you agree to the terms of this Privacy
              Policy. You shouldn’t use the Services if you don’t agree with
              this Privacy Policy or any other agreement that governs your use
              of the Services.
            </p>

            <h5>What Do We Use Your Data ? </h5>
            <p>
              Thank you for joining the most efficiently growing online Travel
              Community. We at Deyor Adventures Private Limited (“Deyor”, “we”,
              “us”) respect your privacy and want you to understand how we
              collect, use, and share data about you. This Privacy Policy covers
              our data collection practices and describes your rights to access,
              correct, or restrict our use of your personal data.
            </p>
            <p>
              Unless we link to a different policy or state otherwise, this
              Privacy Policy applies when you visit or use the Deyor website,
              mobile applications, APIs or related services (the “Services”).
            </p>
            <p>
              By using the Services, you agree to the terms of this Privacy
              Policy. You shouldn’t use the Services if you don’t agree with
              this Privacy Policy or any other agreement that governs your use
              of the Services.
            </p>     <p>
              Unless we link to a different policy or state otherwise, this
              Privacy Policy applies when you visit or use the Deyor website,
              mobile applications, APIs or related services (the “Services”).
            </p>
            <p>
              By using the Services, you agree to the terms of this Privacy
              Policy. You shouldn’t use the Services if you don’t agree with
              this Privacy Policy or any other agreement that governs your use
              of the Services.
            </p>     
            <p>
              Thank you for joining the most efficiently growing online Travel
              Community. We at Deyor Adventures Private Limited (“Deyor”, “we”,
              “us”) respect your privacy and want you to understand how we
              collect, use, and share data about you. This Privacy Policy covers
              our data collection practices and describes your rights to access,
              correct, or restrict our use of your personal data.
            </p>
            <p>
              Unless we link to a different policy or state otherwise, this
              Privacy Policy applies when you visit or use the Deyor website,
              mobile applications, APIs or related services (the “Services”).
            </p>
            <p>
              By using the Services, you agree to the terms of this Privacy
              Policy. You shouldn’t use the Services if you don’t agree with
              this Privacy Policy or any other agreement that governs your use
              of the Services.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
