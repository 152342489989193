import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import PageBanner from "./PageBanner";
import { images } from "./Utility/Images";

function Blogs() {
  return (
    <>
      <Header />
      <main>
        <PageBanner
          banner3
          img1={images.slide4}
          className="page-banner-3"
          title="Deyor’s Pad"
        />

        <ul className="filter-tabs mt-5">
          <li>
            <a className="btn" href="#go-to-blog">
              Blogs
            </a>
          </li>
          <li>
            <a className="btn" href="#go-to-video">
              Featured Video
            </a>
          </li>
          <li>
            <a className="btn" href="#go-to-trends">
              Latest Trend
            </a>
          </li>
        </ul>

        <section className="blog mt-5 mb-80 px-4pc" id="go-to-blog">
          <div className="container-fluid">
            <div className="title-section bg mb-5 w-100">
              <h1>Our Latest Blog Posts</h1>
            </div>
            <div className="row gy-5">
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.holiday2} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.testimonial3} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.goa} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.holiday2} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.testimonial3} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.goa} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.holiday2} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.testimonial3} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.goa} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.holiday2} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.testimonial3} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.goa} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog mb-80 px-4pc" id="go-to-video">
          <div className="container-fluid">
            <div className="title-section bg mb-5 w-100">
              <h1>Featured Video</h1>
            </div>
            <div className="row gy-5">
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="blog-box">
                  <div className="image">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/YmoXPNxCplo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="trending-blog mb-80" id="go-to-trends">
          <div className="container">
            <div className="title-section bg text-center w-100 mb-4">
              <h1 className="heading fw-semibold">Our Trending Blog</h1>
            </div>
            <div
              className="row align-items-center g-0"
              style={{ backgroundColor: "#FAEDED" }}
            >
              <div className="col-12 col-md-6">
                <div className="content">
                  <h4 className="mb-3">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis
                    nisl ut aliquip ex ea commodo consequat.
                  </p>
                  <Link to="/Blog-Detail" className="btn red-bg btn-custom">
                    Know More
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="image">
                  <img src={images.dubai} alt="" className="h-100 w-100" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Blogs;
