import React, { useEffect, useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
import { images } from "./Utility/Images";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import SliderTabs from "./SliderTabs";
import MainSlider from "./MainSlider";
import { BsSearch } from "react-icons/bs";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { MdFlightTakeoff } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";

function Index() {
  // ==============================================================================================================================
  const navigate = useNavigate();
  const [getWindow, setGetWindow] = useState(window.innerWidth);
  const [choosePlace, setchoosePlace] = useState([
    {
      name: "Holidays",
      icon: images.holiday,
      active: false,
      value: "holiday",
    },
    {
      name: "Bike Trips",
      icon: images.bike,
      active: false,
      value: "bike",
    },
    {
      name: "Flights",
      icon: images.flight,
      active: false,
      value: "flight",
    },
    {
      name: "Hotels",
      icon: images.hotel,
      active: false,
      value: "hotel",
    },
    {
      name: "login",
      icon: images.login_icon,
      active: false,
      value: "login",
    },
  ]);

  const adventures = {
    0: {
      slidesPerView: 3,
    },
    500: {
      slidesPerView: 3,
    },
    650: {
      slidesPerView: 4,
    },
    850: {
      slidesPerView: 5,
    },
    1000: {
      slidesPerView: 6,
    },
    1200: {
      slidesPerView: 5,
    },
    // 1500: {
    //   slidesPerView: 6,
    // },
  };
  const about_tour = {
    0: {
      slidesPerView: 1,
    },
    1000: {
      slidesPerView: 2,
    },
    1600: {
      slidesPerView: 3,
    },
  };
  const clients = {
    0: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 4,
    },
    900: {
      slidesPerView: 5,
    },
    1000: {
      slidesPerView: 6,
    },
    1400: {
      slidesPerView: 7,
    },
  };

  const tabClick = (i, value) => {
    let temp = choosePlace.map((item, index) => {
      if (i === index) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
    setchoosePlace([...temp]);
    if (value === "holiday") {
      navigate("/Questions");
    }
    if (value === "bike") {
      navigate("/Questions");
    }
    if (value === "flight") {
      navigate("/Flight");
    }
    if (value === "hotel") {
      navigate("/Hotel");
    }
    if (value === "login") {
      navigate("/SignIn");
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", () => setGetWindow(getWindow));
    setGetWindow(getWindow);
    return () =>
      window.addEventListener("resize", () => setGetWindow(getWindow));
  }, []);

  // ==============================================================================================================================

  return (
    <>
      <Header className="change-header" />
      <main>
        <MainSlider />

        <section className="best-off mt-80 mb-80">
          <div className="container">
            <div className="title-section mb-2 mb-sm-4">
              <h2 className="heading">Bike Trips</h2>
            </div>
            <div className="row gx-2 gy-2 gx-sm-4 gy-sm-4 gy-lg-0">
              <div className="col-6 col-sm-6 col-lg-3">
                <div className="best-off-box m-0">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.france} alt="" />
                    </div>
                    <div className="content">
                      <p>Create Memories in </p>
                      <h4>France</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-lg-3">
                <div className="best-off-box m-0">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.greece} alt="" />
                    </div>
                    <div className="content">
                      <p>Create Memories in </p>
                      <h4>Greece</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-lg-3">
                <div className="best-off-box m-0">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.italy} alt="" />
                    </div>
                    <div className="content">
                      <p>Create Memories in </p>
                      <h4>Italy</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-lg-3">
                <div className="best-off-box m-0">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.portugal} alt="" />
                    </div>
                    <div className="content">
                      <p>Create Memories in </p>
                      <h4>Portugal</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="best-off mb-80">
          <div className="container">
            <div className="title-section mb-2 mb-sm-4">
              <h2 className="heading">Best Of India</h2>
            </div>
            <div className="row gx-2 gy-2 gx-sm-4 gy-sm-4 gy-lg-0">
              <div className="col-6 col-sm-6 col-lg-3">
                <div className="best-off-box m-0">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.goa} alt="" />
                    </div>
                    <div className="content">
                      <p>Create Memories in </p>
                      <h4>Goa</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-lg-3">
                <div className="best-off-box m-0">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.manali} alt="" />
                    </div>
                    <div className="content">
                      <p>Create Memories in </p>
                      <h4>Manali</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-lg-3">
                <div className="best-off-box m-0">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.ooty} alt="" />
                    </div>
                    <div className="content">
                      <p>Create Memories in </p>
                      <h4>Ooty</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-lg-3">
                <div className="best-off-box m-0">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.rajasthan} alt="" />
                    </div>
                    <div className="content">
                      <p>Create Memories in </p>
                      <h4>Rajasthan</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="adventure mb-80">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-xl-3">
                <div className="title-section red-bg text-center">
                  <h2 className="heading text-white m-0">
                    Adventure & Activity
                  </h2>
                </div>
              </div>
              <div className="col-12 col-xl-9">
                <Swiper
                  spaceBetween={20}
                  slidesPerView={6}
                  loop
                  speed={2000}
                  modules={[Autoplay]}
                  autoplay={{ disableOnInteraction: false }}
                  breakpoints={adventures}
                >
                  <SwiperSlide>
                    <div className="adventure-box">
                      <Link to="/Bike-Trips">
                        <div className="image not-hover">
                          <img src={images.beach} alt="" />
                        </div>
                        <h5>Beach Tours</h5>
                      </Link>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="adventure-box">
                      <Link to="/Bike-Trips">
                        <div className="image not-hover">
                          <img src={images.surfing} alt="" />
                        </div>
                        <h5>Surfing</h5>
                      </Link>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="adventure-box">
                      <Link to="/Bike-Trips">
                        <div className="image not-hover">
                          <img src={images.safari} alt="" />
                        </div>
                        <h5>Safari</h5>
                      </Link>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="adventure-box">
                      <Link to="/Bike-Trips">
                        <div className="image not-hover">
                          <img src={images.cycling} alt="" />
                        </div>
                        <h5>Cycling</h5>
                      </Link>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="adventure-box">
                      <Link to="/Bike-Trips">
                        <div className="image not-hover">
                          <img src={images.trek} alt="" />
                        </div>
                        <h5>Trekings</h5>
                      </Link>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="adventure-box">
                      <Link to="/Bike-Trips">
                        <div className="image not-hover">
                          <img src={images.camp} alt="" />
                        </div>
                        <h5>Camping</h5>
                      </Link>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section className="about-tour mb-80 gray-bg pt-80 pb-5">
          <div className="container">
            <Swiper
              spaceBetween={0}
              slidesPerView={3}
              autoplay={{ disableOnInteraction: false }}
              loop
              speed={7000}
              modules={[Autoplay, Pagination]}
              pagination={{ clickable: true }}
              className="pb-5"
              breakpoints={about_tour}
            >
              <SwiperSlide>
                <div className="best-off-box about-tour-box">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.paris} alt="" />
                    </div>
                    <div className="content text-start align-items-start">
                      <h4 className="mb-2">Paris Tour</h4>
                      <p>
                        We denonce with righteous Indignation and dislike men
                        who are so beguiled and demoralized by the charms of
                        pleasure of the moment
                      </p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="best-off-box about-tour-box">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.paris} alt="" />
                    </div>
                    <div className="content text-start align-items-start">
                      <h4 className="mb-2">Egypt Tour</h4>
                      <p>
                        We denonce with righteous Indignation and dislike men
                        who are so beguiled and demoralized by the charms of
                        pleasure of the moment
                      </p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="best-off-box about-tour-box">
                  <Link to="/Bike-Trips">
                    <div className="image">
                      <img src={images.maldive} alt="" />
                    </div>
                    <div className="content text-start align-items-start">
                      <h4 className="mb-2">Maldives Tour</h4>
                      <p>
                        We denonce with righteous Indignation and dislike men
                        who are so beguiled and demoralized by the charms of
                        pleasure of the moment
                      </p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>

        <section className="trending-destination mb-80">
          <div className="container">
            <div className="title-section mb-4 text-center">
              <h2 className="heading">Trending Now</h2>
            </div>
            <div className="row g-0">
              <div className="row g-0">
                <div className="col-12 col-lg-8 row g-0">
                  <div className="col-6 col-sm-6 col-xxl-12">
                    <div className="best-off-box trending-destination-left">
                      <Link to="/Bike-Trips">
                        <div className="image">
                          <img src={images.jammu} alt="" />
                        </div>
                        <div className="content text-start align-items-end">
                          <h4 className="mb-2">Jammu & Kashmir</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-xxl-4">
                    <div className="best-off-box trending-destination-left">
                      <Link to="/Bike-Trips">
                        <div className="image">
                          <img src={images.vietnum} alt="" />
                        </div>
                        <div className="content text-start align-items-end">
                          <h4 className="mb-2">Vietnam</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-xxl-4">
                    <div className="best-off-box trending-destination-left">
                      <Link to="/Bike-Trips">
                        <div className="image">
                          <img src={images.maritius} alt="" />
                        </div>
                        <div className="content text-start align-items-end">
                          <h4 className="mb-2">Mauritius</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-xxl-4">
                    <div className="best-off-box trending-destination-left">
                      <Link to="/Bike-Trips">
                        <div className="image">
                          <img src={images.europe} alt="" />
                        </div>
                        <div className="content text-start align-items-end">
                          <h4 className="mb-2">Europe</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="best-off-box trending-destination-right">
                    <Link to="/Bike-Trips">
                      <div className="image">
                        <img src={images.goa2} alt="" />
                      </div>
                      <div className="content text-start align-items-end">
                        <h4 className="mb-2">Goa</h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-80 px-4pc">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-center gy-4">
              <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                <div className="awards gray-bg">
                  <div className="title-section mb-4 text-center">
                    <h2 className="heading fw-500">Awards</h2>
                  </div>
                  <ul className="row">
                    <li className="col-6">
                      <div className="image">
                        <img src={images.award1} alt="" />
                      </div>
                      <div className="image">
                        <img src={images.award2} alt="" />
                      </div>
                      <div className="image">
                        <img src={images.award3} alt="" />
                      </div>
                      <div className="image">
                        <img src={images.award4} alt="" />
                      </div>
                    </li>
                    <li className="col-6">
                      <div className="image">
                        <img src={images.award5} alt="" />
                      </div>
                      <div className="image">
                        <img src={images.award6} alt="" />
                      </div>
                      <div className="image">
                        <img src={images.award7} alt="" />
                      </div>
                      <div className="image">
                        <img src={images.award8} alt="" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-none d-xxl-block col-xxl-4">
                <img
                  src={images.maldive2}
                  alt=""
                  className="w-100 img-cover test-award-mid"
                />
              </div>
              <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                <div className="testimonials gray-bg">
                  <div className="title-section text-center">
                    <h2 className="heading fw-500">Testimonials</h2>
                  </div>
                  <Swiper
                    slidesPerView={1}
                    autoplay={{ disableOnInteraction: false }}
                    loop
                    speed={2000}
                    modules={[Autoplay, Pagination]}
                    pagination={{ clickable: true }}
                    className="pb-4 pb-lg-0"
                  >
                    <SwiperSlide>
                      <div className="testimonials-box">
                        <div className="image">
                          <img src={images.testimonial1} alt="" />
                          <div className="name">
                            <h4>Anjali Nayak</h4>
                            <p>Maldives Honeymoon</p>
                          </div>
                        </div>
                        <div className="content">
                          <p>
                            We booked our Maldives Honeymoon through Deyor. Aman
                            was super helpful and helped us understand the
                            destination, properties and curated such an amazing
                            tour for us. We can't wait to travel with Deyor
                            again!
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="testimonials-box">
                        <div className="image">
                          <img src={images.testimonial2} alt="" />
                          <div className="name">
                            <h4>Rahul</h4>
                            <p>Maldives Honeymoon</p>
                          </div>
                        </div>
                        <div className="content">
                          <p>
                            We booked our Maldives Honeymoon through Deyor. Aman
                            was super helpful and helped us understand the
                            destination, properties and curated such an amazing
                            tour for us. We can't wait to travel with Deyor
                            again!
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="testimonials-box">
                        <div className="image">
                          <img src={images.testimonial3} alt="" />
                          <div className="name">
                            <h4>Sanju</h4>
                            <p>Maldives Honeymoon</p>
                          </div>
                        </div>
                        <div className="content">
                          <p>
                            We booked our Maldives Honeymoon through Deyor. Aman
                            was super helpful and helped us understand the
                            destination, properties and curated such an amazing
                            tour for us. We can't wait to travel with Deyor
                            again!
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="most-viewed">
          <div className="container">
            <div className="title-section mb-4 text-center">
              <h2 className="heading">Most viewed Destinations</h2>
            </div>
            <div className="row g-0">
              <div className="row g-0">
                <div className="col-12 col-lg-4 col-xxl-6 row g-0">
                  <div className="col-6 col-md-6 col-lg-12 col-xxl-6">
                    <div className="best-off-box most-viewed-right">
                      <Link to="/Bike-Trips">
                        <div className="image">
                          <img src={images.maldive} alt="" />
                        </div>
                        <div className="content text-start align-items-start">
                          <h5 className="mb-2 text-white">
                            Maldives Romantic Escapades
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 col-md-6 col-lg-12 col-xxl-6">
                    <div className="best-off-box most-viewed-right">
                      <Link to="/Bike-Trips">
                        <div className="image">
                          <img src={images.dubai} alt="" />
                        </div>
                        <div className="content text-start align-items-start">
                          <h5 className="mb-2 text-white">
                            Dubai Vacation Packages
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-8 col-xxl-6 row g-0">
                  <div className="col-6 col-md-6">
                    <div className="best-off-box most-viewed-left">
                      <Link to="/Bike-Trips">
                        <div className="image">
                          <img src={images.singapour} alt="" />
                        </div>
                        <div className="content text-start align-items-start">
                          <h5 className="mb-2 text-white">
                            Singapore Tour Packages
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 col-md-6">
                    <div className="best-off-box most-viewed-left">
                      <Link to="/Bike-Trips">
                        <div className="image">
                          <img src={images.maritius2} alt="" />
                        </div>
                        <div className="content text-start align-items-start">
                          <h5 className="mb-2 text-white">Love In Mauritius</h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 col-md-6">
                    <div className="best-off-box most-viewed-left">
                      <Link to="/Bike-Trips">
                        <div className="image">
                          <img src={images.singapour} alt="" />
                        </div>
                        <div className="content text-start align-items-start">
                          <h5 className="mb-2 text-white">
                            Singapore Tour Packages
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 col-md-6">
                    <div className="best-off-box most-viewed-left">
                      <Link to="/Bike-Trips">
                        <div className="image">
                          <img src={images.maritius2} alt="" />
                        </div>
                        <div className="content text-start align-items-start">
                          <h5 className="mb-2 text-white">Love In Mauritius</h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="brand my-5">
          <div className="container">
            <Swiper
              slidesPerView={8}
              spaceBetween={20}
              autoplay={{ disableOnInteraction: false }}
              loop
              speed={2000}
              modules={[Autoplay]}
              breakpoints={clients}
            >
              <SwiperSlide>
                <div className="image">
                  <img src={images.brand1} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image">
                  <img src={images.brand2} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image">
                  <img src={images.brand3} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image">
                  <img src={images.brand4} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image">
                  <img src={images.brand5} alt="" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
      </main>
      <Footer />
      {getWindow <= 768 && (
        <div className="home-trip-tabs">
          <div className="inner">
            <ul className="tabs">
              {choosePlace.map((item, i) => {
                return (
                  <li key={i}>
                    <button
                      className={`bg-transparent border-0 text-center ${
                        item.active ? "active" : ""
                      }`}
                      onClick={() => tabClick(i, item.value)}
                    >
                      <div className="img">
                        <img src={item.icon} alt="" />
                      </div>
                      <span>{item.name}</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Index;
