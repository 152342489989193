import { Rating } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import React, { useEffect, useState } from "react";
import { BsFillStarFill, BsSearch } from "react-icons/bs";
import { MdFlightTakeoff } from "react-icons/md";
import { Link, useSearchParams } from "react-router-dom";
import { getHotelsByDestination } from "../services/hotels";
import Filter from "./Filter";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import { images } from "./Utility/Images";

function Hotel() {
  const [room, setRoom] = useState(["Room 1"]);
  const [place, setPlace] = useState(false);
  const [destination, setDestination] = useState(false);
  const [getDate, GetDate] = useState([new Date(), new Date()]);
  const [travellerType, setTravellerType] = useState(false);
  const [searchParams] = useSearchParams();
  const [hideBanner, setHideBanner] = useState(searchParams.get("hideBanner"));

  const [hotelSearch, setHotelSearch] = useState(searchParams.get("search"));
  const [isLoading, setIsLoading] = useState(false);
  const [hotelArr, setHotelArr] = useState([]);

  // console.log(searchParams.get('banner'), "param");
  const sort_by = [
    { value: "popular", label: "Popular" },
    { value: "Date, new to old", label: "Date, new to old" },
    { value: "Date, old to new", label: "Date, old to new" },
    { value: "Price, low to high", label: "Price, low to high" },
    { value: "Price, high to low", label: "Price, high to low" },
  ];

  const addRoom = (e) => {
    e.preventDefault();
    setRoom((temp) => [...temp, `Room ${room.length + 1}`]);
  };

  const getHotels = async () => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        let tempObj = JSON.parse(hotelSearch);
        console.log(tempObj);

        const { data: res } = await getHotelsByDestination(tempObj);
        if (res) {
          setHotelArr(res.data);
          setIsLoading(false);
          console.log(res);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getHotels();
  }, []);

  return (
    <>
      <Header />
      <main className={hideBanner ? "border-top" : ""}>
        {!hideBanner && (
          <section className="search-flight-form mb-80 px-4pc">
            <div className="container-fluid">
              <div className="search-flight-form-inner">
                <div className="title-section">
                  <h4 className="heading mb-1 fw-semibold">Search Your Flight Here!!!</h4>
                </div>
                <form action="/" className="form row">
                  <div className="col-12 col-lg-3 mb-0">
                    <label>Place</label>
                    <input type="search" className="form-control" onClick={() => setPlace(!place)} />
                    {place && (
                      <div className="custum-dropdown">
                        <p className="text-muted">Popular cities</p>
                        <ul className="list">
                          <li>
                            <div className="icon">
                              <MdFlightTakeoff />
                            </div>
                            <div>
                              <p className="text-dark">Mumbai, India</p>
                              <p className="text-muted">Chhatrapati Shivaji International Airport</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <MdFlightTakeoff />
                            </div>
                            <div>
                              <p className="text-dark">Mumbai, India</p>
                              <p className="text-muted">Chhatrapati Shivaji International Airport</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <MdFlightTakeoff />
                            </div>
                            <div>
                              <p className="text-dark">Mumbai, India</p>
                              <p className="text-muted">Chhatrapati Shivaji International Airport</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <MdFlightTakeoff />
                            </div>
                            <div>
                              <p className="text-dark">Mumbai, India</p>
                              <p className="text-muted">Chhatrapati Shivaji International Airport</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <MdFlightTakeoff />
                            </div>
                            <div>
                              <p className="text-dark">Mumbai, India</p>
                              <p className="text-muted">Chhatrapati Shivaji International Airport</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <MdFlightTakeoff />
                            </div>
                            <div>
                              <p className="text-dark">Mumbai, India</p>
                              <p className="text-muted">Chhatrapati Shivaji International Airport</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <MdFlightTakeoff />
                            </div>
                            <div>
                              <p className="text-dark">Mumbai, India</p>
                              <p className="text-muted">Chhatrapati Shivaji International Airport</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <MdFlightTakeoff />
                            </div>
                            <div>
                              <p className="text-dark">Mumbai, India</p>
                              <p className="text-muted">Chhatrapati Shivaji International Airport</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-lg-3 mb-0">
                    <label>Check in - Check out</label>
                    <div className="date-picker-module">
                      <DateRangePicker onChange={GetDate} value={getDate} />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 mb-0">
                    <div className="position-relative">
                      <label>Travellers</label>
                      <input type="text" className="form-control" value="5" onClick={() => setTravellerType(!travellerType)} readOnly />
                      {travellerType && (
                        <div className="get-traveller-data room-guest">
                          {room.map((item, i) => {
                            return (
                              <div key={i} className="border-bottom pb-3 mb-2">
                                <h6>{item}</h6>
                                <div className="row gy-3">
                                  <div className="col-12">
                                    <p className="text">ADULTS (12y +)</p>
                                    <ul className="number">
                                      <li className="active">1</li>
                                      <li>2</li>
                                      <li>3</li>
                                      <li>4</li>
                                      <li>5</li>
                                      <li>6</li>
                                      <li>7</li>
                                      <li>8</li>
                                      <li>9</li>
                                      <li>10</li>
                                      <li>11</li>
                                      <li>12</li>
                                    </ul>
                                  </div>
                                  <div className="col-12">
                                    <p className="text">CHILDREN (2y - 12y )</p>
                                    <ul className="number">
                                      <li className="active">0</li>
                                      <li>1</li>
                                      <li>2</li>
                                      <li>3</li>
                                      <li>4</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <div className="d-flex align-items-center gap-3">
                            <button className="btn red-bg btn-sm btn-custom" onClick={(e) => addRoom(e)}>
                              +&nbsp;Add Another Room
                            </button>
                            <button className="btn red-bg btn-sm btn-custom">Apply</button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 mb-0">
                    <button type="submit" className="btn red-bg with-icon btn-custom">
                      <div className="icon">
                        <BsSearch />
                      </div>
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        )}

        <section className="mt-5 mb-3 px-4pc">
          <div className="container-fluid">
            <ul className="packages">
              <div className="packages-left" style={{ display: "unset" }}>
                <Filter sticky />
              </div>
              <div className="packages-right flex-1">
                {/* ====================head======================== */}
                <div className="packages-head packages-box mb-4">
                  <h4 className="heading mb-0">List of Hotels</h4>
                  <div className="form-control search-bar w-50">
                    <input type="search" className="border-0 bg-transparent w-100" placeholder="Searh Here...." />
                    <button className="border-0 bg-transparent py-0 icon green fs-5">
                      <BsSearch />
                    </button>
                  </div>
                </div>
                {/* ====================head======================== */}
                {/* ============packages====================== */}
                <div className="row gy-4">
                  {hotelArr &&
                    hotelArr.length > 0 &&
                    hotelArr.map((el, index) => {
                      return (
                        <div className="col-12" key={index}>
                          <div className="packages-box packages-inner row g-0">
                            <div className="packages-inner-left col-12 col-lg-4">
                              <Link to={`/HotelDetail?hotelId=${el.rezLiveHotelId}`}>
                                <img src={el.imageUrl} alt="" className="w-100 img-cover" />
                              </Link>
                            </div>
                            <div className="col-12 col-lg-8">
                              <div className="packages-inner-right">
                                <div className="d-flex heading gap-3 align-items-start">
                                  <div>
                                    <Link to={`/HotelDetail?hotelId=${el.rezLiveHotelId}`}>
                                      <h4>{el?.name}</h4>
                                    </Link>
                                    <ul className="info">
                                      <li>{el?.roomsArr?.reduce((acc, elz) => acc + parseInt(elz.noOfAdults) + parseInt(elz.Children), 0)} Guests</li>
                                      <li>{el?.roomsArr?.reduce((acc, elz) => acc + parseInt(elz.TotalRooms), 0)} Rooms</li>
                                      {/* <li>1 Bathroom</li> */}
                                    </ul>
                                    <ul className="offer">
                                      <li>
                                        <Link to="/HotelDetail">
                                          <img src={images.hotel1} alt="" />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/HotelDetail">
                                          <img src={images.hotel2} alt="" />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/HotelDetail">
                                          <img src={images.hotel3} alt="" />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/HotelDetail">
                                          <img src={images.hotel4} alt="" />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/HotelDetail">
                                          <img src={images.hotel5} alt="" />
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                  <div>
                                    <div className="d-flex align-items-center gap-3">
                                      <div className="text-end">
                                        <p className="mb-0 text-dark fw-semibold" style={{ lineHeight: "normal" }}>
                                          Superb
                                        </p>
                                        <span className="fs-15 fw-semibold">(153 Reviews)</span>
                                      </div>
                                      <button className="btn green-bg text-white with-icon">
                                        <div className="icon">
                                          <BsFillStarFill />
                                        </div>
                                        {el?.rating}
                                      </button>
                                    </div>
                                    <div className="hotel-rating text-end mt-1">
                                      <Rating
                                        name="read-only"
                                        // size="small"
                                        value={3}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="price">
                                  Starts from
                                  <h3 className="red mb-0">&nbsp;₹{el?.price}</h3>
                                  <span className="text-muted fs-15">/Person</span>
                                </p>
                                <ul className="detail mb-0">
                                  {/* <li style={{ color: "#70B6C1" }}>
                              <div className="icon">
                                <BsClockFill />
                              </div>
                              5D/3N
                            </li> */}
                                  {/* <li>
                                    <div className="icon">
                                      <ImLocation />
                                    </div>
                                    Leh
                                  </li> */}
                                </ul>
                                <p className="text-dark mb-0">
                                  {el?.roomsArr && el?.roomsArr[0]?.RoomDescription && <>{el?.roomsArr[0]?.RoomDescription}</>}
                                  <Link to="/HotelDetail" className="red fw-semibold text-uppercase">
                                    &nbsp;More...
                                  </Link>
                                </p>
                                <ul className="itinarary my-3">
                                  {el?.RoomArr &&
                                    el?.RoomArr.map((roomEl) => {
                                      return <li>{roomEl?.BoardBasis}</li>;
                                    })}
                                </ul>
                                {/* <p>
                                  Check-in Time: 02:00PM
                                  <span className="text-dark fs-5">&nbsp;|&nbsp;</span>
                                  Check-out Time: 12:00PM
                                </p> */}
                                <Link to="/HotelDetail" className="btn btn-custom red-bg px-4">
                                  View Deal
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                {/* ============packages====================== */}
              </div>
            </ul>
          </div>
        </section>

        <section className="faq px-4pc">
          <div className="container-fluid">
            <div className="title-section mb-4">
              <h2 className="heading">Faq About Hotel Booking</h2>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 gray-bg">
                <details>
                  <summary>Is this trip costed in real time prices?</summary>
                  <div className="content">
                    <p>Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events.</p>
                  </div>
                </details>
                <details>
                  <summary>How do I change my flights and hotel options?</summary>
                  <div className="content">
                    <p>Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events.</p>
                  </div>
                </details>
                <details>
                  <summary>What help would be provided in Visa assistance?</summary>
                  <div className="content">
                    <p>Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events.</p>
                  </div>
                </details>
                <details>
                  <summary>How do I change the dates of my departure?</summary>
                  <div className="content">
                    <p>Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events.</p>
                  </div>
                </details>
                <details>
                  <summary>How can I book this trip? How will I get onfirmations of all the bookings?</summary>
                  <div className="content">
                    <p>Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events.</p>
                  </div>
                </details>
                <details>
                  <summary>How to edit the passenger count or add an extra room?</summary>
                  <div className="content">
                    <p>Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events.</p>
                  </div>
                </details>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Hotel;
