import React from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Select from "react-select";

function CareerForm() {
  const sort_by = [
    { value: "popular", label: "Popular" },
    { value: "Date, new to old", label: "Date, new to old" },
    { value: "Date, old to new", label: "Date, old to new" },
    { value: "Price, low to high", label: "Price, low to high" },
    { value: "Price, high to low", label: "Price, high to low" },
  ];
  const yes_no = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];
  const working_time = [
    { value: "Full-time", label: "Full-time" },
    { value: "Part-time", label: "Part-time" },
    { value: "Temporary", label: "Temporary" },
  ];
  const available = [
    { value: "Day", label: "Day" },
    { value: "Evening", label: "Evening" },
    { value: "Night", label: "Night" },
    { value: "Weekend", label: "Weekend" },
  ];
  const heard_where = [
    { value: "Our Web Site", label: "Our Web Site" },
    { value: "Facebook", label: "Facebook" },
    { value: "LinkedIn", label: "LinkedIn" },
    { value: "Indeed", label: "Indeed" },
    { value: "Job Fair", label: "Job Fair" },
    { value: "A Friend", label: "A Friend" },
    {
      value: "Recommendation from a DEYOR",
      label: "Recommendation from a DEYOR",
    },
    { value: "News Paper", label: "News Paper" },
    { value: "Other", label: "Other" },
  ];
  return (
    <>
      <Header />
      <main className="border-top pt-3">
        <section className="career-form mb-80">
          <div className="container">
            <div className="title-section bg mb-4">
              <h1>Employment Application</h1>
              <p className="text-dark">
                If you are interested in employment with DEYOR, we encourage you
                to complete a general application given below.
              </p>
            </div>
            <div
              className="form row px-4 py-5"
              style={{ backgroundColor: "#f5f3fb" }}
            >
              <div className="col-12 col-md-6 mb-3">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control bg-white"
                  id="f-name1"
                  name="first_name"
                  onkeypress="return /[A-Za-z_ ]/.test(event.key)"
                  required=""
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control bg-white"
                  id="l-name1"
                  name="last_name"
                  onkeypress="return /[A-Za-z_ ]/.test(event.key)"
                  required=""
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Email Address</label>
                <input
                  type="email"
                  className="form-control bg-white"
                  id="email-1"
                  name="email"
                  required=""
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Phone Number</label>
                <input
                  type="tel"
                  className="form-control bg-white"
                  id="phone-1"
                  name="phone"
                  required=""
                  im-insert="true"
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label>Country</label>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>State / Province</label>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>City</label>
                <input
                  type="text"
                  className="form-control bg-white"
                  name="city"
                  onkeypress="return /[A-Za-z_ ]/.test(event.key)"
                  required=""
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Position Applying for...</label>
                <Select
                  classNamePrefix="sort-by"
                  options={sort_by}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>You would like to work…</label>
                <Select
                  classNamePrefix="sort-by"
                  options={working_time}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>You are available to work...</label>
                <Select
                  classNamePrefix="sort-by"
                  options={available}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Where have you heard about us?</label>
                <Select
                  classNamePrefix="sort-by"
                  options={heard_where}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#eeeeee",
                      primary: "#FF5050",
                    },
                  })}
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Upload Resume (Accepted file types: doc, docx, pdf)
                </label>
                <input
                  type="file"
                  className="form-control bg-white"
                  name="resume_file"
                  required=""
                />
              </div>
              <div className="col-12 mb-3">
                <p className="fs-15 line-height-normal mb-0">
                  By Submitting this form, I agree that the foregoing statements
                  are correct to the best of my knowledge. I understand that any
                  misrepresentation may disqualify me from employment or be
                  cause for dismissal. If hired, I agree to abide by all rules
                  and regulations of the company, including serving an initial
                  probationary period. Furthermore, I authorize the company to
                  contact my previous employer(s) in order to obtain references.
                </p>
                <p className="fs-15 line-height-normal red">
                *Only selected applicants will be contacted.
                </p>
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="btn red-bg btn-custom btn-lg"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default CareerForm;
