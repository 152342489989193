import React from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import PageBanner from "./PageBanner";
import { images } from "./Utility/Images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, EffectFade } from "swiper";
import { Link } from "react-router-dom";
import { FaQuoteLeft } from "react-icons/fa";
import NewsLetter from "./NewsLetter";

function About() {
  return (
    <>
      <Header />
      <main>
        <PageBanner
          banner3
          img1={images.slide4}
          className="page-banner-3"
          title="About Us"
          desp="This is our story"
        />

        <section className="ptb-80 about-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="title-section bg">
                  <h1>About The Company</h1>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src={images.about}
                  alt=""
                  className="about-right w-100 img-contain"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="who-we-are mb-80">
          <div className="container">
            <div className="title-section bg text-center w-100">
              <h1 className="heading fw-semibold">Who we are....</h1>
            </div>
            <p className="text-center">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <div className="row my-5">
              <div className="col-12 col-md-6">
                <div className="best-off-box about-tour-box">
                  <div className="image">
                    <img src={images.paris} alt="" />
                  </div>
                  <div className="content text-start align-items-start">
                    <h4 className="mb-2">Our Mission</h4>
                    <p>
                      We denonce with righteous Indignation and dislike men who
                      are so beguiled and demoralized by the charms of pleasure
                      of the moment
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="best-off-box about-tour-box">
                  <div className="image">
                    <img src={images.europe} alt="" />
                  </div>
                  <div className="content text-start align-items-start">
                    <h4 className="mb-2">Our Vision</h4>
                    <p>
                      We denonce with righteous Indignation and dislike men who
                      are so beguiled and demoralized by the charms of pleasure
                      of the moment
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-80">
          <div className="container">
            <div className="title-section bg text-center w-100 mb-4">
              <h1 className="heading fw-semibold">Why you choose us?</h1>
              <p className="text-dark fs-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <ul className="row">
              <li className="col-12 col-md-3">
                <div className="why-us-box">
                  <div className="icon">
                    <img src={images.destination} alt="" />
                  </div>
                  <h5>DESTINATIONS</h5>
                  <p>
                    Choose any destination you like, we have several country
                    ratings
                  </p>
                </div>
              </li>
              <li className="col-12 col-md-3">
                <div className="why-us-box">
                  <div className="icon">
                    <img src={images.tour_bus} alt="" />
                  </div>
                  <h5>TOURS</h5>
                  <p>
                    A large number of tours to different countries for one or a
                    family
                  </p>
                </div>
              </li>
              <li className="col-12 col-md-3">
                <div className="why-us-box">
                  <div className="icon">
                    <img src={images.hotl_star} alt="" />
                  </div>
                  <h5>LUXURY HOTEL</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </li>
              <li className="col-12 col-md-3">
                <div className="why-us-box">
                  <div className="icon">
                    <img src={images.assistance} alt="" />
                  </div>
                  <h5>ASSISTANCE</h5>
                  <p>
                    We are ready to solve all your questions and help to deal
                    with problems
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section className="our-team mb-80 gray-bg pt-80 pb-5">
          <div className="container">
            <div className="title-section text-center mb-4">
              <h1 className="heading fw-semibold">Our Team</h1>
              <p className="text-dark fs-6">Meet To our team members</p>
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={4}
              autoplay={{ disableOnInteraction: false }}
              loop
              speed={2000}
              modules={[Autoplay, Navigation]}
              navigation
            >
              <SwiperSlide>
                <div className="our-team-box text-center">
                  <div className="image">
                    <img src={images.team} alt="" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="mb-0">Aman Designer</h5>
                    <p>Team Leader</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-team-box text-center">
                  <div className="image">
                    <img src={images.team} alt="" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="mb-0">Aman Designer</h5>
                    <p>Team Leader</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-team-box text-center">
                  <div className="image">
                    <img src={images.team} alt="" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="mb-0">Aman Designer</h5>
                    <p>Team Leader</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-team-box text-center">
                  <div className="image">
                    <img src={images.team} alt="" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="mb-0">Aman Designer</h5>
                    <p>Team Leader</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>

        <section className="blog mb-80">
          <div className="container">
            <div className="title-section bg d-flex align-items-center justify-content-between mb-4 w-100">
              <h1 className="mb-0">Our Latest Blog Posts</h1>
              <Link className="btn red-bg btn-custom" to="/Blogs">
                See All Blog Posts
              </Link>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.holiday2} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.testimonial3} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="blog-box">
                  <div className="image">
                    <Link to="/Blog-Detail">
                      <img src={images.goa} alt="" />
                    </Link>
                    <ul className="tags red">
                      <li>08-11-2022</li>
                      <li>Summer Vacation</li>
                    </ul>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/Blog-Detail">
                        Integer turpis ligula, tempor a libero in, pharetra
                        aliquet dolor.
                      </Link>
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce eleifend tempor malesuada. Donec tempor quam vel mi
                      laoreet convallis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

       <NewsLetter className='mb-80 ptb-80 mx-4pc' />

        <section className="testimonials-style-2 mb-80">
          <div className="container">
            <div className="title-section bg text-center w-100 mb-4">
              <h1 className="heading fw-semibold">Testimonials</h1>
              <p className="text-dark fs-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <Swiper
              slidesPerView={1}
              autoplay={{ disableOnInteraction: false }}
              loop
              speed={2000}
              modules={[Autoplay, Navigation, EffectFade]}
              navigation
              effect="fade"
            >
              <SwiperSlide>
                <div className="testimonials-style-2-box">
                  <div className="row align-items-center gx-5">
                    <div className="col-12 col-lg-6">
                      <div className="image left">
                        <img src={images.testimonial1} alt="" />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="right">
                        <div className="icon red fs-1 mb-4">
                          <FaQuoteLeft />
                        </div>
                        <h4 className="tour-to">Maldives</h4>
                        <div className="content">
                          <p>
                            We booked our Maldives Honeymoon through Deyor. Aman
                            was super helpful and helped us understand the
                            destination, properties and curated such an amazing
                            tour for us. We can't wait to travel with Deyor
                            again!
                          </p>
                        </div>
                        <div className="name">
                          <h5>Anjali Nayak</h5>
                          <p>
                            <i>Maldives Honeymoon</i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonials-style-2-box">
                  <div className="row align-items-center gx-5">
                    <div className="col-12 col-lg-6">
                      <div className="image left">
                        <img src={images.testimonial2} alt="" />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="right">
                        <div className="icon red fs-1 mb-4">
                          <FaQuoteLeft />
                        </div>
                        <h4 className="tour-to">Maldives</h4>
                        <div className="content">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Fusce eleifend tempor malesuada. Donec tempor
                            quam vel mi laoreet convallis. Praesent euismod
                            tortor quis iaculis ultrices. Phasellus felis lorem,
                            ultricies nec ligula finibus, mollis aliquet erat.
                            Nulla neque ipsum, laoreet ut lacinia id, finibus
                            eget arcu. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit. Fusce eleifend tempor malesuada.
                          </p>
                        </div>
                        <div className="name">
                          <h5>Anjali Nayak</h5>
                          <p>
                            <i>Maldives Honeymoon</i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonials-style-2-box">
                  <div className="row align-items-center gx-5">
                    <div className="col-12 col-lg-6">
                      <div className="image left">
                        <img src={images.testimonial3} alt="" />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="right">
                        <div className="icon red fs-1 mb-4">
                          <FaQuoteLeft />
                        </div>
                        <h4 className="tour-to">Maldives</h4>
                        <div className="content">
                          <p>
                            Praesent euismod tortor quis iaculis ultrices.
                            Phasellus felis lorem, ultricies nec ligula finibus,
                            mollis aliquet erat. Nulla neque ipsum, laoreet ut
                            lacinia id, finibus eget arcu. Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit. Fusce eleifend
                            tempor malesuada.
                          </p>
                        </div>
                        <div className="name">
                          <h5>Anjali Nayak</h5>
                          <p>
                            <i>Maldives Honeymoon</i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default About;
