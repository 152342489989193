import axios from "axios";
export const axiosApiInstance = axios.create();
axiosApiInstance.interceptors.request.use(
  async (config) => {
    // console.log(token)
    let token = await localStorage.getItem("Session");
    if (token) {
      config.headers["authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);
axiosApiInstance.interceptors.response.use(
  (res) => {
    // Add configurations here
    return res;
  },
  async (err) => {
    // await logoutUser()

    return Promise.reject(err);
  }
);

export default axiosApiInstance;
