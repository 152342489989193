import React from "react";
import { BsClockFill } from "react-icons/bs";
import { GiTicket } from "react-icons/gi";
import { Link } from "react-router-dom";
import { images } from "./Utility/Images";

function MyBooking() {
  return (
    <section className="account-booking pb-80">
      <div className="container">
        <h4 className="mb-4">BOOKKING SUMMARY</h4>
        <div className="row gy-4">
          <div className="col-12">
            <div className="packages-box m-0 packages-inner row g-0 align-items-center">
              <div className="packages-inner-left col-lg-4">
                <Link to="/">
                  <img src={images.slide2} alt="" className="w-100 img-cover" />
                </Link>
              </div>
              <div className="col-lg-8">
                <div className="packages-inner-right">
                  <Link to="/TripDetail">
                    <h4>Leh Ladakh Bike Trip</h4>
                  </Link>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                  <p className="price mb-3">
                    Total Price
                    <h3 className="red mb-0">&nbsp;₹1,500</h3>
                    <span className="text-muted fs-15">/Person</span>
                  </p>
                  <ul className="detail">
                    <li style={{ color: "#70B6C1" }}>
                      <div className="icon">
                        <BsClockFill />
                      </div>
                      2022-10-20
                    </li>
                    <li>
                      <div className="icon">
                        <GiTicket />
                      </div>
                      Museum of the Future Admission Tickets
                    </li>
                  </ul>
                  <ul className="d-flex gap-3">
                    <button
                      className="btn text-white btn-custom green-bg"
                    >
                      Review Us
                    </button>
                    <button className="btn red-bg btn-custom">Any Query</button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="packages-box m-0 packages-inner row g-0 align-items-center">
              <div className="packages-inner-left col-lg-4">
                <Link to="/">
                  <img src={images.slide2} alt="" className="w-100 img-cover" />
                </Link>
              </div>
              <div className="col-lg-8">
                <div className="packages-inner-right">
                  <Link to="/TripDetail">
                    <h4>Leh Ladakh Bike Trip</h4>
                  </Link>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                  <p className="price mb-3">
                    Total Price
                    <h3 className="red mb-0">&nbsp;₹1,500</h3>
                    <span className="text-muted fs-15">/Person</span>
                  </p>
                  <ul className="detail">
                    <li style={{ color: "#70B6C1" }}>
                      <div className="icon">
                        <BsClockFill />
                      </div>
                      2022-10-20
                    </li>
                    <li>
                      <div className="icon">
                        <GiTicket />
                      </div>
                      Museum of the Future Admission Tickets
                    </li>
                  </ul>
                  <ul className="d-flex gap-3">
                    <button
                      className="btn text-white btn-custom green-bg"
                    >
                      Review Us
                    </button>
                    <button className="btn red-bg btn-custom">Any Query</button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="packages-box m-0 packages-inner row g-0 align-items-center">
              <div className="packages-inner-left col-lg-4">
                <Link to="/">
                  <img src={images.slide2} alt="" className="w-100 img-cover" />
                </Link>
              </div>
              <div className="col-lg-8">
                <div className="packages-inner-right">
                  <Link to="/TripDetail">
                    <h4>Leh Ladakh Bike Trip</h4>
                  </Link>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                  <p className="price mb-3">
                    Total Price
                    <h3 className="red mb-0">&nbsp;₹1,500</h3>
                    <span className="text-muted fs-15">/Person</span>
                  </p>
                  <ul className="detail">
                    <li style={{ color: "#70B6C1" }}>
                      <div className="icon">
                        <BsClockFill />
                      </div>
                      2022-10-20
                    </li>
                    <li>
                      <div className="icon">
                        <GiTicket />
                      </div>
                      Museum of the Future Admission Tickets
                    </li>
                  </ul>
                  <ul className="d-flex gap-3">
                    <button
                      className="btn text-white btn-custom green-bg"
                    >
                      Review Us
                    </button>
                    <button className="btn red-bg btn-custom">Any Query</button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="packages-box m-0 packages-inner row g-0 align-items-center">
              <div className="packages-inner-left col-lg-4">
                <Link to="/">
                  <img src={images.slide2} alt="" className="w-100 img-cover" />
                </Link>
              </div>
              <div className="col-lg-8">
                <div className="packages-inner-right">
                  <Link to="/TripDetail">
                    <h4>Leh Ladakh Bike Trip</h4>
                  </Link>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                  <p className="price mb-3">
                    Total Price
                    <h3 className="red mb-0">&nbsp;₹1,500</h3>
                    <span className="text-muted fs-15">/Person</span>
                  </p>
                  <ul className="detail">
                    <li style={{ color: "#70B6C1" }}>
                      <div className="icon">
                        <BsClockFill />
                      </div>
                      2022-10-20
                    </li>
                    <li>
                      <div className="icon">
                        <GiTicket />
                      </div>
                      Museum of the Future Admission Tickets
                    </li>
                  </ul>
                  <ul className="d-flex gap-3">
                    <button
                      className="btn text-white btn-custom green-bg"
                    >
                      Review Us
                    </button>
                    <button className="btn red-bg btn-custom">Any Query</button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MyBooking;
